/**
 * @file events.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 17th December 2020 11:05:18 am
 * @copyright 2015 - 2020 SKAT LLC, Delive LLC
 * @flow
 */
import type { EmitterEvents } from 'web-panel/types'

const PROTOCOL_CONNECTION_EVENT : EmitterEvents = {
  CONNECT: 'connect',
  DISCONNECT: 'disconnect',

  /**
    * When server message received
    * @param {String} message
    * @param {ProtocolConnection} instance
    * @event MESSAGE
    */
  MESSAGE: 'message',

  /**
    * When server message received, but parsed as list
    * @param {Array} command list command representation
    * @param {Connection} instance
    * @event COMMAND
    */
  COMMAND: 'command',
  RESPONSE: 'response'
}

export { PROTOCOL_CONNECTION_EVENT }
