/**
 * @file easterEggs.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 3rd December 2020 9:04:22 am
 * @copyright 2015 - 2020 SKAT LLC, Delive LLC
 * @flow
 */
import type { iApplication, iStockExtension } from '../types'

import isSameWeek from 'date-fns/isSameWeek'
import lastDayOfYear from 'date-fns/lastDayOfYear'
import setYear from 'date-fns/setYear'
import getYear from 'date-fns/getYear'
import isSameDay from 'date-fns/isSameDay'

const isExactDay = (month: number, day: number) => {
  const today = new Date()
  const holiday = setYear(new Date(2000, month - 1, day), getYear(today))
  return isSameDay(today, holiday)
}

const eggs = [
  function newYearMode (app: {}) {
    const today = new Date()
    const holiday = lastDayOfYear(today)
    if (isSameWeek(today, holiday)) {
      const block = document.getElementById('header-region')
      if (block) block.style.backgroundImage = "url('images/extra/flakes.png')"
    }
  },

  function taxersDay (app: {}) {
    if (isExactDay(3, 22)) {
      const block = document.getElementById('header-region')
      if (block) block.style.backgroundImage = "url('images/extra/squares.png')"
    }
  },

  function womansDay (app: {}) {
    if (isExactDay(3, 8)) {
      const block = document.getElementById('header-region')
      if (block) block.style.backgroundImage = "url('images/extra/flowers.png')"
    }
  }
]

export default class Extension implements iStockExtension {
  async activate (application: iApplication) {
    try {
      eggs.forEach((egg) => egg(application))
    } catch (e) {
      console.error(e)
    }
  }
}
