/**
 * @file LocalsStoreWrapped.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Monday, 30th August 2021 12:00:35 pm
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow
 */
import type { iStore, StoreItem } from 'web-panel/types'
import { CAST } from 'web-panel-essentials/misc'

class LocalStoreWrapped implements iStore {
  setItem (key: string, value?: string) : void {
    global.localStorage.setItem(key, value)
  }

  getAll () : StoreItem[] {
    const data : StoreItem[] = []
    for (const [name, value] of Object.entries(global.localStorage)) data.push({ name, value: CAST.String(value) })
    return data
  }

  setAll (data: StoreItem[]) : void {
    data.forEach(item => this.setItem(item.name, CAST.String(item.value)))
  }

  getItem (key: string) : ?string {
    return global.localStorage.getItem(key)
  }

  removeItem (key: string) : void {
    global.localStorage.removeItem(key)
  }

  clear () : void {
    global.localStorage.clear()
  }
}

export default LocalStoreWrapped
