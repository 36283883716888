/**
 * @file defaultCell.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Monday, 5th August 2019 4:34:10 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow
 */
import type { ColumnDefinition } from 'web-panel-essentials/types'
import autoBind from 'react-autobind'

import * as React from 'react'
import { genTestName, genCellTestMarks } from 'web-panel/globals'

export type Props = {|
  className: string,
  parentName: string,
  column: ColumnDefinition<any>,
  record: Object,
  columnIndex: number,
  rowIndex: number,
  style: Object,
  onContextMenu: Function,
  onDoubleClick: Function,
  onClick: Function,
  value?: string,
  children?: React.ChildrenArray<any>,
  tooltipId: string
|}

class DefaultCell extends React.PureComponent<Props> {
  constructor () {
    super()
    autoBind(this)
  }

  handleContextMenu (event: any) : void {
    const props = this.props
    props.onContextMenu({ event, record: props.record, column: props.column, columnIndex: props.columnIndex, rowIndex: props.rowIndex })
  }

  handleDoubleClick (event: any) : void {
    const props = this.props
    props.onDoubleClick({ event, record: props.record, column: props.column, columnIndex: props.columnIndex, rowIndex: props.rowIndex })
  }

  handleClick (event: any) : void {
    const props = this.props
    props.onClick({ event, record: props.record, column: props.column, columnIndex: props.columnIndex, rowIndex: props.rowIndex })
  }

  render () : React.Node {
    const props = this.props

    return (
      <div
        {...genTestName(props.column.name, props.parentName)}
        {...genCellTestMarks(props.rowIndex, props.columnIndex)}
        className={props.className}
        style={props.style}
        data-tooltip-id={props.tooltipId}
        data-tooltip-payload={props.record.id}
        onContextMenu={this.handleContextMenu}
        onDoubleClick={this.handleDoubleClick}
        onClick={this.handleClick}
      >
        {props.children || props.value}
      </div>
    )
  }
}

export default DefaultCell
