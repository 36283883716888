/**
 * @file ShortcutEditor.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 23rd March 2021 11:13:19 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow
 */
import type { iLogger, iShortcutsManager, iExposedView } from 'web-panel/types'

import { Inject } from '../../serviceLocator'
import keysight from 'keysight'
import * as React from 'react'
import { genTestName, __mf } from 'web-panel/globals'
import autoBind from 'react-autobind'

type Props = {
  title: string,
  name: string,
  testName?: string
}

export type Shortcut = {
  title: string,
  name: string,
  value: string
}

class ShortcutEditor extends React.Component<Props, Shortcut> implements iExposedView<Shortcut> {
  @Inject logger : iLogger
  @Inject shortcutsManager : iShortcutsManager

  input: React.ElementRef<any>

  constructor (props: Props) {
    super(props)
    autoBind(this)
    this.state = {
      title: this.props.title,
      name: this.props.name,
      value: ''
    }

    this.input = React.createRef()
  }

  async data () : Promise<Shortcut> {
    const { name, value, title } = this.state
    return { title, name, value }
  }

  async command () : Promise<void> {

  }

  handleKeyDown (event: any) {
    const value = []
    const excluded = ['shift', 'alt', 'ctrl', 'meta']

    if (event.metaKey) value.push('meta')
    if (event.altKey) value.push('alt')
    if (event.shiftKey) value.push('shift')
    if (event.ctrlKey) value.push('ctrl')

    const key = keysight(event)
    if (!excluded.includes(key.char)) value.push(key.char)
    this.setState({
      value: value.join('+')
    })

    event.preventDefault()
  }

  componentDidMount () {
    this.input.current.focus()
  }

  render () : React.Node {
    const { value, title } = this.state
    const { testName } = this.props
    return (
      <div className='shortcut-editor'>
        <span className='pre-wrap muted small'>{__mf('SET_HOTKEY_FOR', { name: title })}</span>
        <input {...genTestName('input', testName)} type='text' ref={this.input} readOnly value={value} onKeyDownCapture={this.handleKeyDown} />
      </div>
    )
  }
}

export default ShortcutEditor
