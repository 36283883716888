// $FIXME: Нелегальный иммигрант, надо его депортировать из ядра
/**
 * @file LocationView.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Monday, 1st June 2020 12:33:10 pm
 * @copyright 2019 - 2020 SKAT LLC, Delive LLC
 * @flow
 */
import type { Arrow, iLogger, iExposedView, iSettingsManager } from '../../types'
import type { Point } from 'web-panel-essentials/types'
import type { LocationPoint } from 'skat-js/types'
import type { MapSpecification, PanStrategyArgs } from 'web-panel/components/types'

import * as React from 'react'
import { Map, PointMarker } from 'web-panel/components'
import { Inject } from '../../serviceLocator'
import { Cached } from 'web-panel-essentials/decorators'
import { __ } from 'web-panel/globals'
import { SETTINGS } from 'skat-js/constants'
import autoBind from 'react-autobind'
import isNil from 'lodash/isNil'

export type Props = {
  center: [number, number],
  point?: LocationPoint,
  cityId: number,
  serviceId: number
}

export type State = {
  point?: LocationPoint
}

class LocationView extends React.PureComponent<Props, State> implements iExposedView<?LocationPoint> {
  @Inject addressProvider : {reverseGeocode: Function}
  @Inject logger: iLogger
  @Inject settingsManager : iSettingsManager

  constructor (props: Props) {
    super(props)
    autoBind(this)
    this.state = {
      point: props.point
    }
  }

  async data () : Promise<?LocationPoint> {
    return this.state.point
  }

  async command () {}

  handleClick : Arrow = (point: Point) => {
    if (!this.addressProvider) { // Web-panel can't provide addressProvider by itself
      this.logger.warn('This component requires "addressProvider" with "reverseGeocode" method')
      return
    }
    const [latitude, longitude] = point
    this.setState({ point: { latitude, longitude } })
  }

  async componentDidUpdate (prevProps: Props, prevState: State) {
    if (!this.addressProvider) { // Web-panel can't provide addressProvider by itself
      this.logger.warn('This component requires "addressProvider" with "reverseGeocode" method')
      return
    }

    const point = this.state.point
    const prevPoint = prevState.point
    if (point && (!prevPoint || prevPoint.latitude !== point.latitude || prevPoint.longitude !== point.longitude)) {
      const { latitude, longitude } = point
      const cityId = this.props.cityId
      const serviceId = this.props.serviceId
      const [response] = await this.addressProvider.reverseGeocode({ where: { latitude, longitude, cityId, serviceId } })
      if (response) {
        const { street, house, regionId, markupFixed, markupPercent } = response
        this.setState({
          point: {
            latitude,
            longitude,
            street,
            house,
            regionId,
            position: 0,
            markupFixed,
            markupPercent
          }
        })
      }
    }
  }

  @Cached({ isDeepEqual: false })
  transformLocationToPoint (point: ?LocationPoint) : ?Point {
    if (!point) return null
    const { latitude, longitude } = point
    return [latitude, longitude]
  }

  async mapSpecification () : Promise<MapSpecification> {
    const settings = await this.settingsManager.getValues([SETTINGS.GEO_TILES, SETTINGS.GEO_API_KEY])
    return {
      type: settings[SETTINGS.GEO_TILES],
      key: settings[SETTINGS.GEO_API_KEY]
    }
  }

  panStrategy ({ width, height, zoom, center }: PanStrategyArgs) : { zoom: ?number, center: Point } {
    const { point } = this.state
    if (!point) return { zoom, center }
    if (isNil(point.latitude) || isNil(point.longitude)) return { zoom, center }
    return {
      zoom: 17,
      center: [point.latitude, point.longitude]
    }
  }

  render () : React.Node {
    const { center } = this.props
    const { point } = this.state
    const markerPosition = this.transformLocationToPoint(point)

    const { street, house } = point || {}
    const details = [street, house].join(' ').trim() || __('UNKNOWN_ADDRESS')

    return (
      <div className='location-view h400px'>
        <Map specification={this.mapSpecification} center={center} onClick={this.handleClick} panStrategy={this.panStrategy}>
          {(props) => (
            <>
              {
                markerPosition
                  ? (<PointMarker {...props} point={markerPosition} name='⇣' details={details} />)
                  : null
            }
            </>
          )}
        </Map>
      </div>
    )
  }
}

export default LocationView
