/**
 * @file DocxReport.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 3rd September 2021 12:34:47 pm
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow
 */
import type { TemplateData, iReportGenerator, Blob } from '../../types'

import createReport from 'docx-templates'

class DocxReport implements iReportGenerator {
  bufferToString (buffer: ArrayBuffer) : string {
    return new TextDecoder().decode(buffer)
  }

  async generate (template: ArrayBuffer, payload: TemplateData) : Promise<Blob> {
    const bytes = await createReport({
      template: Buffer.from(template),
      cmdDelimiter: ['{{', '}}'],
      data: payload
    })

    const blob = new global.Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
    return blob
  }
}

export default DocxReport

