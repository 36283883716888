/**
 * @file iconFlagCell.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 10th January 2020 10:11:20 am
 * @copyright 2015 - 2020 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { Props as DefaultCellProps } from './defaultCell'
import * as React from 'react'
import moize from 'moize'
import { Icon as IconComponent, If } from 'web-panel/components'
import DefaultCell from './defaultCell'

type Props = {|
  ...DefaultCellProps
|}

const Icon : (props: { name: string }) => React.Node = moize.react(({ name }) => (<IconComponent className='icon half-right-margin' icon={name} />))

function IconFlagCell ({ className, column, parentName, tooltipId, record, columnIndex, rowIndex, style, onContextMenu, onDoubleClick, onClick, value }: Props) : React.Node {
  const cellStyle = {
    ...style,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row'
  }

  const icon = this.icon
  const reverseIcon = this.reverseIcon

  let representField = record[this.referenceField]
  if (this.mode && this.mode === 'reverse') representField = !representField

  return (
    <DefaultCell
      column={column}
      record={record}
      parentName={parentName}
      columnIndex={columnIndex}
      rowIndex={rowIndex}
      className={className}
      style={cellStyle}
      onContextMenu={onContextMenu}
      onDoubleClick={onDoubleClick}
      onClick={onClick}
      tooltipId={tooltipId}
      value={value}
    >
      <>
        <If condition={representField}>
          <Icon name={icon} />
        </If>
        <If condition={!representField}>
          {
            reverseIcon
              ? <Icon name={reverseIcon} />
              : null
          }
        </If>
        {value}
      </>
    </DefaultCell>
  )
}

export default IconFlagCell
