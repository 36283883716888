/**
 * @file WindowManager.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 22nd August 2019 4:24:37 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow
 */

import type { Window, iWindowManager, WindowModel, iShortcutsManager, iLogger, iExposedView, iGlobalEventBus } from '../../types'
import type { ID } from 'web-panel-essentials/types'
import type { LocationPoint } from 'skat-js/types'

import { __, getViewportSize, KEYBINDING_SCOPE, assert, isRTL } from '../../globals'
import * as React from 'react'

import Modal from '../../views/modal/Modal'
import shortid from 'shortid'

import { Inject, Injectable } from '../../serviceLocator'

import { mix } from 'mixwith'
import mList from '../../utils/mList'
import Delegate from '../../utils/Delegate'
import ModalList from '../../views/modal/ModalList'
import LocationView from '../../views/map/LocationView'
import Prompt from '../../views/Prompt'
import autoBind from 'react-autobind'
import { CAST } from 'web-panel-essentials/misc'

import { OperationCancelled } from 'web-panel-essentials/errors'
import Msg from './Msg'
import ExtraActions from './ExtraActions'
import { getRemUnitSize } from 'web-panel/globals'
import pick from 'lodash/pick'

const DEFAULT_OFFSET = 40
const ROTATION_LIMIT_X = 20
const ROTATION_LIMIT_Y = 10

export type DialogOptions = any

export type LocationOptions = {
  center: [number, number],
  point?: LocationPoint,
  dialogOptions: DialogOptions,
  cityId: number,
  orderCityId: number,
  serviceId: number
}

/**
 * React view for fast rendering button collection changes
 * @private
 * @class _ToolBar
 * @extends {React.Component}
*/
class WindowManagerView extends mix(React.Component).with(mList) implements iWindowManager {
  @Inject shortcutsManager : iShortcutsManager
  @Inject logger : iLogger
  @Inject globalEventBus : iGlobalEventBus

  constructor (props) {
    super(props)
    autoBind(this)

    this.logger.namespace = 'modal-mgr'
    this.logger.info('Initializing modal manager')
    this.parent = props.parent
    this.state = {
      active: null,
      contextView: null,
      modals: this.defineList('modals'),
      positions: {}
    }

    this.shortcutsManager.register({
      name: 'COLLAPSE_ALL',
      title: __('COLLAPSE_ALL_OPENED_WINDOWS'),
      scope: [KEYBINDING_SCOPE.GLOBAL, null],
      defaultShortcut: 'alt+end',
      action: this.collapseAll
    })

    this.shortcutsManager.register({
      name: 'NEXT_WINDOW',
      title: __('SWITCH_TO_NEXT_WINDOW'),
      scope: [KEYBINDING_SCOPE.GLOBAL, null],
      defaultShortcut: 'alt+pageup',
      action: this.activateNext
    })

    this.shortcutsManager.register({
      name: 'PREV_WINDOW',
      title: __('SWITCH_TO_PREVIOUS_WINDOW'),
      scope: [KEYBINDING_SCOPE.GLOBAL, null],
      defaultShortcut: 'alt+pagedown',
      action: this.activatePrev
    })

    this.shortcutsManager.register({
      name: 'Close actieve window',
      title: __('CLOSE_CURRENT_ACTIVE_WINDOW'),
      scope: [KEYBINDING_SCOPE.GLOBAL, null],
      defaultShortcut: 'esc',
      action: () => {
        const [modal : ?WindowModel] = this.getActive()
        if (modal) this.close(modal.id)
      }
    })

    this.shortcutsManager.register({
      defaultShortcut: 'enter',
      name: 'primaryAction',
      scope: [KEYBINDING_SCOPE.GLOBAL, null],
      title: __('PRIMARY_ACTION'),
      action: () => {
        const modal = this.state.modals.find(({ id }) => id === this.state.active)
        if (modal && modal.primaryActionRef && modal.primaryActionRef.current) modal.primaryActionRef.current.simulateClick()
      }
    })

    this.globalEventBus.registerEvent('active-window-changed')
    this.globalEventBus.registerEvent('window-action-complete')
  }

  invokeLocationSelect (options: LocationOptions) : Promise<?LocationPoint> {
    const { center, point, dialogOptions, cityId, orderCityId, serviceId } = options

    return new Promise(async (resolve, reject) => { // eslint-disable-line
      const id = Symbol('any')
      const dialogDefaults = {
        id,
        title: __('SET_LOCATION'),
        label: __('SET_LOCATION'),
        details: __('SET_LOCATION'),
        icon: 'map-marker',
        width: 600,
        expandable: true,
        buttons: [{
          name: 'cancel',
          label: __('CANCEL'),
          title: __('CANCEL'),
          action: async () => {
            this.close(id)
            reject(new OperationCancelled(__('CANCELLED')))
          }
        }, {
          name: 'set',
          label: __('SET'),
          title: __('SET'),
          isPrimary: true,
          action: async (locationView) => {
            const point : ?LocationPoint = await locationView.data()
            resolve(point)
            this.close(id)
          }
        }]
      }

      await this.show({
        ...{ ...dialogDefaults, ...dialogOptions },
        view: (<LocationView center={center} point={point} cityId={cityId} orderCityId={orderCityId} serviceId={serviceId} />)
      })
      await this.activate(id)
    })
  }

  /**
   * Create modal window
   * @async
   * @param {Object} modal
   * @param {Symbol} modal.[id]
   * @param {Symbol} modal.name
   * @param {Symbol} modal.label
   * @param {Symbol} modal.title
   * @returns [ModalView, ModalChildView]
   */
  async show (modalWindow : Window) : Promise<iExposedView<any>> {
    this.logger.info('Rendering new modal window')
    const ref = await this.addModals({ ...modalWindow, id: modalWindow.id || Symbol('id') })
    return {
      data: ref.data,
      command: ref.command
    }
  }

  alert (options: { message: string, title?: string, icon?: string, parent?: ID, positiveButtonText?: string }) : Promise<true> {
    return new Promise((resolve, reject) => {
      const id = Symbol('id')
      this.show({
        id,
        parent: options.parent,
        name: 'alert',
        testName: 'alert-window',
        label: options.title || __('WARNING'),
        title: options.title || __('WARNING'),
        width: 360,
        icon: options.icon || 'exclamation-triangle',
        view: <Msg text={options.message} testName='alert-window' />,
        dispose: () => resolve(true),
        buttons: [{
          name: 'ok',
          label: options.positiveButtonText || 'OK',
          testName: 'ok-alert-button',
          title: options.positiveButtonText || 'OK',
          isPrimary: true,
          action: async () => {
            this.close(id)
            resolve(true)
          }
        }]
      }).then(() => {
        this.activate(id)
      })
    })
  }

  confirm (options: { message: string, title?: string, icon?: string, parent?: ID, positiveButtonText?: string, negativeButtonText?: string }) : Promise<boolean> {
    return new Promise((resolve, reject) => {
      const id = Symbol('id')
      this.show({
        id,
        parent: options.parent,
        name: 'confirm',
        testName: 'confirm-window',
        label: options.title || __('CONFIRM'),
        title: options.title || __('CONFIRM'),
        width: 360,
        icon: options.icon || 'question-circle',
        view: <Msg text={options.message} testName='confirm-window' />,
        dispose: () => resolve(false),
        buttons: [{
          name: 'cancel',
          testName: 'cancel-confirm-button',
          label: options.negativeButtonText || __('CANCEL'),
          title: options.negativeButtonText || __('CANCEL'),
          action: async () => {
            resolve(false)
            this.close(id)
          }
        }, {
          name: 'ok',
          testName: 'ok-confirm-button',
          label: options.positiveButtonText || __('OK'),
          title: options.positiveButtonText || __('OK'),
          isPrimary: true,
          action: async () => {
            resolve(true)
            this.close(id)
          }
        }]
      }).then(() => {
        this.activate(id)
      })
    })
  }

  prompt (options: { message: string, title?: string, icon?: string, parent?: ID, positiveButtonText?: string, negativeButtonText?: string, value?: string }) : Promise<string | null> {
    return new Promise((resolve, reject) => {
      const id = Symbol('id')
      this.show({
        id,
        parent: options.parent,
        name: 'confirm',
        testName: 'prompt-window',
        label: options.title || __('OK'),
        title: options.title || __('OK'),
        width: 360,
        icon: options.icon || 'question-circle',
        view: <Prompt message={CAST.String(options.message)} value={options.value || ''} />,
        dispose: () => resolve(null),
        buttons: [{
          name: 'cancel',
          testName: 'cancel-confirm-button',
          label: options.negativeButtonText || __('CANCEL'),
          title: options.negativeButtonText || __('CANCEL'),
          action: async (form) => {
            resolve(null)
            this.close(id)
          }
        }, {
          name: 'ok',
          testName: 'ok-confirm-button',
          label: options.positiveButtonText || __('OK'),
          title: options.positiveButtonText || __('OK'),
          isPrimary: true,
          action: async (form) => {
            const value = form.data()
            resolve(value)
            this.close(id)
          }
        }]
      }).then(() => {
        this.activate(id)
      })
    })
  }

  getActive () : [?WindowModel, ?iExposedView<any>] {
    const modal = this.state.modals.find(({ id }) => id === this.state.active)
    if (modal) {
      const model = pick(modal, ['id', 'parent', 'testName', 'label', 'title', 'icon', 'width', 'height', 'buttons', 'className', 'expandable'])
      const subView = modal.childRef.current
      const view = {
        data: subView.data.bind(subView),
        command: subView.command.bind(subView)
      }
      return [model, view]
    }
    return [null, null]
  }

  getById (id: ID): [?WindowModel, ?iExposedView<any>] {
    const modal = this.state.modals.find((modal) => modal.id === id)
    if (modal) {
      const model = pick(modal, ['id', 'parent', 'testName', 'label', 'title', 'icon', 'width', 'height', 'buttons', 'className', 'expandable'])
      const subView = modal.childRef.current
      const view = {
        data: subView.data.bind(subView),
        command: subView.command.bind(subView)
      }
      return [model, view]
    }
    return [null, null]
  }

  async close (id) : Promise<void> {
    if (id) {
      const modal = this.state.modals.find(modal => modal.id === id)
      if (modal.onBeforeClose) {
        if (modal.ref && modal.ref.current) {
          const proceed = await modal.onBeforeClose({
            data: modal.childRef.current.data.bind(modal.childRef.current),
            command: modal.childRef.current.command.bind(modal.childRef.current)
          })
          if (proceed === false) return
        }
      }
      await this.removeModals({ id })
      if (modal && modal.parent) await this.activate(modal.parent)
    }
  }

  async toggleHelp (id) : Promise<void> {
    const modal = this.state.modals.find(modal => modal.id === id)
    if (!modal) return

    await this.updateModals({ id: id, helpVisible: !modal.helpVisible })
  }

  async activate (id) {
    if (this.state.active === id) return

    if (this.state.active !== id) {
      this.globalEventBus.emit('active-window-changed', { id: id })
    }

    const child = this.getChilds(id)
    if (child) {
      await this.updateModals({ id: child.id, isFlashed: false, isCollapsed: false })
      return this.activate(child.id)
    }

    const modal = this.state.modals.find(modal => modal.id === id)
    if (!modal) return
    if (modal.isCollapsed) this.flash({ id })
    else {
      global.requestAnimationFrame(() => {
        if (modal.childRef && modal.childRef.current && modal.childRef.current.activate) modal.childRef.current.activate()
        this.setState({ active: id, isFlashed: false })
      })
    }
  }

  hasWindow (id: ID) : boolean {
    return Boolean(this.state.modals.find(modal => modal.id === id))
  }

  async flash ({ id } : { id: ID }) {
    await this.updateModals({ id, isFlashed: true })
  }

  async unflash ({ id } : { id: ID }) {
    await this.updateModals({ id, isFlashed: false })
  }

  async deactivate (id) {
    this.setState({ active: null })
    this.globalEventBus.emit('active-window-changed', { id: null })
  }

  activateNext () {
    let { active, modals } = this.state
    modals = modals.filter(({ conceal }) => !conceal)
    const index = modals.findIndex(({ id }) => id === active)
    const next = modals[index + 1] || modals[0]
    if (next) this.activate(next.id)
  }

  activatePrev () {
    let { active, modals } = this.state
    modals = modals.filter(({ conceal }) => !conceal)
    const index = modals.findIndex(({ id }) => id === active)
    const prev = modals[index - 1] || modals[modals.length - 1]
    if (prev) this.activate(prev.id)
  }

  async updateButton (windowId: ID, buttonId: ID, changes: Object) {
    const modal = this.state.modals.find(({ id }) => id === windowId)
    if (modal) {
      let button = modal.buttons.find(({ id }) => id === buttonId)
      if (button) {
        button = { ...button, ...changes }
        modal.buttons = modal.buttons.map((existingButton) => {
          if (existingButton.id && existingButton.id === buttonId) return button
          else return existingButton
        })
        await this.updateModals([modal])
      }
    }
  }

  async toggle ({ id, isCollapsed }) {
    if (!isCollapsed && id !== this.state.active) return this.activate(id)
    isCollapsed = !isCollapsed
    await this.unflash({ id })

    if (!isCollapsed) this.activate(id)
    else this.deactivate(id)

    this.updateModals([{ id, isCollapsed }])
  }

  async expand ({ id }) {
    this.logger.debug('Expanding window', id)
    await this.unflash({ id })
    await this.activate(id)
    const modal = this.state.modals.find((modal) => id === modal.id)

    if (modal.isExpanded) await this.updateModals([{ id, isExpanded: false }])
    else await this.updateModals([{ id, isExpanded: true }])
  }

  async collapse ({ id }) {
    this.logger.debug('Collapsing window', id)
    await this.unflash({ id })
    await this.deactivate(id)
    await this.updateModals([{ id, isCollapsed: true }])
  }

  async showError (id: ID, error: string) {
    assert(id, { id }, 'The target window id to show error is specified')
    await this.updateModals([{ id, error }])
  }

  async hideError (id: ID) {
    assert(id, { id }, 'The target window id to hide error is specified')
    await this.updateModals([{ id, error: null }])
  }

  async collapseAll () {
    await this.updateAllModals({ isCollapsed: true })
    this.globalEventBus.emit('active-window-changed', { id: null })
  }

  handleCollapseAll () {
    this.collapseAll()
  }

  _preAddModals (items) {
    return items.map((i) => {
      i[Symbol.for('key')] = shortid()
      i.defaultPosition = this._allocatePosition({
        id: i.id,
        width: i.width || 500,
        widthUnit: i.widthUnit || 'px',
        offsetY: i.offsetY
      })
      i.ref = React.createRef()
      i.primaryActionRef = React.createRef()
      i.childRef = React.createRef()
      return i
    })
  }

  _postAddModals (items) {
    return items.map(i => i.childRef.current)
  }

  _preRemoveModals (items) {
    let { positions, active } = this.state
    items.forEach(item => {
      delete positions[item.id]
      if (active === item.id) active = null
    })
    this.setState({
      positions,
      active
    })
    return items
  }

  _postRemoveModals (items) {
    let { modals, active } = this.state

    if (active === null) {
      modals = modals.filter(({ isCollapsed }) => !isCollapsed)
      active = modals.length ? modals[modals.length - 1].id : null
      this.setState({ active })
    }
  }

  _updateModalPosition ({ id, x, y }) {
    const { positions } = this.state
    positions[id] = { x, y }
    this.setState({ positions })
  }

  async _allocatePosition ({ id, width, widthUnit, offsetY }) {
    const winSize = getViewportSize()
    const positions = this.state.positions
    if (widthUnit === 'rem') width = width * getRemUnitSize()

    const RTLMirroringOffset = isRTL() ? width : 0
    const initialPosition = {
      x: Math.ceil(winSize.width / 2) - width / 2 + RTLMirroringOffset,
      y: Math.max(Math.ceil(winSize.height / 2) - Math.ceil((width * 0.75) / 2) + Math.ceil(CAST.Number(offsetY) * winSize.height), 0)
    }

    const positionsList = Object.getOwnPropertySymbols(positions).map((key) => positions[key])
    let newPostition = initialPosition
    let increment = 0
    while (newPostition === initialPosition && increment < ROTATION_LIMIT_X) {
      const attempt = {
        x: Math.ceil(initialPosition.x + increment % ROTATION_LIMIT_X * DEFAULT_OFFSET),
        y: Math.ceil(initialPosition.y + increment % ROTATION_LIMIT_Y * DEFAULT_OFFSET)
      }
      if (positionsList.some((position) => position.x === attempt.x && position.y === attempt.y)) {
        increment++
        continue
      }
      newPostition = attempt
    }

    positions[id] = newPostition
    await this.setState({ positions })
    return newPostition
  }

  handleIndicatorClick ({ id, isCollapsed }) {
    this.toggle({ id, isCollapsed })
  }

  async updateContextView (contextView: React.Node) {
    this.setState({ contextView })
  }

  hasChilds = (id: ID) : boolean => {
    return this.state.modals.some(({ parent }) => id === parent)
  }

  getChilds = (id: ID) : WindowModel => {
    return this.state.modals.find(({ parent }) => id === parent)
  }

  handleCloseButtonPressed = (id) => {
    this.close(id)
  }

  handleHelpButtonPressed = (id) => {
    this.toggleHelp(id)
  }

  handleCollapseButtonPressed = (id) => {
    this.collapse({ id })
  }

  handleExpandButtonPressed = (id) => {
    this.expand({ id })
  }

  handleMouseDown = (id) => {
    this.activate(id)
  }

  handleDragStop = (a, { lastX, lastY }, id) => {
    this._updateModalPosition({ id: id, x: lastX, y: lastY })
  }

  render () : React.Node {
    const { modals, active, positions, contextView } = this.state
    const viewport = {
      width: window.innerWidth,
      height: window.innerHeight
    }

    return (
      <div className='modals-controls'>
        <div className='modal-layer'>
          {modals.map((modal, idx) => {
            const position = positions[modal.id]
            const view = React.cloneElement(modal.view, { ref: modal.childRef, ...modal.childProps })
            return (
              <Modal
                id={modal.id}
                name={modal.name}
                error={modal.error}
                ref={modal.ref}
                modalRef={modal.ref}
                childRef={modal.childRef}
                key={modal[Symbol.for('key')]}
                testName={modal.testName}
                label={modal.label}
                title=''
                icon={modal.icon}
                width={modal.width}
                widthUnit={modal.widthUnit}
                expandable={modal.expandable}
                height={modal.height}
                className={modal.className}
                hasChilds={this.hasChilds(modal.id)}
                buttons={modal.buttons}
                primaryActionRef={modal.primaryActionRef}
                onDispose={modal.dispose} // eslint-disable-line
                isActive={modal.id === active}
                isCollapsed={modal.isCollapsed}
                isExpanded={modal.isExpanded}
                conceal={modal.conceal}
                onMouseDown={this.handleMouseDown}
                onCollapseButtonPressed={this.handleCollapseButtonPressed}
                onExpandButtonPressed={this.handleExpandButtonPressed}
                onCloseButtonPressed={this.handleCloseButtonPressed}
                onHelpButtonPressed={this.handleHelpButtonPressed}
                defaultPosition={modal.defaultPosition}
                position={position}
                onDragStop={this.handleDragStop}
                viewport={viewport}
                help={modal.help}
                helpVisible={modal.helpVisible}
                extraView={modal.extraView}
                view={view}
              />
            )
          }
          )}
        </div>
        <ModalList
          modals={modals}
          active={active}
          onIndicatorClick={this.handleIndicatorClick}
        />
        <ExtraActions onCollapseAll={this.handleCollapseAll} contextView={contextView} />
      </div>
    )
  }
}

/**
 * Прокси обвязка вокруг WindowManagerView
 */
@Injectable('windowManager', true)
class WindowManager extends Delegate implements iWindowManager {
  @Inject layout : any
  ready: Promise<void>

  alert: Function
  confirm: Function
  prompt: Function
  activate: Function
  deactivate: Function
  show: Function
  getActive: Function
  updateButton: Function
  close: Function
  hasWindow: Function
  updateContextView: Function
  hideError: Function
  showError: Function
  getById: Function

  constructor () {
    super()
    this.ready = new Promise(async (resolve) => { //eslint-disable-line
      const view = await this.layout.renderView(<WindowManagerView />, 'footer-region')
      this.becomeProxyOf(view)
      resolve()
    })
  }
}

export default WindowManager
