/**
 * @file TextReport.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 3rd September 2021 11:02:16 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow
 */
import type { TemplateData, iReportGenerator, Blob } from '../../types'

import { compile } from 'handlebars'

class TextReport implements iReportGenerator {
  bufferToString (buffer: ArrayBuffer) : string {
    return new TextDecoder().decode(buffer)
  }

  async generate (template: ArrayBuffer, payload: TemplateData) : Promise<Blob> {
    const text = this.bufferToString(template)
    const renderer = compile(text)
    const rendered = renderer(payload)
    return new global.Blob([rendered], { type: 'text/plain' })
  }
}

export default TextReport
