/**
 * @file PhoneInput.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 10th January 2020 10:11:20 am
 * @copyright 2015 - 2020 SKAT LLC, Delive LLC
 * @flow
 */

import * as React from 'react'

import { genTestName } from 'web-panel/globals'
import { CAST } from 'web-panel-essentials/misc'
import cn from 'classnames'
import autoBind from 'react-autobind'
import { Icon } from 'web-panel/components'

export type Props = {
  className?: string,
  testName?: string,
  placeholder?: string,
  value?: string,
  displayValue?: string,
  focused?: boolean,
  disabled?: boolean,
  onFocus?: Function,
  onBlur?: Function,
  onChange?: Function,
  bgColor?: string | null,
  fgColor?: string | null,
  indicatorHint?: string,
  iconName: string,
  iconColor: string
}

export type State = {
  focused: boolean
}

class IndicatorInput extends React.Component<Props, State> {
  input: React.ElementRef<any>

  constructor (props: Props) {
    super(props)
    autoBind(this)
    this.state = {
      focused: false
    }
    this.input = React.createRef()
  }

  handleChange (ev:any) : void {
    const displayValue = ev.target.value
    this.props.onChange && this.props.onChange({ displayValue })
  }

  focus () : void {
    this.input.focus()
    this.setState({ focused: true })
  }

  handleFocus () : void {
    this.focus()
    this.props.onFocus && this.props.onFocus()
  }

  handleBlur () : void {
    this.setState({ focused: false })
    this.props.onBlur && this.props.onBlur()
  }

  render () : React.Node {
    const { className, iconColor, disabled, value, displayValue, placeholder, testName, indicatorHint, iconName } = this.props
    const { focused } = this.state

    const inputValue = CAST.String(displayValue) || CAST.String(value)
    return (
      <div
        className={cn(className, 'field', 'field-autocomplete', { focused, disabled })}
        data-title={placeholder}
        onClick={this.handleFocus}
        style={{ backgroundColor: this.props.bgColor }}
      >
        <div className='field-data'>
          <input
            {...genTestName('indicator-input', testName)}
            disabled={disabled}
            ref={(input) => { this.input = input }}
            placeholder={placeholder}
            className='field-input'
            value={inputValue}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            style={{ color: this.props.fgColor }}
          />
        </div>
        <div data-title={indicatorHint} className={cn('field-button', 'non-interactive')}>
          <Icon className='icon' style={{ color: iconColor }} icon={iconName} />
        </div>
      </div>
    )
  }
}

export default IndicatorInput
