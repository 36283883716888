/**
 * @file Reset.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 19th October 2021 7:31:41 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow strict
 */
/* global SyntheticEvent, HTMLElement */
import type { asyncVoid } from '../types'

import * as React from 'react'
import { __, isRTL } from '../globals'
import cn from 'classnames'
import Icon from './Icon'

type Props = {
  active: boolean,
  onClick: (event: SyntheticEvent<HTMLElement>) => asyncVoid
}

export default function Reset ({ onClick, active }: Props) : React.Node {
  const handleClick = (event: SyntheticEvent<HTMLElement>) => onClick(event)
  return (
    <Icon
      tabIndex={-1}
      title={__('RESET')}
      icon={isRTL() ? 'circle-xmark' : 'backspace'}
      className={cn('reset-icon', { active })}
      onClick={handleClick}
    />
  )
}
