/**
 * @file utils.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 7th July 2022 9:40:58 am
 * @copyright 2015 - 2022 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { Point } from 'web-panel-essentials/types'
import type { Bounds } from '../types'

const lngInside = (lng, bounds: Bounds) => lng > bounds.w && lng < bounds.e
const latInside = (lat, bounds: Bounds) => lat > bounds.s && lat < bounds.n

export function pointIsOutOfBounds ([lat, lng]: Point, bounds: Bounds) : boolean {
  return !latInside(lat, bounds) || !lngInside(lng, bounds)
}
