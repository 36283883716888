/**
 * @file headerFilterCell.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 16th February 2021 6:28:33 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow strict
 */
/* global SyntheticKeyboardEvent, HTMLInputElement */
import type { ElementEvent, asyncVoid } from 'web-panel/types'

import * as React from 'react'

import { KEY } from 'web-panel-essentials/misc'
import { __ } from 'web-panel/globals'
import autoBind from 'react-autobind'
import cn from 'classnames'
import { Icon } from 'web-panel/components'

type Props = {
  value: string,
  columnName: string,
  style: {},
  tabIndex: number,
  onChange: (value: string, columnName: string) => asyncVoid,
}

class HeaderFilterCell extends React.PureComponent<Props> {
  constructor (props: Props) {
    super(props)
    autoBind(this)
  }

  handleKeydown (event: SyntheticKeyboardEvent<HTMLInputElement>) : void {
    if (event.keyCode === KEY.ENTER) {
      event.stopPropagation()
      event.preventDefault()

      const { columnName, value } = this.props
      if (!value.trim().startsWith('=')) {
        this.props.onChange('=' + value.trim(), columnName)
      }
    }
  }

  handleChange (event: ElementEvent) {
    this.props.onChange(event.target.value, this.props.columnName)
  }

  handleReset () {
    this.props.onChange('', this.props.columnName)
  }

  render () : React.Node {
    const { value, style, tabIndex } = this.props
    return (
      <div className={cn('filterCell', { active: Boolean(value) })} style={style}>
        <input
          value={value}
          tabIndex={tabIndex}
          placeholder={__('NOT_SET')}
          onChange={this.handleChange}
          onKeyDown={this.handleKeydown}
          type='text'
          className='filterField'
          data-title='Для поиска точного совпадения укажите знак равенства "=" перед искомой строкой или нажмите на кнопку Enter'
        />
        <Icon
          title={__('RESET')}
          icon='backspace'
          className={cn('reset-icon', { active: Boolean(value) })}
          onClick={this.handleReset}
        />
      </div>
    )
  }
}

export default HeaderFilterCell
