/**
 * @file Toolbar.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 11th July 2019 10:32:32 am
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow
 */
import type { ID } from 'web-panel-essentials/types'
import type { iToolbarButton, Page } from 'web-panel/types'

import * as React from 'react'
import ButtonList from './ButtonList'

type Props = {
  page: ID,
  pages: {[ID]: Page},
  buttons: iToolbarButton[]
}

/**
 * React view for fast rendering button collection changes
 * @private
 * @class _ToolBar
 * @extends {React.Component}
*/
class Toolbar extends React.PureComponent<Props> {
  async handleClick (button: iToolbarButton) {
    await button.action(button)
  }

  render () : React.Node {
    const { page, pages, buttons } = this.props

    let localButtons = []
    const currentPage = pages[page]
    if (currentPage) localButtons = currentPage.buttons // localButtons.filter(button => button.page === page).sort(sortByPriority)

    return (
      <div className='toolbar-buttons'>
        <div className='buttons-container'>
          <ButtonList onClick={this.handleClick} type='local' buttons={localButtons} />
          <ButtonList onClick={this.handleClick} type='global' buttons={buttons} />
        </div>
      </div>
    )
  }
}

export default Toolbar
