/**
 * @file Title.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 24th June 2022 7:59:53 am
 * @copyright 2015 - 2022 SKAT LLC, Delive LLC
 * @flow strict
 */
import * as React from 'react'
import { Icon } from 'web-panel/components'
import { genTestName } from 'web-panel/globals'
import cn from 'classnames'

type Props = {
  title: string,
  icon: string,
  label: string,
  testName?: string,
  invisible?: boolean
}

export default function Title (props: Props) : React.Node {
  const { icon, title, label, testName, invisible } = props
  const className = cn('title-text', 'a-v', { invisible })
  return (
    <span data-title={title} className={className}>
      <Icon className='icon' icon={icon} />
      <span className='ellipsize' {...genTestName('title-text-modal', testName)}>{label}</span>
    </span>
  )
}
