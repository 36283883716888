/**
 * @file Layout.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 7th September 2021 12:34:04 pm
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow
 */
import type { iLogger } from '../types'

import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { Injectable, Inject } from '../serviceLocator'
import { TypeError } from 'web-panel-essentials/errors'
import { __ } from '../globals'

@Injectable('layout', true)
class Layout {
  @Inject logger : iLogger

  renderView (view: React.ElementType, targetRegionId: string) : Promise<React.ElementRef<any>> {
    if (!targetRegionId) throw new TypeError(__('TARGET_REGION_REQUIRED'))
    const element = (id) => document.getElementById(id)

    this.logger.info('Rendering', targetRegionId)
    const root = createRoot(element(targetRegionId))
    return new Promise((resolve) => {
      // $FlowFixMe[incompatible-type]
      root.render(React.cloneElement(view, { ref: (a) => resolve(a) }))
    })
  }
}

export default Layout
