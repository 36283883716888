/**
 * @file Layout.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Wednesday, 8th September 2021 8:36:14 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow
 */
import type { Page, iToolbarButton } from 'web-panel/types'
import type { ID } from 'web-panel-essentials/types'

import * as React from 'react'
import autoBind from 'react-autobind'
import Sidebar, { pageMap } from './Sidebar'
import Toolbar from './Toolbar'
import cn from 'classnames'
import { CAST } from 'web-panel-essentials/misc'
import ThrottledComponent from 'web-panel/utils/ThrottledComponent'

const sortByPriority = (a: iToolbarButton, b: iToolbarButton) => CAST.Number(b.priority) - CAST.Number(a.priority)

const Header = ({ pages, buttons, activePage }) => (
  <div role='region' id='header-region'>
    <div role='region' id='branding-region'>
      <div id='logo' />
    </div>

    <div role='region' id='toolbar-region'>
      <Toolbar page={activePage} pages={pages} buttons={buttons} />
      <div role='region' id='info-region' />
      <div role='region' id='context-region' />
    </div>
  </div>
)

const Pages = ({ pages, active, activated }) => {
  return (
    <div className='pages'>
      {pageMap(pages, (page) => {
        const className = cn('page-body', { conceal: page.id !== active }, page.className)
        if (activated[page.id]) {
          return (
            <div key={page.name} className={className}>
              {/* $FlowFixMe */}
              {page.view ? React.cloneElement(page.view, { conceal: page.id !== active }) : null}
            </div>
          )
        } else return null
      })}
    </div>
  )
}

type Props = {}
type State = {
  globalButtons: iToolbarButton[],
  pages: {[ID]: Page},
  activePage: ?ID,
  activated: {[ID]: true}
}

class Layout extends ThrottledComponent<Props, State> {
  constructor (props: Props) {
    super(props)
    autoBind(this)

    this.state = {
      pages: {},
      activePage: null,
      activated: {},
      globalButtons: []
    }
  }

  getActivePage () : Page {
    return this.stateValues.pages[this.state.activePage]
  }

  getPageById (id: ID) : ?Page {
    return this.stateValues.pages[id]
  }

  async addPage (page: Page) : Promise<void> {
    const id: ID = page.id
    // $FlowFixMe
    const pages = { ...this.stateValues.pages, [id]: page }
    this.setState({ pages })
  }

  async addGlobalToolbarButtons (buttons: iToolbarButton[]) : Promise<void> {
    let { globalButtons } = this.stateValues
    globalButtons = [...globalButtons, ...buttons].sort(sortByPriority)
    this.setState({ globalButtons })
  }

  async removeGlobalToolbarButtons (ids: ID[]) : Promise<void> {
    const { globalButtons } = this.stateValues
    const filtered = globalButtons.filter(button => !ids.includes(button.id))
    this.setState({ globalButtons: filtered.sort(sortByPriority) })
  }

  async mutate (pageId: ID, mutator: (source: iToolbarButton[]) => iToolbarButton[]) {
    const { pages } = this.stateValues
    const page = pages[pageId]
    if (page) {
      const changed = mutator(page.buttons)
      this.setState({
        pages: {
          ...pages,
          // $FlowFixMe
          [page.id]: {
            ...page,
            buttons: changed.sort(sortByPriority)
          }
        }
      })
    }
  }

  async addButtonsToPage (buttons: iToolbarButton[], pageId: ID) {
    await this.mutate(pageId, (source) => source.concat(buttons))
  }

  async removeButtonsFromPage (ids: ID[], pageId: ID) {
    await this.mutate(pageId, (source) => source.filter(button => ids.includes(button.id)))
  }

  async updatePageButtons (buttons: iToolbarButton[], pageId: ID) {
    await this.mutate(pageId, (source) => source.map(sourceButton => {
      const update = buttons.find((button) => sourceButton.id === button.id)
      // $FlowFixMe
      if (update) return { ...sourceButton, ...update }
      return sourceButton
    }))
  }

  async activatePage (id: ID) {
    this.stateValues.activePage = id
    // $FlowFixMe[invalid-computed-prop]
    this.stateValues.activated = { ...this.stateValues.activated, [id]: true }
    this.highlightPage(id, false)
  }

  async highlightPage (id: ID, highlighted:boolean = true) {
    const page = { ...this.stateValues.pages[id], highlighted }
    this.stateValues.pages = {
      ...this.stateValues.pages,
      // $FlowFixMe
      [id]: page
    }
    this.flush()
  }

  handleActivate (active: ID) {
    this.activatePage(active)
  }

  render () : React.Node {
    const { pages, globalButtons, activePage, activated } = this.state
    return (
      <>
        <Header activePage={activePage} pages={pages} buttons={globalButtons} />
        <div role='region' id='content-region'>
          <Sidebar pages={pages} active={activePage} onActivate={this.handleActivate} />
          <div className='main'>
            <div className='extra-padding'>
              <div role='region' id='main-region'>
                <Pages pages={pages} active={activePage} activated={activated} />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Layout
