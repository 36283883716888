/**
 * @file Notification.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 24th September 2021 10:14:07 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow
 */
import type { InnerMessage } from './NotificationManager'

import * as React from 'react'
import autoBind from 'react-autobind'
import { __, genTestName } from 'web-panel/globals'
import { CAST } from 'web-panel-essentials/misc'
import Icon from './Icon'
import cn from 'classnames'
import { If } from '../../components'

const MIDDLE_MOUSE_BUTTON = 1

class Notification extends React.PureComponent<InnerMessage> {
  constructor () {
    super()
    autoBind(this)
  }

  handleMouseDown (event: {button: number}) : void {
    const { onClose, unclosable } = this.props
    if (event.button === MIDDLE_MOUSE_BUTTON && !unclosable) onClose()
  }

  handleDeny () : void {
    this.props.onDeny()
  }

  render () : React.Node {
    let { testName, message, details, type, progress, onClose, wasAlreadyInvokedBefore, buttons = [], unclosable, icon } = this.props
    progress = Math.min(100, CAST.Number(progress))

    return (
      <div {...genTestName(testName)} onMouseDown={this.handleMouseDown} className={cn('alert', ['alert', type].join('-'), 'alert-dismissable')}>
        <If condition={Boolean(icon)}>
          <Icon iconName={String(icon)} />
        </If>

        <div className='message'>
          <span className={cn({ bold: Boolean(details) })}>{message}</span>
          {details ? <div className='details'>{details}</div> : null}
          <div className='buttons'>
            {buttons.map((button, index) => <ActionButton key={index} button={button} />)}
          </div>
          <ProgressLine progress={progress} />
          <NotAskAgainButton display={wasAlreadyInvokedBefore && !unclosable} onDeny={this.handleDeny} />
        </div>

        <CloseButton unclosable={unclosable} onClick={onClose} />
        <br className='clear' />
      </div>
    )
  }
}

function CloseButton ({ unclosable, onClick }) : React.Node {
  if (unclosable) return null
  return (
    <button onClick={onClick} type='button' className='close'>
      &times;
    </button>
  )
}

function ActionButton ({ button }) : React.Node {
  const className = cn('button', 'action-button', button.type ? ['button', button.type].join('-') : null)
  return (
    <div onClick={() => button.action()} type='button' className={className}>
      <span>{button.title}</span>
    </div>
  )
}

function ProgressLine ({ progress }) : React.Node {
  if (!progress) return null
  const width : string = [Number(progress), '%'].join('')

  return (
    <div className='progress'>
      <div style={{ width }} className='inner-line' />
    </div>
  )
}

function NotAskAgainButton ({ display, onDeny }) : React.Node {
  if (!display) return null
  return (
    <div className='dont-show-again' onClick={onDeny}>
      {__('DONT_SHOW_SUCH_MESSAGES_ANYMORE')}
    </div>
  )
}

export default Notification
