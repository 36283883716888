// @flow
import type { iConnectionTransport, iLogger, iREST, iEventEmitter, EmitterEvents, JSONValue } from '../types'
import socketIOClient from 'socket.io-client'
import sailsIOClient from 'sails.io.js'
import { Injectable, Inject } from '../serviceLocator'

@Injectable('ioTransport')
class IOTransport implements iConnectionTransport, iREST, iEventEmitter {
  @Inject logger : iLogger
  socket: any

  static get EVENT () : EmitterEvents {
    return {}
  }

  get EVENT () : EmitterEvents {
    return {}
  }

  constructor () {
    const io = sailsIOClient(socketIOClient)
    io.sails.autoConnect = true
    io.sails.reconnection = true
    io.sails.reconnectionAttempts = 10
    io.sails.reconnectionDelay = 10000
    this.socket = io.socket
  }

  connect () {
    this.logger.warn('STUB', 'Not implemented')
  }

  release (reason: number) : void {
    // NOOP
  }

  get connected () : boolean {
    return this.socket.isConnected()
  }

  on (...args: Array<any>) : boolean {
    return this.socket.on(...args)
  }

  emit (...args: Array<any>) : boolean {
    return this.socket.emit(...args)
  }

  once (eventName: string, callback: Function) : boolean {
    return this.socket.on(eventName, (...args) => {
      callback.apply(this.socket, args)
      this.off(eventName, callback)
    })
  }

  off (...args: Array<any>) : boolean {
    return this.socket.off(...args)
  }

  get (...args: Array<any>) : Promise<JSONValue> {
    return this.socket.get(...args)
  }

  post (...args: Array<any>) : Promise<JSONValue> {
    return this.socket.post(...args)
  }

  put (...args: Array<any>) : Promise<JSONValue> {
    return this.socket.put(...args)
  }

  delete (...args: Array<any>) : Promise<JSONValue> {
    return this.socket.delete(...args)
  }
}

export default IOTransport
