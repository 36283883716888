/**
 * @file Logger.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 26th July 2019 1:20:44 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @exports Logger
 * @flow
 */

import type { iLogger, iGlobalEventBus, iEventEmitter } from '../types'

import logdown from 'logdown'
import { Inject, Injectable } from '../serviceLocator'
import { TypeError } from 'web-panel-essentials/errors'
import { __, isTestingMode } from '../globals'
/**
 * Can be used to write any messages to developer console
 * @class
 * @implements iLogger
 * @injectable logger
 */
@Injectable('logger')
class Logger implements iLogger {
  _log: any

  get namespace () : void {}

  /**
   * Set current logger namespace.
   * Can be used as tag for filtering messages from
   * this logger inside developer console
   * @property
   */
  set namespace (name: string) {
    if (!name) throw new TypeError(__('NAMESPACE_IS_REQUIRED'))
    this._log = logdown('WP:' + name)
  }

  constructor () {
    this._log = logdown('WP')
    if (isTestingMode()) this._log.state.isEnabled = true
  }

  /**
   * @method
   * @param  {...any} args
   */
  info (...args: Array<mixed>) {
    this._log.info(...args)
  }

  /**
   * @method
   * @param  {...any} args
   */
  debug (...args : Array<mixed>) {
    this._log.debug(...args)
  }

  /**
   * @method
   * @param  {...any} args
   */
  warn (...args: Array<mixed>) {
    this._log.warn(...args)
  }

  /**
   * @method
   * @param  {...any} args
   */
  error (...args: Array<mixed>) {
    this._log.error(...args)
  }
}

export default Logger
