/**
 * @file WidgetFactory.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Wednesday, 17th February 2021 10:22:11 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow
 */
import type { iExposedView } from 'web-panel/types'

import * as React from 'react'
import autoBind from 'react-autobind'
import { Row, Col, Field, AutocompleteField } from '../components'
import { FSimpleDataProvider } from './../connections/DataProvider'

type Props = {}
type State = {
  fieldValue: string,
  autocompleteValue: number,
  autocompleteDisplayValue: string
}

const dataProvider = FSimpleDataProvider({
  payload: [
    { id: 1, name: 'Скат' },
    { id: 2, name: 'Софт' }
  ]
})
export default class WidgetFactory extends React.PureComponent<Props, State> implements iExposedView<void> {
  async data () {}
  async command () {}
  constructor (props: Props) {
    super(props)
    autoBind(this)
    this.state = {
      fieldValue: 'Initial value',
      autocompleteValue: 1,
      autocompleteDisplayValue: ''
    }
  }

  render () : React.Node {
    const { fieldValue, autocompleteValue, autocompleteDisplayValue } = this.state
    return (
      <div className='blueprint-desk h400px'>
        <Row>
          <Col size={6}>
            <Field
              displayValue={fieldValue}
              onChange={({ displayValue }) => this.setState({ fieldValue: displayValue })}
            />
          </Col>
          <Col size={6}>
            <AutocompleteField
              valuesBasedBlur
              onBlur={() => {
                console.log('BLE')
                console.trace()
              }}
              dataProvider={dataProvider}
              value={autocompleteValue}
              displayValue={autocompleteDisplayValue}
              onChange={({ value, displayValue }) => this.setState({ autocompleteValue: value, autocompleteDisplayValue: displayValue })}
            />
          </Col>
        </Row>
      </div>
    )
  }
}
