
/**
 * @file Modal.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 9th July 2019 2:22:27 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow
 */
import type { Window, iExposedView, asyncVoid } from 'web-panel/types'
import type { ID } from 'web-panel-essentials/types'

import * as React from 'react'
import cn from 'classnames'
import Draggable from 'react-draggable'
import Header from './Header'
import Footer from './Footer'
import Title from './Title'

import autoBind from 'react-autobind'
import { If } from 'web-panel/components'
import { genTestName } from 'web-panel/globals'
import isEqual from 'lodash/isEqual'
import Action from 'web-panel/components/Action'

type Viewport = { width: number, height: number }
type Position = { x: number, y: number }

export type Props = Window & {
  error: string | null,
  conceal: boolean,
  isActive: boolean,
  isCollapsed: boolean,
  isExpanded?: boolean,
  hasChilds: boolean,
  onDispose: Function,
  onMouseDown: Function,
  onDragStop: Function,
  onCloseButtonPressed: Function,
  onCollapseButtonPressed: Function,
  onHelpButtonPressed: (id: ID) => asyncVoid,
  onExpandButtonPressed: Function,

  viewport: Viewport,
  defaultPosition: Position,
  position: Position,

  modalRef: React.ElementRef<Modal>, //eslint-disable-line
  childRef: React.ElementRef<iExposedView<any>>,
}

type LastPositionPoint = {
  lastX: number,
  lastY: number
}

class Modal extends React.Component<Props, null> {
  container: React.ElementRef<any>
  focusFirst: React.ElementRef<any>
  focusLast: React.ElementRef<any>

  constructor (props: Object) {
    super(props)
    autoBind(this)
    this.container = React.createRef()
    this.focusFirst = React.createRef()
    this.focusLast = React.createRef()
  }

  handleClose () : void {
    this.props.onCloseButtonPressed(this.props.id)
  }

  handleHelpClick () : void {
    this.props.onHelpButtonPressed(this.props.id)
  }

  handleCollapse () : void {
    this.props.onCollapseButtonPressed(this.props.id)
  }

  handleExpand () : void {
    this.props.onExpandButtonPressed(this.props.id)
  }

  handleDragStop (a: any, { lastX, lastY }: LastPositionPoint) : void {
    if (this.props.isExpanded) return
    const position = {
      // We don't need fractional digits for pixels
      // as it would be the reason of a blurry lines
      lastX: Math.ceil(lastX),
      lastY: Math.ceil(lastY)
    }
    this.props.onDragStop(a, position, this.props.id)
  }

  handleMouseDown () : void {
    this.props.onMouseDown(this.props.id)
  }

  componentWillUnmount () {
    this.props.onDispose && this.props.onDispose()
  }

  shouldComponentUpdate (nextProps: Object) : boolean {
    const { position, viewport, isActive, helpVisible, isCollapsed, conceal, buttons, isExpanded, error } = this.props
    if (!isEqual(position, nextProps.position)) return true
    if (!isEqual(viewport, nextProps.viewport)) return true
    if (isActive !== nextProps.isActive) return true
    if (error !== nextProps.error) return true
    if (isCollapsed !== nextProps.isCollapsed) return true
    if (isExpanded !== nextProps.isExpanded) return true
    if (conceal !== nextProps.conceal) return true
    if (helpVisible !== nextProps.helpVisible) return true
    if (!isEqual(buttons, nextProps.buttons)) return true
    if (this.props.view !== nextProps.view) return false
    return true
  }

  handleFocusToFirst (e: any) {
    this.focusFirst.current?.focus()
  }

  handleFocusToLast (e: any) {
    this.focusLast.current?.focus()
  }

  render () : React.Node {
    const {
      id,
      name,
      error,
      label,
      title = '',
      icon = 'circle',
      width = 500,
      widthUnit = 'px',
      height,
      view,
      modalRef,
      childRef,
      // $FlowFixMe[incompatible-use]
      primaryActionRef,
      extraView,
      buttons = [],
      isActive = false,
      isCollapsed = false,
      isExpanded = false,
      conceal = false,
      hasChilds = false,
      defaultPosition,
      position,
      helpVisible = false,
      viewport,
      expandable,
      className,
      testName,
      help
    } = this.props

    const modal = {
      id,
      name,
      label,
      title,
      view,
      modalRef,
      childRef
    }

    const bounds = {
      left: -width * 0.75,
      right: viewport.width - width * 0.25,
      top: 0,
      bottom: viewport.height - 40
    }

    const widthInUnits = [width, widthUnit].join('')
    return (
      <Draggable
        defaultPosition={defaultPosition}
        position={position}
        onStop={this.handleDragStop}
        onMouseDown={this.handleMouseDown}
        bounds={bounds}
        handle='.title'
      >
        <div
          {...genTestName(testName)}
          tabIndex={1}
          ref={this.container}
          className={cn('modal', className, { isActive, isCollapsed, isExpanded, conceal })}
          style={{ width: widthInUnits }}
        >
          <span tabIndex={0} onFocus={this.handleFocusToLast} />
          <span ref={this.focusFirst} tabIndex={0} />

          <If condition={helpVisible}>
            <div className='help-content'>
              <div className='title'>
                <Title
                  title={title}
                  icon={icon}
                  label={label}
                  testName={testName}
                />
              </div>
              <div className='full-padding h100'>
                {help}
              </div>
              <div className='footer'>
                <Action icon='check-circle' onClick={this.handleHelpClick} />
              </div>
            </div>
          </If>

          <div className='modal-content'>
            <Header
              closeDisabled={hasChilds}
              expandable={Boolean(expandable)}
              isExpanded={isExpanded}
              helpAvaialable={Boolean(help)}
              onCollapseClick={this.handleCollapse}
              onExpandClick={this.handleExpand}
              onCloseClick={this.handleClose}
              onHelpClick={this.handleHelpClick}
            >
              <Title
                title={title}
                icon={icon}
                label={label}
                testName={testName}
                invisible={helpVisible}
              />
            </Header>

            <div className='body' style={{ height }}>{view}</div>
            <div className={cn('error', { active: Boolean(error) })} {...genTestName('modal-error-container')}>
              <span className='error-text' {...genTestName('modal-error-text')}>{error}</span>
            </div>
            <Footer
              primaryActionRef={primaryActionRef}
              buttonsDisabled={hasChilds}
              buttons={buttons}
              extraView={extraView}
              modal={modal}
            />
          </div>
          <span ref={this.focusLast} tabIndex={0} />
          <span tabIndex={0} onFocus={this.handleFocusToFirst} />
        </div>
      </Draggable>
    )
  }
}

export default Modal
