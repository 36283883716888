/**
 * @file ExtraActions.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 8th June 2021 10:59:14 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow strict
 */

/* global SyntheticEvent, HTMLTextAreaElement, HTMLDivElement, $PropertyType  */
import type { iGlobalEventBus, CombinedValue, iCRUD, UserPreferences, Locale, ReactRef } from '../../types'
import type { iDataProvider } from 'web-panel-essentials/types'

import * as React from 'react'

import autoBind from 'react-autobind'
import { Icon, Slider, If, Row, Col, Select } from '../../components'
import { Button } from '../../views/forms/components'

import { getCurrentLocale, __ } from '../../globals'
import { Inject } from '../../serviceLocator'
import debounce from 'lodash/debounce'
import isNil from 'lodash/isNil'
import cn from 'classnames'
import { CAST } from 'web-panel-essentials/misc'

const DRAFT_TEXT_PREF_NAME = 'draft-text'
const NOTIFICATIONS_MODE_PREF_NAME = 'notifications-concealed'
const FONT_SCALE_PREF_NAME = 'font-scale'

type Props = {|
  contextView: React.Node,
  onCollapseAll: () => void
|}

type State = {|
  viewsVisibility: {[string]: boolean},
  notificationsVisible: boolean
|}

type Destroyable = {|
  onDestroyRequested: () => void
|}

class LocaleSelect extends React.PureComponent<Destroyable, CombinedValue> {
  @Inject localeProvider: iDataProvider<Locale>
  container: ReactRef<'div'>

  constructor () {
    super()
    autoBind(this)
    this.state = {
      value: '',
      displayValue: '...'
    }
    this.container = React.createRef()
  }

  async componentDidMount () {
    this.container.current?.focus()
    const [current] = await this.localeProvider.get({ where: { active: true } })
    this.setState({
      value: current.id,
      displayValue: CAST.String(current.name)
    })
  }

  handleBlur (event: SyntheticEvent<HTMLDivElement>) : void {
    const { currentTarget } = event
    global.requestAnimationFrame(() => {
      if (!currentTarget.contains(document.activeElement)) {
        this.props.onDestroyRequested()
      }
    })
  }

  handleChange (changes: CombinedValue) {
    this.setState(changes)
    this.changeLocale(changes.value)
  }

  changeLocale (code: string) {
    window.location = '/?lang=' + code + window.location.hash
  }

  render () : React.Node {
    const { value, displayValue } = this.state
    return (
      <div className='locale-select' tabIndex={1} onBlur={this.handleBlur} ref={this.container}>
        <Row>
          <Col size={4} className='v-a lang-col'>
            {__('LANGUAGE')}
            <Icon icon='language' />
          </Col>
          <Col size={8}>
            <Select
              onChange={this.handleChange}
              value={value}
              displayValue={displayValue}
              containerClassName='inverse-colors'
              dataProvider={this.localeProvider}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

class DraftEditor extends React.PureComponent<Destroyable, {text: string}> {
  @Inject preferencesManager : iCRUD<UserPreferences>
  container: ReactRef<'div'>

  updatePrefs: (text: string) => Promise<void>

  constructor () {
    super()
    autoBind(this)
    this.state = {
      text: ''
    }
    this.container = React.createRef()

    this.updatePrefs = debounce(async (text) => {
      await this.preferencesManager.update({
        name: DRAFT_TEXT_PREF_NAME,
        value: text
      })
    }, 200)
  }

  async componentDidMount () {
    this.container.current?.focus()
    const pref = await this.preferencesManager.read(DRAFT_TEXT_PREF_NAME)
    if (pref && pref.value) this.setState({ text: pref.value })
  }

  handleBlur (event: SyntheticEvent<HTMLDivElement>) : void {
    const { currentTarget } = event
    global.requestAnimationFrame(() => {
      if (!currentTarget.contains(document.activeElement)) {
        this.props.onDestroyRequested()
      }
    })
  }

  handleTextChange (event: SyntheticEvent<HTMLTextAreaElement>) {
    const text = event.currentTarget.value
    this.setState({ text })
    this.updatePrefs(text)
  }

  render () : React.Node {
    const { text } = this.state
    return (
      <div className='draft-edit' tabIndex={1} onBlur={this.handleBlur} ref={this.container}>
        <textarea value={text} placeholder={__('THIS_IS_DRAFT')} onChange={this.handleTextChange} />
      </div>
    )
  }
}

class ScaleControl extends React.PureComponent<Destroyable, {position: number}> {
  @Inject preferencesManager : iCRUD<UserPreferences>
  updatePrefs: (factor: ?number) => Promise<void>
  container: ReactRef<'div'>

  constructor () {
    super()
    autoBind(this)
    this.state = {
      position: 50
    }
    this.container = React.createRef()

    this.updatePrefs = debounce(async (value) => {
      await this.preferencesManager.update({
        name: FONT_SCALE_PREF_NAME,
        value
      })
    }, 200)
  }

  async componentDidMount () {
    this.container.current?.focus()
    const pref = await this.preferencesManager.read(FONT_SCALE_PREF_NAME)
    if (pref && pref.value) {
      this.setState({
        position: pref.value
      })
    }
  }

  static resize (fraction: number) : void {
    // max 26
    const fontSize = fraction / 100 * 16 + 8
    const root = document.documentElement
    if (root) root.style.fontSize = fontSize + 'px'
  }

  handleChange ({ value }) {
    this.setState({ position: value }, debounce(this.applyState, 250))
  }

  applyState () {
    const { position } = this.state
    this.updatePrefs(position === 50 ? null : position)
    ScaleControl.resize(position)
  }

  async onReset () {
    this.setState({ position: 50 }, this.applyState)
  }

  handleBlur (event: SyntheticEvent<HTMLDivElement>) : void {
    const { currentTarget } = event
    global.requestAnimationFrame(() => {
      if (!currentTarget.contains(document.activeElement)) {
        this.props.onDestroyRequested()
      }
    })
  }

  render () : React.Node {
    const { position } = this.state
    return (
      <div className='scale-control' tabIndex={1} onBlur={this.handleBlur} ref={this.container}>
        <Row>
          <Col size={12} className='v-a'>
            {__('TEXT_SCALE')}
          </Col>
        </Row>
        <Row>
          <Col size={9} className='v-a'>
            <Slider containerClassName='full-width' step={10} value={position} onChange={this.handleChange} />
          </Col>
          <Col size={3}>
            <Button label={__('RESET')} action={this.onReset} />
          </Col>
        </Row>
      </div>
    )
  }
}

class ExtraActions extends React.Component<Props, State> {
  @Inject globalEventBus : iGlobalEventBus
  @Inject preferencesManager : iCRUD<UserPreferences>

  constructor () {
    super()
    autoBind(this)
    this.state = {
      viewsVisibility: {
        locales: false,
        draft: false,
        scale: false
      },
      notificationsVisible: true
    }
  }

  async componentDidMount () {
    this.globalEventBus.registerEvent('notifications-mode-changed')
    let pref = await this.preferencesManager.read(NOTIFICATIONS_MODE_PREF_NAME)
    if (pref && !isNil(pref.value)) this.setState({ notificationsVisible: pref.value })

    pref = await this.preferencesManager.read(FONT_SCALE_PREF_NAME)
    if (pref && pref.value) ScaleControl.resize(pref.value)
  }

  toggleView (key: $Keys<$PropertyType<State, 'viewsVisibility'>>) : void {
    const { viewsVisibility } = this.state
    const result = {}
    for (const viewName in viewsVisibility) {
      if (viewName === key) result[key] = !viewsVisibility[key]
      else result[viewName] = false
    }
    this.setState({ viewsVisibility: result })
  }

  handleNotificationsModeToggle () {
    const { notificationsVisible } = this.state
    this.setState({ notificationsVisible: !notificationsVisible }, async () => {
      const visible = this.state.notificationsVisible
      this.preferencesManager.update({
        name: NOTIFICATIONS_MODE_PREF_NAME,
        value: visible
      })
      this.globalEventBus.emit('notifications-mode-changed', { visible })
    })
  }

  render () : React.Node {
    const { contextView, onCollapseAll } = this.props
    const { notificationsVisible, viewsVisibility } = this.state
    return (
      <>
        <If condition={viewsVisibility.locales}><LocaleSelect onDestroyRequested={() => this.toggleView('locales')} /></If>
        <If condition={viewsVisibility.draft}><DraftEditor onDestroyRequested={() => this.toggleView('draft')} /></If>
        <If condition={viewsVisibility.scale}><ScaleControl onDestroyRequested={() => this.toggleView('scale')} /></If>

        <div className='context-block'>{contextView}</div>
        <div className='extra-context-button' data-title={__('LANGUAGE')} onClick={() => this.toggleView('locales')}>
          <span className='locale-indicator'>{getCurrentLocale()}</span>
        </div>
        <div className='extra-context-button' data-title={__('OPEN_DRAFT_EDITOR')} onClick={() => this.toggleView('draft')}>
          <Icon className='icon marker' icon={viewsVisibility.draft ? 'box-tissue' : 'edit'} />
        </div>
        <div className='extra-context-button' data-title={__('TEXT_SCALE')} onClick={() => this.toggleView('scale')}>
          <Icon className='icon marker' icon={viewsVisibility.scale ? 'check' : 'text-height'} />
        </div>
        <div className='extra-context-button' data-title={__('VISUAL_MUTE_NOTIFICATIONS')} onClick={this.handleNotificationsModeToggle}>
          <Icon className={cn('icon', 'marker', { red: !notificationsVisible })} icon={notificationsVisible ? 'bell' : 'bell-slash'} />
        </div>
        <div data-title={__('COLLAPSE_ALL_WINDOWS')} className='modals-collapse' onClick={onCollapseAll} />
      </>
    )
  }
}

export default ExtraActions
