/**
 * @file ThrottledComponent.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Wednesday, 1st June 2022 1:17:13 pm
 * @copyright 2015 - 2022 SKAT LLC, Delive LLC
 * @flow strict
 */
import * as React from 'react'
import throttle from 'lodash/throttle'

/**
 * This component has own synchronous state,
 * reflected on react state each 100ms
 */
class ThrottledComponent<P, S> extends React.PureComponent<P, S> {
  #state: S

  get stateValues () : S {
    if (!this.#state) return this.state
    return this.#state
  }

  // $FlowFixMe[incompatible-shape]
  setState (data: ?$Shape<S>, callback?: () => mixed) : void {
    this.#state = { ...this.stateValues, ...data }
    this.flush()
  }

  // $FlowFixMe[incompatible-call]
  flush : () => void = throttle(() => super.setState(this.#state), 100)
}

export default ThrottledComponent
