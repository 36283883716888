/**
 * @file memoryStore.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Monday, 30th August 2021 10:11:50 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow
 */
import type { iStore, StoreItem } from '../../types'

class MemoryStore extends Map<string, ?string> implements iStore {
  setItem (key: string, value: ?string) {
    this.set(key, value)
  }

  setAll (data: StoreItem[]) : void {
    data.forEach(item => this.set(item.name, item.value))
  }

  getAll () : StoreItem[] {
    const data = []
    for (const [name, value] of this.entries()) data.push({ name, value })
    return data
  }

  getItem (key: string) : ?string {
    return this.get(key)
  }

  removeItem (key: string) {
    this.delete(key)
  }

  clear () {
    this.clear()
  }
}

export default MemoryStore
