/**
 * @file Cluster.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 7th July 2022 10:16:40 am
 * @copyright 2015 - 2022 SKAT LLC, Delive LLC
 * @flow strict
 */

/* global CanvasRenderingContext2D */
import type { MapChildrenProps, LocationObject, LocationObjectProjection } from '../types'

import { CAST } from 'web-panel-essentials/misc'
import Canvas from './Canvas'
import throttle from 'lodash/throttle'
import { pointIsOutOfBounds } from './utils'

export type Props<T> = {|
  points: LocationObject<T>[],
  markerRenderer: (context: CanvasRenderingContext2D, point: LocationObjectProjection<T>, zoom: number) => void,
  ...MapChildrenProps
|}

export type State<T> = {
  visiblePoints: LocationObjectProjection<T>[]
}

class Cluster<T> extends Canvas<Props<T>, State<T>> {
  constructor (props: Props<T>) {
    super(props)
    this.state = {
      visiblePoints: []
    }
  }

  componentDidMount () {
    this.UNSAFE_componentWillReceiveProps(this.props)
  }

  UNSAFE_componentWillReceiveProps (nextProps: Props<T>) { //eslint-disable-line
    let points: LocationObject<T>[]
    const [firstPoint] = nextProps.points
    let minLat = firstPoint ? CAST.Number(firstPoint.latitude) : 0
    let minLon = firstPoint ? CAST.Number(firstPoint.longitude) : 0

    if (!nextProps.animating) {
      points = nextProps.points.filter(({ latitude, longitude }) => {
        const lat = CAST.Number(latitude) // This is a nasty hack, but it ensures that driver has coordinates as numbers
        const lon = CAST.Number(longitude)
        if (lat < minLat) minLat = lat
        if (lon < minLon) minLon = lon
        return (!pointIsOutOfBounds([lat, lon], nextProps.bounds))
      })

      let visiblePoints = []
      if (points.length > 50 || nextProps.zoom < 11) {
        const pointDict = {}

        let [minX, minY] = nextProps.latLngToPixel([minLat, minLon])

        minX = Math.round(minX)
        minY = Math.round(minY)

        visiblePoints = points.reduce((list, point) => {
          const [x, y] = nextProps.latLngToPixel([point.latitude, point.longitude])

          const cx = (Math.round(x) - minX) >> 3
          const cy = (Math.round(y) - minY) >> 3

          if (!pointDict[cx]) pointDict[cx] = {}
          if (pointDict[cx][cy]) {
            pointDict[cx][cy].count++
            pointDict[cx][cy].x += x
            pointDict[cx][cy].y += y
            pointDict[cx][cy].latitude += point.latitude
            pointDict[cx][cy].longitude += point.longitude
          } else {
            pointDict[cx][cy] = {
              ...point,
              latitude: point.latitude,
              longitude: point.longitude,
              count: 1,
              x,
              y
            }
            list.push(pointDict[cx][cy])
          }

          return list
        }, [])

        visiblePoints.forEach((point) => {
          point.x = Math.round(point.x / point.count)
          point.y = Math.round(point.y / point.count)
          point.latitude /= point.count
          point.longitude /= point.count
        })
      } else {
        visiblePoints = points.map((point) => {
          const [x, y] = nextProps.latLngToPixel([point.latitude, point.longitude])
          return { ...point, x: Math.round(x), y: Math.round(y), count: 1 }
        })
      }

      this.setState({ visiblePoints })
    } else {
      this.setState((state, props) => {
        return {
          visiblePoints: state.visiblePoints.map((point) => {
            const [x, y] = props.latLngToPixel([point.latitude, point.longitude])
            return { ...point, x: Math.round(x), y: Math.round(y), count: 1 }
          })
        }
      })
    }
  }

  componentDidUpdate (lastProps: Props<T>, lastState: State<T>) {
    const visiblePoints = this.state.visiblePoints
    if (visiblePoints !== lastState.visiblePoints) {
      let { zoom, width, height } = this.props
      zoom = Math.ceil(zoom)
      width = Math.ceil(width)
      height = Math.ceil(height)
      this.renderPointsThrottled(visiblePoints, width, height, zoom)
    }
  }

  renderPointsThrottled : (visiblePoints: LocationObjectProjection<T>[], width: number, height: number, zoom: number) => void = throttle(this.renderPoints, 16)
  renderPoints (visiblePoints: LocationObjectProjection<T>[], width: number, height: number, zoom: number) {
    global.requestAnimationFrame(() => {
      const context = this.context2D
      if (!context) return

      const heavyLoad = visiblePoints.length > 50 || zoom < 11
      context.clearRect(0, 0, width, height)
      if (heavyLoad) this.rendePointsAsMicroPoints(context, visiblePoints)
      else this.renderPointsMarkers(context, visiblePoints, zoom)
    })
  }

  rendePointsAsMicroPoints (context: CanvasRenderingContext2D, points: LocationObjectProjection<T>[]) : void {
    points.forEach(point => context.fillRect(point.x - 2, point.y - 2, 4, 4))
  }

  renderPointsMarkers (context: CanvasRenderingContext2D, points: LocationObjectProjection<T>[], zoom: number) : void {
    const fontSize = zoom - 2
    context.textBaseline = 'middle'
    context.textAlign = 'center'
    context.font = `bold ${fontSize}px sans-serif`
    points.forEach(point => this.props.markerRenderer(context, point, zoom))
  }
}

export default Cluster
