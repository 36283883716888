/**
 * @file Grid.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Wednesday, 10th July 2019 5:26:07 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow
 */
/* global $PropertyType, SyntheticKeyboardEvent, HTMLDivElement  */

import type { ID, WaterlineQuery, WaterlineWhere, ColumnDefinition, iDataProvider, iDataManager } from 'web-panel-essentials/types'
import type { iEventEmitter, iLogger, iNotificationManager, iGlobalEventBus, EmitterEvents } from './../../types'

import './GridManager'
import {
  __,
  NOTIFICATION,
  getTextWidth,
  delay,
  genTestName,
  isTestingMode,
  isMobileMode,
  callLater,
  getRemUnitSize,
  getCurrentLocale
} from 'web-panel/globals'
import { KEY, FORMAT, CAST } from 'web-panel-essentials/misc'
import { RunOnNextTick } from 'web-panel-essentials/decorators'

import cn from 'classnames'
import * as React from 'react'
import { ContextMenuTrigger } from 'react-contextmenu'

import { Grid as ReactVirtualized, AutoSizer, ScrollSync } from 'react-virtualized'
import { prepareCSVData, exportToCSV } from './CSVExporting'
import { rowAllowed } from './filtration'
import autoBind from 'react-autobind'

import { Inject, Injectable } from '../../serviceLocator'

import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import range from 'lodash/range'
import isFunction from 'lodash/isFunction'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import EventBus from '../../utils/EventBus'
import DefaultCell from './defaultCell'

import { Subject, interval } from 'rxjs'

import { spawn, Thread, Worker } from 'threads'
import simulatedWorker from './shared'
import HeaderFilterCell from './headerFilterCell'
import omit from 'lodash/omit'
import { DuplicateKeyError } from '../../errors'
import { TypeError, ConfigurationError, NotFoundError } from 'web-panel-essentials/errors'
import { tap, throttle as rxthrottle } from 'rxjs/operators'
// $FlowFixMe
import workerURL from 'threads-plugin/dist/loader?name=grid!./grid.async-worker.js' // eslint-disable-line import/no-webpack-loader-syntax
import HeaderCell from './headerCell'

const Toolbox = React.lazy(() => import('./Toolbox'))

export const SORT_MODE = {
  ASC: 'ASC',
  DESC: 'DESC'
}

const OPERATION = {
  INSERT: '+',
  UPDATE: '*',
  REPLACE: '/',
  REMOVE: '-'
}

const EVENT = {
  CLICK: 'event-click',
  DOUBLE_CLICK: 'event-double-click',
  SELECTION_CHANGED: 'event-selection-changed',
  OPEN_SETTINGS: 'event-open-settings'
}

const operation = Symbol('@operation')

/**
 * Grid may have multiple instances, and globalEventBus is unable
 * to register the same events several times
 * Can be used by separated webHoosk like in example below:
 * @example
 *   ...
 *   @Inject globalEventBus : iEventEmitter
 *   ...
 *     this.globalEventBus.on('grid-double-click', ({name, record}) => {
 *        if (name === 'orders') this.showCard({...record, isNew: false})
 *     })
 *   ...
*/
@Injectable('gridEventMediator')
class GridEventMediator extends EventBus {
  @Inject globalEventBus : iEventEmitter & iGlobalEventBus

  constructor () {
    super()

    const bus = this.globalEventBus
    if (!bus.eventRegistered('grid-click')) bus.registerEvent('grid-click')
    if (!bus.eventRegistered('grid-double-click')) bus.registerEvent('grid-double-click')
    if (!bus.eventRegistered('grid-selection-changed')) bus.registerEvent('grid-selection-changed')
    if (!bus.eventRegistered('linking-request')) bus.registerEvent('linking-request')

    this.on(EVENT.CLICK, (...args) => this.globalEventBus.emit('grid-click', ...args))
    this.on(EVENT.DOUBLE_CLICK, (...args) => this.globalEventBus.emit('grid-double-click', ...args))
    this.on(EVENT.SELECTION_CHANGED, (...args) => this.globalEventBus.emit('grid-selection-changed', ...args))
  }

  dispose () {
    this.removeAllListeners()
  }
}

type Record = {
  id: any
}

export type GridOptions<T: Record> = {
  name: string,
  dataProvider: iDataProvider<T>,
  dataManager?: ?iDataManager<T>,
  conceal?: boolean,
  selectionMode?: boolean,
  filter?: ?WaterlineQuery,
  sorting?: Object,
  autofetch?: boolean,
  autorefresh?: number,
  visible?: Array<string>,
  contextMenuId?: string,
  onClick?: Function,
  onDoubleClick?: Function,
  onContextMenuClick?: Function,
  onSelectionChanged?: Function,
  onBeforeItemInserted?: Function,
  onFilteredItemsChange?: Function,
  className?: string,
  cellClass?: string,
  noHeader?: boolean,
  noSettings?: boolean,
  testName?: string,
  useWorker?: boolean,
  patchDelay?: number,
  children?: React.ChildrenArray<React.Node>,
  alwaysShowTools?: boolean,
  tooltip?: (recordId: $PropertyType<Record, 'id'>) => Promise<React.Node>,
  scrollToBottomOnFirstFetch?: boolean
}

export type State<T> = {
  columns: Array<ColumnDefinition<T>>,
  visibleColumns: Array<ColumnDefinition<T>>,
  selectedRecords: Array<ID>,
  filter: ?WaterlineQuery,
  localFilter: WaterlineQuery,
  filtered: Array<Object>,
  placeholder: {
    text: string,
    blink?: boolean
  } | null,
  cursor: number | null,
  sorting: Object,
  changed: boolean,
  _changeTrigger: boolean,
  scrollToColumn?: ?number,
  scrollToRow?: ?number,
  headerFilters: Object,
  toolsVisible: boolean,
  toolsVisibilityPinned: boolean,
  manualAdjustments: {[string]: number}
}

class Grid<T:Record> extends React.Component<GridOptions<T>, State<T>> {
  @Inject gridEventMediator : iEventEmitter & { dispose: Function }
  @Inject eventBus : iEventEmitter
  @Inject logger : iLogger
  @Inject notificationManager : iNotificationManager
  @Inject tooltipManager : any
  @Inject gridManager: any // debug
  list: Map<ID, T>
  index: {}

  worker: any
  name: string
  tooltipId: string
  componentUmounted: boolean
  inserts: typeof Subject
  updates: typeof Subject
  replaces: typeof Subject
  deletions: typeof Subject
  changes: any
  initiallyScrolledDown: boolean

  grid: React.ElementRef<typeof ReactVirtualized>
  gridHeader: React.ElementRef<typeof ReactVirtualized>
  renderInfo: any
  testName: string
  refreshInterval: any
  _scrollToRowThrottled: (any) => any
  _dataRefetchDebounced: (any) => any
  touchedCells: Set<ID>

  static get EVENT () : EmitterEvents {
    return EVENT
  }

  get EVENT () : EmitterEvents {
    return Grid.EVENT
  }

  constructor (props: GridOptions<T>) {
    super(props)
    autoBind(this)
    this.props = props
    this.logger.namespace = 'grid'
    this.name = this.props.name || 'Generic grid'
    this.testName = this.props.testName || this.name

    this.tooltipId = [this.name, 'grid', 'tooltip'].join(':')
    this.tooltipManager.register(this.tooltipId, this.props.tooltip)
    this.initiallyScrolledDown = false

    if (!props.dataProvider) throw new ConfigurationError(__('NO_DATA_PROVIDER_PROVIDED'))

    this.changes = new Subject()
    this.touchedCells = new Set()
    this.gridManager.proxy(this.props.name, props.dataProvider, props.filter)

    this.list = new Map()
    this.index = {}

    this.state = {
      cursor: null,
      placeholder: { text: __('NO_ITEMS_TO_DISPLAY'), blink: false },
      scrollToRow: null,
      selectedRecords: [],
      columns: [],
      visibleColumns: [],
      filter: props.filter,
      localFilter: {},
      sorting: props.sorting || {},
      filtered: [],
      changed: false,
      headerFilters: {},
      _changeTrigger: false,
      manualAdjustments: {},
      toolsVisible: false,
      toolsVisibilityPinned: false
    }

    this.bindContext()

    const refreshInterval = CAST.Number(props.autorefresh)

    if (refreshInterval > 0) {
      this.refreshInterval = async () => {
        this.refresh()
        await delay(refreshInterval)
        if (this.componentUmounted) return
        callLater(this.refreshInterval, 5000)
      }
      this.refreshInterval()
    }

    this.grid = React.createRef()
    this.gridHeader = React.createRef()
  }

  bindContext () {
    this._scrollToRowThrottled = throttle(this._scrollToRow.bind(this), 25)
    this._dataRefetchDebounced = debounce((filter) => {
      this.logger.info('Refreshing...')
      this.setState({ filter })
      this.fetch()
    }, 100)
  }

  /**
   * We will be inactive when hidden
   * We will re-fetch the data if a new filter has arrived.
   * @param {*} props
   */
  UNSAFE_componentWillReceiveProps (props: GridOptions<T>) { // eslint-disable-line
    if (!isEqual(props.filter, this.state.filter)) this._dataRefetchDebounced(props.filter)
    if (!isEqual(props.dataProvider, this.props.dataProvider)) this._dataRefetchDebounced(props.filter)
    if (!isEqual(props.dataManager, this.props.dataManager)) this._dataRefetchDebounced(props.filter)
  }

  async componentDidMount () {
    this.worker = this.props.useWorker ? await spawn(new Worker(workerURL)) : simulatedWorker()
    await this.worker.setLocale(getCurrentLocale())

    const patchDelay = () => interval(isNil(this.props.patchDelay) ? 1000 : this.props.patchDelay)
    const bufferPipe = this.props.patchDelay === 0 ? tap() : rxthrottle(patchDelay, { leading: true, trailing: true })

    this.changes.pipe(
      tap((change) => {
        const list = this.list
        const op = change[operation]
        change = omit(change, operation)
        switch (op) {
          case OPERATION.INSERT:
          case OPERATION.REPLACE:
            list.set(change.id, change)
            break
          case OPERATION.UPDATE:
            list.set(change.id, { ...list.get(change.id), ...change })
            break
          case OPERATION.REMOVE:
            list.delete(change.id)
            break
        }
      }),
      bufferPipe
    ).subscribe(async () => await this.applyNewList(this.list))

    const { dataManager, autofetch, onBeforeItemInserted } = this.props

    if (dataManager) {
      const pushChange = (payload, type) => {
        this.preValidate(payload)
        this.changes.next(this.mark(payload, type))
      }
      const exist = (record: Object) => this.list.has(record.id)

      dataManager.activate({
        insertRecord: (record) => {
          if (exist(record)) throw new DuplicateKeyError(__('RECORD_IS_ALREADY_EXIST'))
          if (!this.recordAllowed(record)) return
          if (onBeforeItemInserted) onBeforeItemInserted(record)
          pushChange(record, OPERATION.INSERT)
        },
        updateRecord: (record) => {
          // TODO: Here can be a $Shape of record
          if (!exist(record)) throw new NotFoundError(__('RECORD_NOT_FOUND'))
          pushChange(record, OPERATION.UPDATE)
        },
        upsertRecord: (record) => {
          // TODO: Here must be the whole record
          if (exist(record)) pushChange(record, OPERATION.UPDATE)
          else pushChange(record, OPERATION.INSERT)
        },
        replaceRecord: (record) => {
          if (exist(record)) pushChange(record, OPERATION.REPLACE)
          else throw new NotFoundError(__('RECORD_NOT_FOUND'))
        },
        recordExist: (id: ID) => exist({ id }),
        removeRecordById: (r) => pushChange({ id: r }, OPERATION.REMOVE),
        recordDisplayed: this.isRecordDisplayed.bind(this),

        getRecords: () => [...this.list.values()],
        getFilteredRecords: () => this.state.filtered,

        getSelection: this.getSelection.bind(this),
        getSelectedRecords: this.getSelectedRecords.bind(this),
        setSelection: this.setSelection.bind(this),
        scrollToBottom: this.scrollToBottom.bind(this),
        fetch: this.fetch.bind(this),
        cropTo: this.cropTo.bind(this)
      })
    }

    if (autofetch) this._dataRefetchDebounced(this.state.filter)

    // When gridManager's OK button pressed, rerendering grid columns
    this.gridManager.on(this.gridManager.EVENT.DATA_CHANGED, async () => {
      await this.setStateAsync({ manualAdjustments: {} })
      await this.fetch()
      this.gridHeader.current.recomputeGridSize()
      this.grid.current.recomputeGridSize()
    })
  }

  async componentWillUnmount () {
    if (this.props.useWorker) await Thread.terminate(this.worker)
    if (this.props.dataManager) this.props.dataManager.deactivate()
    this.gridManager.removeAllListeners()

    this.componentUmounted = true

    this.changes.unsubscribe()
    this.gridEventMediator.dispose()

    // $FlowFixMe
    if (this._dataRefetchDebounced) this._dataRefetchDebounced.cancel()
  }

  static throwIfFilterInvalid (filter: WaterlineQuery) {
    const allowedKeys = ['where', 'limit', 'skip', 'select']
    Object.keys(filter).forEach((key) => {
      if (!allowedKeys.includes(key)) throw new TypeError(__('INVALID_FILTRATION_QUERY'))
    })
  }

  async fetch () : Promise<Array<Object>> {
    this.logger.info('Fetching grid records')
    await this.reset()
    await this.showPlaceholder(__('LOADING'), true)
    let payload = []
    let list = new Map()

    let { filter } = this.state
    filter = { ...filter }

    try {
      if (filter) Grid.throwIfFilterInvalid(filter)
    } catch (e) {
      this.notificationManager.show({
        message: __('INVALID_FILTER_EXPRESSION'),
        type: NOTIFICATION.ERROR,
        details: __('SEE_LOGS_FOR_DETAILS'),
        persistent: true
      })
      throw e
    }

    try {
      const { visible } = this.props

      let { columns } = await this.gridManager.meta()
      if (visible) {
        columns = columns.map((column: ColumnDefinition<any>) => {
          // $FlowFixMe[prop-missing]
          if (column.visibilityOverwrited) return column
          else column.visible = visible.includes(column.name)

          return column
        })
      }

      if (filter && filter.select) {
        const extraSelect = columns.filter(column => column.visibilityOverwrited).map(column => column.name)
        if (filter.select) filter.select = [...filter.select, ...extraSelect]
        // $FlowFixMe[prop-missing]
        // $FlowFixMe[incompatible-use]
        columns = columns.filter((column: ColumnDefinition<any>) => (filter.select: any).includes(column.name))
      }

      const visibleColumns = columns.filter(({ visible }) => visible !== false)
      await this.setStateAsync({ columns, visibleColumns })
    } catch (e) {
      this.logger.error(e)
      throw new ConfigurationError(__('UNABLE_TO_INITIALIZE_COLUMNS'))
    }

    try {
      payload = await this.props.dataProvider.get(filter)
      list = new Map(payload.map(record => [record.id, record]))
    } catch (e) {
      // Do not handle server response if stream has been terminated manualy
      this.list = list
      if (e.body && e.body.code === 'ESTMTERM') return []
      this.showPlaceholder(__('LOAD_FAILED'), false)
      return payload
    }

    this.logger.debug('Fetched', list.size, 'records')

    await this.applyNewList(list)
    return payload
  }

  async showPlaceholder (text: string, blink: boolean = false) : Promise<void> {
    await this.setState({ placeholder: { text, blink } })
  }

  hidePlaceholder () : void {
    this.setState({ placeholder: null })
  }

  getSelection () : Array<ID> {
    return this.state.selectedRecords
  }

  setStateAsync (state: Object) : Promise<void> {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  @RunOnNextTick()
  scrollToBottom () {
    this.grid.current && this.grid.current.scrollToCell({ rowIndex: this.state.filtered.length })
  }

  async setSelection (selectedRecords: Array<ID>, silent?: boolean) : Promise<void> {
    if (isEqual(selectedRecords, this.state.selectedRecords)) return

    await this.setStateAsync({ selectedRecords })
    if (silent) return

    const records = this.getSelectedRecords()
    if (this.props.dataManager && this.props.dataManager.selectionChanged) this.props.dataManager.selectionChanged(records)
    if (this.props.onSelectionChanged) (this.props:any).onSelectionChanged(records)
  }

  async cropTo (limit: number) {
    const limited = [...this.list.entries()].slice(-limit)
    await this.applyNewList(new Map(limited))
  }

  getFilteredRecords () : Array<any> {
    return this.state.filtered
  }

  preValidate (record: Object) : void {
    if (!record.id && record.id !== 0) throw new TypeError(__('RECORDS_WITHOUT_ID_ARE_UNSUPPORTED'))
  }

  mark (record: Object, operationType: $Values<typeof OPERATION>) : Object {
    return {
      ...record,
      // $FlowFixMe
      [operation]: operationType
    }
  }

  /**
   * Посколько этот метод обновляет весь список, запускается
   * достаточно часто и блокирует основной поток, то
   * имеет смысл вынести основные вычесления в отдельный worker
   */
  async applyNewList (list: Map<ID, Object>) : Promise<Array<Object>> {
    this.list = list

    let [filtered, index] = await this.applyFilter(this.list)
    this.index = index;

    [filtered, index] = await this.applySorting(filtered, index)
    this.index = index

    this._refreshVieport(filtered)

    const [selected] = this.state.selectedRecords

    let cursor = null
    // $FlowFixMe[invalid-computed-prop]
    if (selected) cursor = index[selected]
    else if (this.state.cursor !== null) cursor = Math.min(CAST.Number(this.state.cursor), filtered.length)

    this.setStateAsync({ filtered, cursor })

    if (this.props.scrollToBottomOnFirstFetch && !this.initiallyScrolledDown) {
      this._scrollToRow(filtered.length)
      this.initiallyScrolledDown = true
    }

    return filtered
  }

  /**
   * Я разделил фильтр на локальный и тот, что приходит извне.
   * Фильт извне, настолько силён, что не позволит даже добавить новую
   * запись в грид, если она не удовлетворяет его условиям.
   * Локальный же фильтр такой силы не имеет и накладывается
   * только на уже существующие данные, тем не менее, он всё еще учитывается
   * при добалении данных.
   * В этом методе оба типа фильтрации сливаются в один.
   * И еще здесь всегда пересчитывается индекс
   */
  async applyFilter (list: Map<ID, any>) : Promise<[T[], {}]> {
    let { filter, localFilter = {} } = this.state
    filter = filter || {}

    const [filtered, index] = await this.worker.filterData(list, filter.where, localFilter.where)

    let placeholderMessage
    if (filtered.length === 0) {
      placeholderMessage = __('NO_ITEMS_TO_DISPLAY')
      if (list.size) placeholderMessage = `${placeholderMessage} (${__('FILTER_APPLIED')})`
    }

    this.setState({ placeholder: placeholderMessage ? { text: placeholderMessage, blink: false } : null })

    if (this.props.onFilteredItemsChange) this.props.onFilteredItemsChange(filtered)
    return [filtered, index]
  }

  async applySorting (filtered: T[], index: {}) : Promise<[T[], {}]> {
    let sorted = filtered
    if (isEmpty(this.state.sorting)) return [sorted, index]

    for (const columnName in this.state.sorting) {
      // $FlowFixMe
      [sorted, index] = await this.sortColumn(sorted, this.state.columns.find(({ name }) => name === columnName), false, index)
    }

    return [sorted, index]
  }

  // https://en.wikipedia.org/wiki/Merge_sort
  async sortColumn (filtered: Array<any>, column?: ColumnDefinition<T>, toggle: boolean = false, index: {}) : Promise<[T[], {}]> {
    if (!column) return [filtered, index]

    let mode : string = this.state.sorting[column.name]

    const getNextMode = (currentMode) : string => {
      if (!currentMode) return 'ASC'
      if (currentMode === 'ASC') return 'DESC'
      if (currentMode === 'DESC') return ''
      return ''
    }

    if (toggle) mode = getNextMode(mode)

    if (!mode) {
      const currentSorting = omit(this.state.sorting, column.name)
      this.setState({ sorting: currentSorting })
      return [filtered, index]
    } else {
      let columnInfo = toggle ? {} : this.state.sorting
      columnInfo = { ...columnInfo, [column.name]: mode }

      const target = column.sortMap || column.name
      const [sorted, index] = await this.worker.sortColumn(filtered, target, mode)
      this.setState({ sorting: columnInfo })
      return [sorted, index]
    }
  }

  isRecordDisplayed (recordId: ID) : boolean {
    // $FlowFixMe[invalid-computed-prop]
    const index = this.index[recordId]
    if (isNaN(index)) return false
    if (!this.renderInfo) return true
    const { rowOverscanStartIndex, rowOverscanStopIndex } = this.renderInfo
    return rowOverscanStartIndex <= index && index <= rowOverscanStopIndex
  }

  reset (list?: Map<ID, Object> = new Map()) : Promise<void> {
    return new Promise(resolve => {
      this.list = list
      this.setState({ filtered: [] }, resolve)
    })
  }

  /**
   * If the user filters in the header, then
   * store it in the state as localFilter,
   * because it does not intersect with what came from outside
   * @param {*} event
   * @param {*} column
   */
  async _onLocalFilterChanged () : Promise<void> {
    const { headerFilters } = this.state
    let where : WaterlineWhere

    for (const columnName in headerFilters) {
      let filterValue = headerFilters[columnName]

      if (filterValue) {
        let operator : string = 'ilike'
        if (filterValue.startsWith('=')) {
          operator = 'is'
          filterValue = filterValue.replace('=', '').trim()
        }
        where = {
          ...where,
          [columnName]: { [operator]: filterValue }
        }
      }
    }

    await this.setState({ localFilter: { where } })
    const [filtered, index] = await this.applyFilter(this.list)
    this.index = index
    this.setState({ filtered })
  }

  /**
   * This method can be used to exclude "dead" records at the insertion stage,
   * These records will never be shown to the user due to the global filter.
   * @see. DataManager
   */
  recordAllowed (record: Object) : boolean {
    const { filter } = this.state
    if (!filter || !filter.where) return true
    return rowAllowed(record, filter.where, { locale: getCurrentLocale() })
  }

  async setFilter (filter: WaterlineQuery) : Promise<this> {
    this.setState({ filter })
    const [filtered, index] = await this.applyFilter(this.list)
    this.index = index
    this.setState({ filtered })
    return this
  }

  refresh () : void {
    // Do not refresh if element is concealed
    if (this.props.conceal) return
    if (!this.state.filtered || this.state.filtered.length === 0) return
    this.setState({ ...this.state, _changeTrigger: !this.state._changeTrigger })
  }

  _getRowStyle (row: Object) : {} {
    const color = row.rowFg
    const backgroundColor = row.rowBg
    return { color, backgroundColor }
  }

  handleCellDoubleClick (opts: any) : void {
    this.eventBus.emit(EVENT.DOUBLE_CLICK, { name: this.name, ...opts })
    this.gridEventMediator.emit(EVENT.DOUBLE_CLICK, { name: this.name, ...opts })
    if (this.props.onDoubleClick) this.props.onDoubleClick(opts)
  }

  handleCellClick (opts: Object) : void {
    const { event, columnIndex, rowIndex, column, record } = opts
    this._selectCell({ event, columnIndex, rowIndex })
    this.eventBus.emit(EVENT.CLICK, { name: this.name, columnIndex, rowIndex, column, record })
    this.gridEventMediator.emit(EVENT.CLICK, { name: this.name, columnIndex, rowIndex, column, record })
    if (this.props.onClick) this.props.onClick({ event, column, record })

    if (isMobileMode()) {
      this.simulateDoubleClick(opts)
    }
  }

  // @see https://stackoverflow.com/questions/25777826/onclick-works-but-ondoubleclick-is-ignored-on-react-component
  simulateDoubleClick (opts: Object) : void {
    const id = opts.record.id
    if (this.touchedCells.has(id)) {
      this.handleCellDoubleClick(opts)
      this.touchedCells.delete(id)
      return
    }
    this.touchedCells.add(id)
    setTimeout(() => this.touchedCells.delete(id), 250)
  }

  _selectCell ({ event, columnIndex, rowIndex }: Object) : void {
    // event.persist()
    let { cursor } = this.state
    const { selectionMode } = this.props

    const record = this.state.filtered[rowIndex]
    if (!record) return

    this.setState({ cursor: rowIndex })

    if (event.shiftKey) {
      let selection = range(cursor, rowIndex).concat(rowIndex)
      selection = selection.map((index) => this.state.filtered[index].id)
      this.setSelection(selection)
    } else if (event.ctrlKey || selectionMode) {
      let { selectedRecords } = this.state
      if (selectedRecords.includes(record.id)) {
        selectedRecords = selectedRecords.filter((id) => id !== record.id)
        if (cursor === rowIndex) cursor = null
        this.setState({ cursor })
      } else selectedRecords = [...selectedRecords, record.id]
      this.setSelection(selectedRecords)
    } else {
      this.setSelection([record.id])
    }
  }

  handleClickSelect ({ event, columnIndex, rowIndex } : Object) : void {
    const { selectedRecords, filtered } = this.state
    if (!selectedRecords.length || selectedRecords.length === 1) {
      const record = filtered[rowIndex]
      this.setState({ cursor: rowIndex })
      this.setSelection([record.id])
    }
  }

  _handleExtraAction (actionName: string, payoad: Object) : void {
    if (this.props.dataManager && this.props.dataManager.extraActionEmited) {
      this.props.dataManager.extraActionEmited(actionName, payoad)
    }
  }

  @RunOnNextTick()
  _scrollToRow (rowIndex: number) : void {
    this.grid.current?.scrollToCell({ rowIndex })
  }

  async keyboardNavigate (event: SyntheticKeyboardEvent<HTMLDivElement>) {
    const { filtered, selectedRecords } = this.state
    const { selectionMode } = this.props
    if (selectionMode) return

    const cursor = CAST.Number(this.state.cursor)

    let pageSize = 10
    if (this.renderInfo) pageSize = this.renderInfo.rowStopIndex - this.renderInfo.rowStartIndex

    const move = (to) => {
      event.preventDefault()
      event.stopPropagation()

      global.requestAnimationFrame(() => {
        this.setState({ cursor: to })
        this._scrollToRowThrottled(to)

        if (event.shiftKey) this.setSelection([filtered[cursor].id, filtered[to].id, ...selectedRecords])
        else this.setSelection([filtered[to].id])
      })
    }

    switch (event.keyCode) {
      case KEY.PAGE_UP: {
        const prevRow = Math.max(cursor - pageSize, 0)
        move(prevRow)
        break
      }
      case KEY.HOME: {
        const prevRow = 0
        move(prevRow)
        break
      }
      case KEY.UP: {
        const prevRow = Math.max(cursor - 1, 0)
        move(prevRow)
        break
      }
      case KEY.PAGE_DOWN: {
        const nextRow = Math.min(cursor + pageSize, filtered.length - 1)
        move(nextRow)
        break
      }
      case KEY.END: {
        const nextRow = filtered.length - 1
        move(nextRow)
        break
      }
      case KEY.DOWN: {
        const nextRow = Math.min(cursor + 1, filtered.length - 1)
        move(nextRow)
        break
      }
      case KEY.ENTER: {
        const limit = 5 // To prevent too many opened window lags
        this
          .getSelectedRecords()
          .slice(0, limit)
          .forEach((record) => this.handleCellDoubleClick({ event, record }))
        break
      }
    }
  }

  getSelectedRecords () : Array<T> {
    return [...this.list.values()].filter(({ id }) => this.state.selectedRecords.includes(id))
  }

  handleSectionRendered (renderInfo: Object) : void {
    this.renderInfo = renderInfo
    this._refreshVieport(this.state.filtered)
  }

  _refreshVieport (filtered: Array<any>) {
    if (this.props.dataManager && this.props.dataManager.viewportChanged) {
      if (!this.renderInfo) return // ? Не уверен
      const { rowOverscanStartIndex, rowOverscanStopIndex } = this.renderInfo
      this.props.dataManager.viewportChanged(filtered.slice(rowOverscanStartIndex, rowOverscanStopIndex + 1), filtered) // Это всё весьма жирненько
    }
  }

  handleOpenSettings () : void {
    this.gridManager.show()
  }

  handleInvokeExport () : void {
    const { visibleColumns = [], filtered } = this.state
    this.notificationManager.show({
      id: Symbol.for('TSV-exported'),
      message: __('DATA_EXPORTED_AS_TSV'),
      details: __('SPECIFY_TAB_AS_DELIMETER_TO_OPEN_DOCUMENT_IN_EXTERNAL_EDITOR'),
      type: NOTIFICATION.WARNING,
      persistent: true
    })
    const { titles, rows } = prepareCSVData(visibleColumns, filtered)
    exportToCSV(titles, rows)
  }

  /**
   * Auto-selection of column width, based on the width of its text
   * @param {*} columnIndex
   */
  adjustColumnWidth (columnIndex: number) {
    const ROW_MEASURE_LIMIT = 100
    const VIRTUAL_PADDING = 24
    const DEFAULT_MIN_WIDTH = 50
    const FONT_DEFINITION = 'Roboto 12px'
    const { filtered, columns } = this.state

    const width = filtered.filter((i, index) => (index < ROW_MEASURE_LIMIT)).reduce((acc, row, rowIndex) => {
      const columnName = columns[columnIndex].name
      return Math.max(acc, getTextWidth(row[columnName], FONT_DEFINITION) + VIRTUAL_PADDING)
    }, DEFAULT_MIN_WIDTH)

    columns[columnIndex].width = Math.min(width, 400)
    this.setState({ columns: [...columns] })
    this.gridHeader.current.recomputeGridSize({ columnIndex })
    this.grid.current.recomputeGridSize({ columnIndex })
  }

  resizeColumn (columnName: string, delta: number) : void {
    const MIN_WIDTH = 30
    const MAX_WIDTH = 500
    const { columns, visibleColumns, manualAdjustments } = this.state

    const columnIndex = columns.findIndex(column => column.name === columnName)
    let { width = 100, name } = columns[columnIndex]
    width += delta
    width = Math.max(MIN_WIDTH, width)
    width = Math.min(MAX_WIDTH, width)
    columns[columnIndex].width = width

    this.setState({ columns: [...columns], manualAdjustments: { ...manualAdjustments, [name]: parseInt(width, 20) } })

    const visibleColumnIndex = visibleColumns.findIndex(column => column.name === columnName)
    this.gridHeader.current.recomputeGridSize({ columnIndex: visibleColumnIndex })
    this.grid.current.recomputeGridSize({ columnIndex: visibleColumnIndex })
  }

  async handleHeaderClick (column: ColumnDefinition<T>) : Promise<void> {
    const [filtered, index] = await this.sortColumn(this.state.filtered, column, true, this.index)
    this.index = index
    this.setState({ filtered, cursor: null })
  }

  handleHeaderFilterChange (value: string, columnName: string) : void {
    const filters = this.state.headerFilters
    this.setState({
      headerFilters: {
        ...filters,
        [columnName]: CAST.String(value)
      }
    }, this._onLocalFilterChanged)
  }

  handleColumnAdjustRequest (columnIndex: number) {
    this.adjustColumnWidth(columnIndex)
  }

  handleColumnResize (name: string, width: number) {
    this.resizeColumn(name, width)
  }

  _headerCellRenderer ({ columnIndex, key, style, rowIndex }: Object) : React.Node {
    const column = this.state.visibleColumns[columnIndex]
    const filters = this.state.headerFilters
    const filterValue = CAST.String(filters[column.name])

    if (rowIndex === 1) {
      return (
        <HeaderFilterCell
          key={key}
          value={filterValue}
          columnName={column.name}
          tabIndex={this.props.conceal ? -1 : 0}
          style={style}
          onChange={this.handleHeaderFilterChange}
        />
      )
    }

    const sortingMode = this.state.sorting[column.name]
    return (
      <HeaderCell
        column={column}
        style={style}
        onClick={this.handleHeaderClick}
        onResize={this.handleColumnResize}
        onAdjustRequest={this.handleColumnAdjustRequest}
        columnIndex={columnIndex}
        sortingMode={sortingMode}
        active={Boolean(filterValue)}
      />
    )
  }

  _cellRenderer ({ columnIndex, key, parent, rowIndex, style, isScrolling, selectedRecords, isVisible }: Object) : React.Node {
    const { filtered, localFilter, visibleColumns } = this.state

    // На больших списках мы не можем себе позволить рендерить что-то лишнее
    // это условие сделает список более производительным, но сделает
    // прокрутку менее удобной
    if (filtered.length > 30 && !isVisible) return null

    const record = filtered[rowIndex]
    if (!record) return null

    const parentName = this.testName
    const columns = visibleColumns
    const column : ColumnDefinition<any> = columns[columnIndex]

    let active = false
    if (localFilter && localFilter.where && localFilter.where[column.name]) active = true

    const className = cn('evenRow', 'cell', {
      selected: this.state.selectedRecords.includes(record.id),
      cursor: rowIndex === this.state.cursor,
      changed: record.changed,
      active
    }, this.props.cellClass)

    style = { ...style, ...this._getRowStyle(record) }
    let value

    if (column.format) value = isFunction(column.format) ? (column:Object).format(record[column.name]) : FORMAT.String(record[column.name])
    else value = FORMAT.String(record[column.name])

    if ((column:any).cellType && isFunction((column:any).cellType)) {
      return (column:any).cellType({
        grid: this,
        onClick: this.handleCellClick,
        onDoubleClick: this.handleCellDoubleClick,
        onContextMenu: this.handleClickSelect,
        onExtraAction: this._handleExtraAction,
        tooltipId: this.tooltipId,
        list: this.state.filtered,
        columnIndex,
        record,
        column,
        key,
        parent,
        parentName,
        rowIndex,
        className,
        style,
        value
      })
    }

    return (
      <DefaultCell
        key={key}
        column={column}
        record={record}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
        tooltipId={this.tooltipId}
        className={className}
        style={style}
        parentName={parentName}
        onContextMenu={this.handleClickSelect}
        onDoubleClick={this.handleCellDoubleClick}
        onClick={this.handleCellClick}
        value={value}
      />
    )
  }

  _getColumnWidth ({ index }: Object) : number {
    const column: ColumnDefinition<any> = this.state.visibleColumns[index]
    if (column && column.visible === false) return 0
    return column.width || 100
  }

  renderHeaderCell ({ columnIndex, rowIndex, key, style } : *) : React.Node {
    return this._headerCellRenderer({ columnIndex, rowIndex, key, style })
  }

  renderCell ({ columnIndex, key, parent, rowIndex, style, isScrolling, isVisible }: *) : React.Node {
    const { scrollToColumn, scrollToRow, selectedRecords } = this.state
    return this._cellRenderer({ columnIndex, isVisible, key, parent, rowIndex, style, isScrolling, scrollToColumn, scrollToRow, selectedRecords })
  }

  renderNoContent () : React.Node {
    const width = this.state.visibleColumns.reduce((acc, column, index) => acc + this._getColumnWidth({ index }), 0) + 'px'
    return (<div style={{ width }}>&nbsp;</div>)
  }

  onCollectHandle (prop: any) : any {
    return prop
  }

  handleKeyDown (event: SyntheticKeyboardEvent<HTMLDivElement>) {
    this.keyboardNavigate(event)
  }

  handleToolsAnchorHover () {
    this.setState({ toolsVisible: true })
  }

  handleToolsUnhover : () => void = debounce(() => {
    this.setState({ toolsVisible: false })
  }, 500)

  handleToolsHover () {
    // $FlowFixMe[prop-missing]
    if (this.state.toolsVisible) this.handleToolsUnhover.cancel()
  }

  handleToolsDoubleClick () {
    if (this.props.alwaysShowTools) return

    const toolsVisibilityPinned = !this.state.toolsVisibilityPinned
    this.setState({ toolsVisibilityPinned })
    if (toolsVisibilityPinned) {
      this.notificationManager.show({
        id: Symbol.for('panel-pinned-message'),
        icon: 'thumbtack',
        message: __('PANEL_PINNED'),
        details: __('DOUBLE_CLICK_TO_UNPIN'),
        type: NOTIFICATION.SUCCESS
      })
    }
  }

  render () : React.Node {
    const {
      visibleColumns = [],
      filtered,
      sorting,
      localFilter,
      placeholder = {},
      toolsVisible,
      toolsVisibilityPinned,
      _changeTrigger
    } = this.state
    const { noHeader, noSettings, conceal, children, alwaysShowTools } = this.props

    const rowHeigth = getRemUnitSize() * 1.5
    const cellHeight = rowHeigth

    const className = cn('grid-overlay', this.props.className, {
      placeholder: placeholder && Boolean(placeholder.text),
      blink: placeholder && placeholder.blink
    })

    return (
      <ScrollSync>
        {({
          clientHeight,
          clientWidth,
          onScroll,
          scrollHeight,
          scrollLeft,
          scrollTop,
          scrollWidth
        }) => {
          return (
            <div
              key='grid-container'
              {...genTestName(this.testName)}
              data-test-rows-length={isTestingMode() ? this.state.filtered.length : null}
              data-test-cols-length={isTestingMode() ? this.state.columns.filter(value => value.visible !== false).length : null}
              className={className}
              placeholder={placeholder ? placeholder.text : ''}
            >
              <AutoSizer>{({ height, width }) => (
                <React.Fragment key='grid-body'>
                  {
                    noHeader
                      ? null
                      : (
                        <div className='header-box' style={{ width: width, height: rowHeigth * 2 }}>
                          <ReactVirtualized
                            className='HeaderGrid'
                            localFilter={localFilter}
                            changed={this.state.changed}
                            sorting={sorting}
                            tabIndex={-1}
                            ref={this.gridHeader}
                            columnWidth={this._getColumnWidth}
                            columnCount={visibleColumns.length}
                            height={rowHeigth * 2}
                            rowHeight={rowHeigth}
                            cellRenderer={this.renderHeaderCell}
                            rowCount={2}
                            scrollLeft={scrollLeft}
                            width={width}
                            conceal={conceal}
                          />
                        </div>
                        )
                  }
                  <div
                    className='grid-box'
                    data-rh='tooltip 1'
                    onKeyDown={this.handleKeyDown}
                  >
                    <ContextMenuTrigger
                      collect={this.onCollectHandle}
                      id={this.props.contextMenuId || ''}
                      disableIfShiftIsPressed
                      holdToDisplay={isMobileMode() ? 1000 : -1}
                      grid={this}
                      onItemClick={this.props.onContextMenuClick}
                    >
                      <ReactVirtualized
                        containerProps={genTestName('scrolling', this.testName)}
                        changeTrigger={_changeTrigger}
                        tabIndex={-1}
                        overscanColumnCount={4}
                        ref={this.grid}
                        scrollingResetTimeInterval={50}
                        changed={this.state.changed}
                        className='BodyGrid'
                        cellRenderer={this.renderCell}
                        records={filtered}
                        selectedRecords={this.state.selectedRecords}
                        onSectionRendered={this.handleSectionRendered}
                        columnCount={visibleColumns.length}
                        columnWidth={this._getColumnWidth}
                        onScroll={onScroll}
                        height={height - (noHeader ? 0 : rowHeigth * 2)}
                        rowCount={filtered.length}
                        rowHeight={cellHeight}
                        estimatedColumnSize={100}
                        estimatedRowSize={cellHeight}
                        width={width}
                        noContentRenderer={this.renderNoContent}
                      />
                    </ContextMenuTrigger>
                  </div>

                  <div className='tools-anchor' onMouseOver={this.handleToolsAnchorHover} />
                  {(toolsVisible || toolsVisibilityPinned || alwaysShowTools)
                    ? (
                      <React.Suspense fallback={__('LOADING')}>
                        <Toolbox
                          pinned={toolsVisibilityPinned || alwaysShowTools}
                          noSettings={Boolean(noSettings)}
                          total={filtered.length}
                          onInvokeExport={this.handleInvokeExport}
                          onOpenSettings={this.handleOpenSettings}
                          onMouseLeave={this.handleToolsUnhover}
                          onMouseOver={this.handleToolsHover}
                          onTogglePin={this.handleToolsDoubleClick}
                          width={width}
                        >
                          {children}
                        </Toolbox>
                      </React.Suspense>)
                    : null}
                </React.Fragment>)}
              </AutoSizer>
            </div>
          )
        }}
      </ScrollSync>
    )
  }
}

export { GridEventMediator }
export default Grid
