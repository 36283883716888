/**
 * @file UpdateObserverExtension.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 10th December 2020 10:55:41 am
 * @copyright 2015 - 2020 SKAT LLC, Delive LLC
 * @flow
 */
import type { iStockExtension, iDataConnection, iNotificationManager, iLogger } from './../types'

import { Inject } from '../serviceLocator'
import { NOTIFICATION, __ } from 'web-panel/globals'

class UpdateObserverExtension implements iStockExtension {
  @Inject logger : iLogger
  @Inject dataConnection : iDataConnection
  @Inject notificationManager : iNotificationManager

  async activate () : Promise<void> {
    this.dataConnection.onServerEvent('server-updated', () => {
      // Если мы сразу покажем это сообщение, то скорее всего клиент обновит
      // и увидит bad gateway, потому-что на медленных машинах запуск может
      // длится больше секунды
      setTimeout(() => {
        this.logger.info('Application has been updated. Showing a message after a bit delay')
        this.notificationManager.show({
          message: __('WEB_PLATFORM_HAS_BEEN_UPDATED_JUST_NOW'),
          details: __('PAGE_RELOAD_IS_NECCESSARY'),
          type: NOTIFICATION.WARNING,
          persistent: true,
          unclosable: true,
          buttons: [{
            title: __('RELOAD_PAGE'),
            action: () => window.location.reload(true)
          }]
        })
      }, 1000 * 30)
    })
  }
}

export default UpdateObserverExtension
