/**
 * @file Row.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Wednesday, 7th October 2020 8:09:29 am
 * @copyright 2015 - 2020 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { RowProps } from './types'
import * as React from 'react'
import cn from 'classnames'

const Row = (props: RowProps) : React.Node => {
  let { className, noMargin, noPadding, children, style } = props
  className = cn('row', className, { 'no-margin': noMargin, 'no-padding': noPadding })
  return (<div className={className} style={style}>{children}</div>)
}

export default Row
