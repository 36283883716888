/**
 * @file languages.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 9th July 2019 4:14:46 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { Locale } from '../types'
import type { iDataProvider } from 'web-panel-essentials/types'

import { Cached } from 'web-panel-essentials/decorators'
import { Injectable } from 'web-panel/serviceLocator'
import { RemoteDataProvider } from 'web-panel/connections/DataProvider'
import { rowAllowed } from 'web-panel/views/grid/filtration'
import { getCurrentLocale } from 'web-panel/globals'

@Injectable('localeProvider', true)
class LocaleProvider extends RemoteDataProvider<Locale> implements iDataProvider<Locale> {
  get ENDPOINT () : string {
    return '/locales'
  }

  @Cached({ isPromise: true, isDeepEqual: true })
  async get (...args: *) : Promise<Locale[]> {
    const payload = await super.get(...args)
    const [query] = args

    if (query && query.where) return payload.filter((record) => rowAllowed(record, query.where, { locale: getCurrentLocale() }))
    return payload
  }
}

export default LocaleProvider
