/**
 * @module RemoteDataCache
 * @memberof web-panel.cache
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 19th November 2020 8:40:19 am
 * @copyright 2015 - 2020 SKAT LLC, Delive LLC
 * @flow
 */
import { Injectable } from '../serviceLocator'
import { spawn, Worker } from 'threads'
import { getFullURL } from 'web-panel/globals'

// $FlowFixMe
import workerURL from 'threads-plugin/dist/loader?name=cache!./cache.async-worker.js'// eslint-disable-line import/no-webpack-loader-syntax

@Injectable('remoteDataCache', true)
class RemoteDataCache {
  marks: { [string]: string }
  worker: {pull: (endpoint: string, cacheMark: string, marks: {}) => {}}

  constructor () {
    this.marks = global.CACHE_MARKS
  }

  async getWorker () : any {
    if (this.worker) return this.worker
    else {
      this.worker = await spawn(new Worker(workerURL), { type: 'module' })
      return this.worker
    }
  }

  async pull (endpoint: string, cacheMark: string) : Promise<any> {
    const worker = await this.getWorker()
    const url = getFullURL(endpoint)
    return await worker.pull(url, cacheMark, this.marks)
  }

  async clear () {
    const worker = await this.getWorker()
    await worker.clear()
  }
}

export default RemoteDataCache
