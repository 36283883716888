/**
 * @file DataConnection.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 26th July 2019 12:43:26 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow
 */

import type { iConnection, iConnectionTransport, iLogger, EmitterEvents, iEventEmitter, iREST, iNotificationManager, JSONValue } from './../types'
import type { Credentials } from 'web-panel-essentials/types'

import { __, NOTIFICATION } from '../globals'
import shortid from 'shortid'
import { Inject, Injectable } from '../serviceLocator'
import EventEmitter from 'web-panel/utils/EventEmitter'
import { TransportError } from '../errors'

/**
* Uses built-in sails feature to work with web sockets using
* socket.io. Sails by itself monitors the connection and restores the connection when
* necessary
* @summary Connecting to the sails server and receiving data from it
* @class DataConnection
* @extends EventEmitter
* @copyright SKAT Ltd 2015-2019
* @since 1.0.0
*/

@Injectable('dataConnection', true)
class DataConnection extends EventEmitter implements iConnection, iREST {
  @Inject logger : iLogger
  @Inject ioTransport : iConnectionTransport & iEventEmitter & iREST
  @Inject notificationManager: iNotificationManager

  username: string
  password: string
  connectionEstablished: Promise<void>

  static get EVENT () : EmitterEvents {
    return {
      CONNECT: 'connect',
      DISCONNECT: 'disconnect',
      QUERY_STARTED: 'query_started',
      QUERY_FINISHED: 'query_finished'
    }
  }

  constructor () {
    super()
    this.logger.namespace = 'data'
    if (!this.ioTransport) throw new TransportError(__('UNABLE_TO_INITIALIZE_SPECIFIED_TRANSPORT'))

    const dcId = Symbol('id')
    let rcId = Symbol('id')

    this.ioTransport.once('disconnect', async () => {
      await this.notificationManager.show({
        id: dcId,
        type: NOTIFICATION.DEFAULT,
        persistent: true,
        message: __('CONNECTION_TO_BACKEND_LOST'),
        progress: 0
      })
    })

    this.ioTransport.once('reconnect', async () => {
      await this.notificationManager.show({
        type: NOTIFICATION.DEFAULT,
        message: __('SUCCESSFULLY_RECONNECTED'),
        progress: 0
      })
      this.notificationManager.destroy(dcId)
      this.notificationManager.destroy(rcId)
    })

    this.ioTransport.once('reconnecting', async () => {
      rcId = Symbol('id')
      await this.notificationManager.show({
        id: rcId,
        type: NOTIFICATION.DEFAULT,
        message: __('RECONNECTING_ATTEMPT'),
        progress: 0
      })

      let timeout
      let progress = 0
      const tick = async () => {
        if (progress > 10) {
          clearTimeout(timeout)
          this.notificationManager.destroy(rcId)
        } else {
          progress += 1
          this.notificationManager.update(rcId, { progress: progress * 20 })
          timeout = setTimeout(tick, 1000)
        }
      }
      tick()
    })
  }

  /**
  * @method skat.connections.Data#connect
  * @override
  */
  connect () : Promise<void> {
    this.logger.info('Initializing new data connection')
    this.connectionEstablished = (new Promise((resolve, reject) => {
      this.ioTransport.once('connect', resolve)
      this.ioTransport.once('disconnect', reject)
    }) : Promise<void>)
    return this.connectionEstablished
  }

  disconnect () {
    this.logger.warn('STUB', 'Not implemented yet')
  }

  onServerEvent (...args: Array<any>) : void {
    this.ioTransport.on(...args)
  }

  removeServerEventListener (...args: Array<any>) : void {
    this.ioTransport.off(...args)
  }

  authorize (credentials: Credentials, { url = '/auth/login' }: Object) : Promise<any> {
    this.username = credentials.username || this.username
    this.password = credentials.password || this.password

    return this.post(url, {
      username: this.username,
      password: this.password
    })
  }

  /**
  * Connection Loss Handling
  * @param {object} state connection status
  * @method skat.connections.Connection#_onConnectionClose
  * @private
  * @return {skat.connections.Connection}
  */
  _onConnectionClose () {
    window.alert(__('SERVER_IS_UNREACHABLE'))
    window.onbeforeunload = null
    window.location.reload()
  }

  /**
  * @method skat.connections.Data#isConnected
  * @override
  */
  get connected () : boolean {
    return this.ioTransport.connected
  }

  /**
  * Sending a get request
  * @param {String} url destination
  * @param {PlainObject} [opt_params] query params
  * @method skat.connections.Data#get
  * @return {JQuery.Promise}
  */
  async get (url: string, optParams: JSONValue) : Promise<JSONValue> {
    const response = await this.send('get', url, optParams)
    return response
  }

  /**
  * Sending a create request
  * @param {String} url destination
  * @param {Object} [opt_params] query params
  * @method skat.connections.Data#create
  * @return {JQuery.Promise}
  */
  create (url: string, optParams: JSONValue) : Promise<JSONValue> {
    return this.send('post', url, optParams)
  }

  /**
  * Sending a post request
  * @param {String} url destination
  * @param {Object} [opt_params] query params
  * @method skat.connections.Data#post
  * @return {JQuery.Promise}
  */
  post (url: string, optParams: JSONValue) : Promise<JSONValue> {
    return this.create.apply(this, arguments)
  }

  /**
  * Sending a update request
  * @param {String} url destination
  * @param {Object} [opt_params] query params
  * @method skat.connections.Data#update
  * @return {JQuery.Promise}
  */
  update (url: string, optParams: JSONValue) : Promise<JSONValue> {
    return this.send('put', url, optParams)
  }

  /**
  * Sending a put request
  * @param {String} url destination
  * @param {Object} [opt_params] query params
  * @alias this.update
  * @method skat.connections.Data#put
  * @return {JQuery.Promise}
  */
  put () : Promise<JSONValue> {
    this.logger.debug('Update request', arguments)
    return this.update.apply(this, arguments)
  }

  /**
  * Sending a delete request
  * @param {String} url destination
  * @param {Object} [opt_params]  query params
  * @method skat.connections.Data#delete
  * @return {JQuery.Promise}
  */
  delete (url: string, optParams: JSONValue) : Promise<JSONValue> {
    return this.send('delete', url, optParams)
  }

  /**
  *Submit request
  * @param {String} method request type get, post, udpate etc
  * @param {String} url destination
  * @param {Object} [opt_params] query params
  * @method skat.connections.Data#send
  * @return {JQuery.Promise}
  */
  async send (method: 'get' | 'post' | 'put' | 'delete', url: string, optParams: JSONValue) : Promise<JSONValue> {
    try {
      let response

      if (this.connected) {
        response = await this._send(method, url, optParams)
      } else {
        this.logger.debug('Waiting for connection...')
        await this.connectionEstablished
        response = await this._send(method, url, optParams)
      }
      return response
    } catch (e) {
      this.logger.error(method, url, optParams || '')
      this.logger.error(e)
      throw e
    }
  }

  _send (method: 'get' | 'post' | 'put' | 'delete', url: string, optParams: JSONValue) : Promise<JSONValue> {
    if (!this.ioTransport) throw new TransportError(__('UNABLE_TO_INITIALIZE_SPECIFIED_TRANSPORT'))

    this.emit(DataConnection.EVENT.QUERY_STARTED, { method, url })
    const qId = shortid.generate()
    this.logger.debug(`_${qId}_ ***${method.toUpperCase()}*** ${url}`)

    const methods = {
      get: this.ioTransport.get.bind(this.ioTransport),
      post: this.ioTransport.post.bind(this.ioTransport),
      put: this.ioTransport.put.bind(this.ioTransport),
      delete: this.ioTransport.delete.bind(this.ioTransport)
    }

    return (new Promise((resolve, reject) => {
      methods[method](url, optParams, (data, state) => {
        this.emit(DataConnection.EVENT.QUERY_FINISHED, { method, url })
        if (state.statusCode >= 200 && state.statusCode < 300) {
          this.logger.info(`_${qId}_ ${state.statusCode} OK`)
          resolve(data)
        } else {
          this.logger.warn(`_${qId}_ Failed`)
          this.logger.debug(state.error)
          reject(state)
        }
      })
    }) : Promise<JSONValue>)
  }
}

export default DataConnection
