/**
 * @file Icon.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Wednesday, 20th October 2021 12:22:31 pm
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow strict
 */
/* global SyntheticEvent, SyntheticMouseEvent, HTMLElement */
import type { asyncVoid } from '../types'
import * as React from 'react'
import cn from 'classnames'

type Props = {|
  icon: string,
  onClick?: (event: SyntheticEvent<HTMLElement>) => asyncVoid,
  onDoubleClick?: (event: SyntheticEvent<HTMLElement>) => asyncVoid,
  onMouseOver?: (event: SyntheticMouseEvent<HTMLElement>) => asyncVoid,
  onMouseOut?: (event: SyntheticMouseEvent<HTMLElement>) => asyncVoid,
  className?: string,
  containerClassName?: string,
  title?: string,
  tabIndex?: number,
  testName?: string,
  style?: {}
|}

function Icon (props: Props) : React.Node {
  let { title, icon, style, className, containerClassName, tabIndex, onClick, onDoubleClick, onMouseOver, onMouseOut } = props
  className = cn('icon', 'fa-solid ', 'fa-' + icon, className)
  containerClassName = cn('icon-wrapper', containerClassName)
  return React.useMemo(() => (
    <span
      className={containerClassName}
      data-title={title}
      tabIndex={tabIndex}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <i className={className} style={style} />
    </span>
  ),
  [icon, className, containerClassName])
}

export default Icon
