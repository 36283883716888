/**
 * @file RichEdit.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 10th March 2022 9:37:32 am
 * @copyright 2015 - 2022 SKAT LLC, Delive LLC
 * @flow strict
 */
/* global HTMLTextAreaElement, SyntheticEvent, SyntheticFocusEvent, HTMLElement */
import type { CombinedValue, asyncVoid, ReactRef } from 'web-panel/types'

import * as React from 'react'
import autoBind from 'react-autobind'
import cn from 'classnames'

type Props = {
  value?: mixed,
  placeholder?: string,
  displayValue: string,
  disabled?: boolean,
  invalid?: boolean,
  onChange: (changes: CombinedValue) => asyncVoid,
  onFocus?: (event: SyntheticEvent<HTMLElement>) => asyncVoid,
  onBlur?: (event: SyntheticEvent<HTMLElement>) => asyncVoid
}

type State = {
  displayValue: string,
  focused: boolean
}

class RichEdit extends React.PureComponent<Props, State> {
  input: ReactRef<'textarea'>

  constructor (props: Props) {
    super()
    autoBind(this)
    this.input = React.createRef()

    this.state = {
      displayValue: props.displayValue,
      focused: false
    }
  }

  componentDidMount () {
    const cursor = this.props.displayValue.length
    this.input.current?.setSelectionRange(cursor, cursor)
  }

  focus () {
    if (this.props.disabled) return
    this.input.current?.focus()
    this.setState({ focused: true })
  }

  handleFocus (event: SyntheticEvent<HTMLElement>) {
    if (this.props.disabled) return
    this.setState({ focused: true })
    if (this.props.onFocus) this.props.onFocus(event)
  }

  handleChange (event: SyntheticEvent<HTMLTextAreaElement>) {
    const { value } = this.props
    const displayValue = event.currentTarget.value
    this.setState({ displayValue })
    this.props.onChange({ value, displayValue })
  }

  handleBlur (event: SyntheticFocusEvent<HTMLTextAreaElement>) {
    if (this.state.focused) {
      // $FlowFixMe[incompatible-call]
      if (this.input === document.activeElement) return
      this.setState({ focused: false })
      if (this.props.onBlur) this.props.onBlur(event)
    }
  }

  componentDidUpdate (prevProps: Props, prevState: State) {
    // Something changed outside the component, we should obey
    if (this.props.displayValue !== prevProps.displayValue && this.state.displayValue === prevState.displayValue) {
      this.setState({ displayValue: this.props.displayValue })
    }
  }

  render () : React.Node {
    const { displayValue, focused } = this.state
    const { disabled, invalid, placeholder } = this.props
    const className = cn({ focused, disabled, invalid })

    return (
      <textarea
        ref={this.input}
        placeholder={placeholder}
        className={className}
        onFocus={this.handleFocus}
        value={displayValue}
        onChange={this.handleChange}
      />
    )
  }
}

export default RichEdit
