/**
 * @file DropdownMenu.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 19th October 2021 6:08:38 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow strict
 */
/* global SyntheticEvent */
import type { Entity } from '../types'
import type { DropdownMenuProps } from './types'

import * as React from 'react'
import Option from './Option'
import cn from 'classnames'
import Portal from 'react-relative-portal'
import autoBind from 'react-autobind'
import { CAST } from 'web-panel-essentials/misc'

type Props<T> = DropdownMenuProps<T>

class DropdownMenu<T: Entity> extends React.PureComponent<Props<T>> {
  constructor (props: Props<T>) {
    super(props)
    autoBind(this)
  }

  handleOutClick (event: SyntheticEvent<typeof Portal>) {
    if (this.props.onOutClick && this.props.items.length) this.props.onOutClick(event)
  }

  handleActivateByValues (value: mixed, displayValue: string) {
    this.props.onChange({ value, displayValue })
    if (this.props.onFocusTransfer) this.props.onFocusTransfer()
  }

  render () : React.Node {
    const { valueKey, displayValueKey, className } = this.props
    const { items, focusedItemIndex } = this.props

    return (
      <Portal top={0} left={0} onOutClick={this.handleOutClick} component='s'>
        <div className={cn('field-dropdown', className, { hidden: !items.length })}>
          {items.map((item, index) => (
            <Option
              key={index}
              value={item[valueKey]}
              displayValue={CAST.String(item[displayValueKey])}
              onClick={this.handleActivateByValues}
              focused={index === focusedItemIndex}
            />
          ))}
        </div>
      </Portal>
    )
  }
}

export default DropdownMenu
