/**
 * @file ExtensionsManager.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 13th December 2019 3:58:04 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow
 */
import * as React from 'react'
import type { iLogger, iExtensionsManager, iExtension } from '../types'
import { Injectable, Inject } from '../serviceLocator'

@Injectable('extensionsManager', true)
class ExtensionsManager implements iExtensionsManager {
  @Inject logger : iLogger

  extendables: Object
  extensions: Array<any>

  constructor () {
    this.extendables = {}
    this.extensions = []
    this.logger.namespace = 'extensions'
  }

  getExtensions (extendableName: string) : Array<iExtension> {
    return this.extensions.filter(({ targetName }) => targetName === extendableName).map(({ extension }) => extension) || []
  }

  registerExtendable (extendableName: string, point: Object, handleExtensionsChange: Function) {
    this.logger.info(`*** Registered new extension point "${extendableName}" ***`)
    this.extendables[extendableName] = { point, handleExtensionsChange }
  }

  registerExtension (targetName: string, extension: iExtension) {
    this.extensions.push({ targetName, extension })
    if (this.extendables[targetName]) {
      this.extendables[targetName].handleExtensionsChange(this.getExtensions(targetName))
    } else {
      this.logger.warn('Extension was registered before extendable point appeared')
    }
  }

  getWrapper (extendableName: string, parent: any) : (wrapped: React.Node) => Array<React.Node> {
    return (Wrapped: React.Node) => {
      const extensions : Array<iExtension> = this.getExtensions(extendableName)
      return [
        ...extensions.map(extension => extension.before ? extension.before(parent) : null),
        Wrapped, // TODO: Replace
        ...extensions.map(extension => extension.after ? extension.after(parent) : null)
      ]
    }
  }
}

export default ExtensionsManager
