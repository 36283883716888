/**
 * @file Footer.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 29th October 2019 1:16:56 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 */

import type { ButtonModel } from './../../types'

import * as React from 'react'
import { Button } from '../forms/components'

class Footer extends React.PureComponent<any, any> {
  render () : React.Node {
    const { buttons, extraView, primaryActionRef, modal } = this.props
    return (
      <div className='footer'>
        <div className='extra' role='region'> {extraView || null}</div>
        <div className='buttons' role='region'>
          {
            buttons.map((button: ButtonModel, index: number) => (
              <Button
                ref={button.isPrimary ? primaryActionRef : null}
                disabled={button.disabled || this.props.buttonsDisabled}
                key={index}
                modal={modal}
                {...button}
              />)
            )
          }
        </div>
      </div>
    )
  }
}

export default Footer
