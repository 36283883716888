/**
 * @file ShortcutsManager.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Monday, 3rd August 2020 9:12:49 am
 * @copyright 2015 - 2020 SKAT LLC, Delive LLC
 * @flow
 */
import type { iLogger, iEventEmitter, EmitterEvents, iExposedView } from 'web-panel/types'
import type { iDataProvider } from 'web-panel-essentials/types'

import { Inject } from '../serviceLocator'
import * as React from 'react'
import Grid from './grid/Grid'
import pick from 'lodash/pick'
import autoBind from 'react-autobind'

const COMMAND = {
  EDIT: 'edit'
}

export type Props = {
  onEdit: Function
}
export type State = {}

class ShortcutsListView extends React.Component<Props, State> implements iExposedView<null> {
  @Inject logger : iLogger
  @Inject shortcutsManager : iDataProvider<any> & iEventEmitter
  @Inject eventBus : iEventEmitter

  grid: React.ElementRef<any>

  static get COMMAND () : EmitterEvents {
    return COMMAND
  }

  get COMMAND () : EmitterEvents {
    return ShortcutsListView.COMMAND
  }

  async data () : Promise<null> {
    return null
  }

  async command () {

  }

  constructor (props: Props) {
    super(props)
    autoBind(this)
    this.state = {}
    this.grid = React.createRef()
  }

  getData () : {} {
    return pick(this.state, ['forceLogin'])
  }

  refresh () {
    this.grid.current.fetch()
  }

  handleDoubleClick (...args: any) {
    this.props.onEdit(...args)
  }

  componentDidMount () {
    this.grid.current.eventBus.on(this.grid.current.EVENT.DOUBLE_CLICK, this.handleDoubleClick)
    this.shortcutsManager.on(this.shortcutsManager.EVENT.DATA_CHANGED, this.refresh)
  }

  componentWillUnmount () {
    this.shortcutsManager.off(this.shortcutsManager.EVENT.DATA_CHANGED, this.refresh)
    this.grid.current.eventBus.off(this.grid.current.EVENT.DOUBLE_CLICK, this.handleDoubleClick)
    this.grid = null
  }

  render () : React.Node {
    return (
      <div className='h400px mini-margin'>
        <Grid
          autofetch
          name='shortcuts'
          ref={this.grid}
          sorting={{ defaultShortcut: 'DESC', name: 'ASC' }}
          dataProvider={this.shortcutsManager}
        />
      </div>
    )
  }
}

export default ShortcutsListView
