/**
 * @file ColumnDataManager.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 2nd July 2020 4:39:09 pm
 * @copyright 2015 - 2020 SKAT LLC, Delive LLC
 */
import type { iLogger, iDataProvider } from 'web-panel/types'
import type { ColumnDefinition, DataControlCommands } from 'web-panel-essentials/types'
import type { ExtendedColumnDefinition } from './ColumnDefinitionProvider'

import { Inject } from 'web-panel/serviceLocator'
import EventEmitter from 'web-panel/utils/EventEmitter'

const GRID_MANAGER_EVENT = {
  UP: 'up',
  DOWN: 'down',
  WIDTH: 'width',
  VISIBLE: 'visible',
  ALTER_NAME: 'alter-name',
  UPDATE_SELECTION: 'update-selection'
}

class ColumnsDataManager extends EventEmitter {
  @Inject logger : iLogger
  dataProvider: iDataProvider<ExtendedColumnDefinition> & {set: Function}
  initialColumns: Array<ColumnDefinition>
  static get EVENT () {
    return GRID_MANAGER_EVENT
  }

  get EVENT () {
    return ColumnsDataManager.EVENT
  }

  constructor (dataProvider: iDataProvider<ExtendedColumnDefinition> & {set: Function}, initialColumns: Array<ColumnDefinition>) {
    super()
    this.dataProvider = dataProvider
    this.initialColumns = initialColumns
  }

  async activate ({ updateRecord, getRecords } : DataControlCommands<ExtendedColumnDefinition>) {
    this.logger.info('Data manager activated')

    const swap = async (columns, id, up) => {
      const shift = up ? -1 : 1

      columns = [...columns]
      const record = columns.find(column => id === column.id)
      const neighbour = columns.find(({ position }) => position === record.position + shift)
      if (!neighbour) return

      record.position = record.position + shift
      neighbour.position = neighbour.position - shift

      await updateRecord(record)
      await updateRecord(neighbour)

      this.dataProvider.set(getRecords())
      return record
    }

    this.on(GRID_MANAGER_EVENT.UP, async ({ record }) => {
      const columns = getRecords()
      if (record.position <= 0) return
      const updated = await swap(columns, record.id, true)
      this.emit(this.EVENT.UPDATE_SELECTION, [updated])
    })

    this.on(GRID_MANAGER_EVENT.DOWN, async ({ record }) => {
      const columns = getRecords()
      if (record.position >= columns.length - 1) return
      const updated = await swap(columns, record.id, false)
      this.emit(this.EVENT.UPDATE_SELECTION, [updated])
    })

    this.on(GRID_MANAGER_EVENT.WIDTH, async ({ record, value }) => {
      await updateRecord({ ...record, width: value })
      const columns = getRecords()
      this.dataProvider.set(columns)
    })

    this.on(GRID_MANAGER_EVENT.VISIBLE, async ({ record, value }) => {
      await updateRecord({ ...record, visible: value, visibilityOverwrited: true })
      const columns = getRecords()
      this.dataProvider.set(columns)
    })

    this.on(GRID_MANAGER_EVENT.ALTER_NAME, async ({ record, value }) => {
      await updateRecord({ ...record, alias: value })
      const columns = getRecords()
      this.dataProvider.set(columns)
    })
  }

  deactivate () {
    this.removeAllListeners()
    this.logger.info('Data manager deactivated')
  }
}

export default ColumnsDataManager
export { GRID_MANAGER_EVENT }
