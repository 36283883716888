/**
 * @file actionRegistry.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Monday, 8th July 2019 5:01:41 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow
 */
import type { iLogger, iShortcutsManager, iCRUD, Action, iEventEmitter, UserPreferences, iGlobalEventBus } from '../../types'
import type { WaterlineQuery, ProviderMeta } from 'web-panel-essentials/types'

import debounce from 'lodash/debounce'
import { Inject, Injectable } from '../../serviceLocator'
import Mousetrap, { bind as _bind, unbind } from 'mousetrap'
import { DataProvider } from '../../connections/DataProvider'
import { __, getCurrentLocale, KEYBINDING_SCOPE } from '../../globals'
import { FORMAT } from 'web-panel-essentials/misc'
import { rowAllowed } from 'web-panel/views/grid/filtration'
import isObject from 'lodash/isObject'
import shortid from 'shortid'
import { DuplicateKeyError } from '../../errors'

const STORAGE_KEY = 'shortcuts'

Mousetrap.prototype.stopCallback = function (e, element, combo) {
  const tags = ['INPUT', 'SELECT', 'TEXTAREA']
  if (element.classList.contains('protect-from-shortcuts')) return true
  if (tags.includes(element.tagName) && combo === 'space') return true
  return false
}

@Injectable('shortcutsManager', true)
class ShortcutsManager extends DataProvider<Action> implements iShortcutsManager {
  @Inject logger: iLogger
  @Inject globalEventBus : iGlobalEventBus & iEventEmitter
  @Inject preferencesManager : iCRUD<UserPreferences>

  overrides: {[string]: string}
  mapping: {[string]: Action}
  _updateBindings: Function

  constructor () {
    super()
    this.mapping = {}
    this.logger.namespace = 'action-registry'
    this.logger.info('Initializing action registry')
    if (!this.globalEventBus.eventRegistered('new-action-registered')) {
      this.globalEventBus.registerEvent('new-action-registered')
    }

    this._updateBindings = debounce(() => {
      this.logger.info('Updating action bindings')
      for (const actionName in this.mapping) {
        const action: Action = this.mapping[actionName]

        const [scope, scopeOptions] = action.scope
        const execute = async (e) => {
          e.preventDefault()
          if (e.repeat) return

          if (scope === KEYBINDING_SCOPE.GLOBAL) await action.action()
          if (scope === KEYBINDING_SCOPE.WINDOW) {
            const windowId = scopeOptions
            const [activeWindow] = global.application.serviceLocator.registry.windowManager.instance.getActive() // FIXME: Ужс, некрасиво
            if (!activeWindow) return
            if (activeWindow.id === windowId) await action.action()
          }
          if (scope === KEYBINDING_SCOPE.PAGE) {
            const pageId = scopeOptions
            const activePage = await global.application.serviceLocator.registry.pageManager.instance.getActive() // FIXME: Ужс, кошмар
            if (!activePage) return
            if (activePage.id === pageId) await action.action()
          }

          return false
        }

        unbind(action.defaultShortcut)
        if (action.customShortcut) {
          unbind(action.customShortcut)
          _bind(action.customShortcut, execute)
        } else {
          _bind(action.defaultShortcut, execute)
        }
      }
    }, 1000)
  }

  async loadOverrides () : Promise<Object> {
    if (isObject(this.overrides)) return this.overrides
    const prefs = await this.preferencesManager.read(STORAGE_KEY)
    if (prefs) {
      this.logger.debug('Loaded overrides', prefs)
      this.overrides = prefs.value || {}
    } else {
      this.overrides = {}
    }
    return this.overrides
  }

  async saveOverride (name: string, shortcut: string) : Promise<void> {
    const prefs = await this.preferencesManager.read(STORAGE_KEY)
    if (prefs && prefs.value) {
      prefs.value[name] = shortcut
      await this.preferencesManager.update({ name: STORAGE_KEY, value: prefs.value })
    } else {
      const value = { [name]: shortcut }
      await this.preferencesManager.update({ name: STORAGE_KEY, value })
    }
  }

  async meta () : Promise<ProviderMeta<Action>> {
    return {
      value: 'name',
      displayValue: 'title',
      columns: [
        { name: 'defaultShortcut', column: null, title: __('DEFAULT_SHORTCUT'), type: 'String', format: FORMAT.Uppercase, width: 150 },
        { name: 'customShortcut', column: null, title: __('CUSTOM_SHORTCUT'), type: 'String', format: FORMAT.Uppercase, width: 80 },
        { name: 'name', column: null, title: __('NAME'), format: FORMAT.String, type: 'String', width: 120, visible: false },
        { name: 'title', column: null, title: __('DETAILS'), format: FORMAT.String, type: 'String', width: 400 }
      ]
    }
  }

  async get (query: ?WaterlineQuery) : Promise<Array<Action>> {
    // $FlowFixMe[incompatible-type]: https://github.com/facebook/flow/issues/2133
    const payload : Array<Action> = Object.values(this.mapping)
    if (query && query.where) return payload.filter((action) => rowAllowed(action, query.where, { locale: getCurrentLocale() }))
    return payload
  }

  isRegistered (name: string) : boolean {
    return Boolean(this.mapping[name])
  }

  async register ({ name, title, scope, defaultShortcut, action } : Action) {
    if (this.mapping[name]) throw new DuplicateKeyError(__('ACTION_ALREADY_REGISTERED'))

    this.logger.info(`Registering action: ${name}`)
    const overrides = await this.loadOverrides()
    const customShortcut = overrides[name]
    this.mapping[name] = { id: shortid(), name, title, scope, defaultShortcut, customShortcut, action }
    this.updateBindings()
    this.globalEventBus.emit('new-action-registered', { action: this.mapping[name] })
  }

  /**
   * You can only update custom shortcut for existing registered bindings
   * You can't change default shortcut, use register method instead
   * @param {*} param0
   */
  update ({ name, customShortcut } : Object) {
    this.mapping[name] = { ...this.mapping[name], customShortcut }
    this.saveOverride(name, customShortcut)
    this._updateBindings()
    this.emit(this.EVENT.DATA_CHANGED, this.mapping[name], { name, customShortcut })
  }

  updateBindings () {
    this._updateBindings()
  }
}

export default ShortcutsManager
