/**
 * @file Icon.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 24th September 2021 10:01:36 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow
 */
import * as React from 'react'
import moize from 'moize'
import { Icon as IconComponent } from 'web-panel/components'
type Props = { iconName: string }

function Icon (props: Props) : React.Node {
  const { iconName } = props
  if (!iconName) return null
  return (
    <div className='icon-container'>
      <IconComponent className='notification-icon' icon={iconName} />
    </div>
  )
}

const CachableIcon : (props: Props) => React.Node = moize(Icon, { isReact: true })
export default CachableIcon
