/**
 * @file InlineAction.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 11th March 2022 9:36:58 am
 * @copyright 2015 - 2022 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { InlineActionProps } from './types'

import * as React from 'react'
import classnames from 'classnames'

export default function InlineAction ({ children, onClick, disabled, title, className } : InlineActionProps) : React.Node {
  const handleClick = () => {
    if (!disabled) onClick()
  }

  const classname = classnames('inline-action', { disabled }, className)
  return (
    <a data-title={title} className={classname} onClick={handleClick}>
      {children}
    </a>
  )
}
