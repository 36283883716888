/**
 * @fileOverview preferences.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 4th September 2020 12:17:27 pm
 * @copyright 2015 - 2020 SKAT LLC, Delive LLC
 * @flow
 */
import type { iPreferencesManager, UserPreferences, iLogger, iStore, iUserPreferencesDefaultsStrategy } from '../../types'
import type { ID, ProviderMeta } from 'web-panel-essentials/types'

import { CAST } from 'web-panel-essentials/misc'
import { Cached } from 'web-panel-essentials/decorators'
import { Injectable, Inject } from '../../serviceLocator'
import { __ } from 'web-panel/globals'
import Deferred from 'es6-deferred'

/**
 * This iPreferencesManager depends on iStore, you must specify one via setter,
 * also defaultsStrategy must be provided before initialization.
 * @example
 * preferencesManager.store = new CustomStore()
 * preferencesManager.defaultsStrategy = {execute: async () => ([])}
 */
@Injectable('preferencesManager', true)
class Preferences implements iPreferencesManager {
  @Inject logger: iLogger
  _lazyStore: typeof Deferred & Promise<iStore>
  _lazyStrategy: typeof Deferred & Promise<iUserPreferencesDefaultsStrategy>

  set defaultsStrategy (strategy: iUserPreferencesDefaultsStrategy) {
    this._lazyStrategy = this._lazyStrategy || new Deferred()
    this._lazyStrategy.resolve(strategy)
  }

  set store (store: iStore) {
    this._lazyStore = this._lazyStore || new Deferred()
    this._lazyStore.resolve(store)
  }

  get defaultsStrategy () : Promise<iUserPreferencesDefaultsStrategy> {
    this._lazyStrategy = this._lazyStrategy || new Deferred()
    return this._lazyStrategy.promise
  }

  get store () : Promise<iStore> {
    // We should wait untill somebody init
    // our store from outside via setter
    this._lazyStore = this._lazyStore || new Deferred()
    return this._lazyStore.promise
  }

  async meta () : Promise<ProviderMeta<UserPreferences>> {
    return {
      value: 'name',
      displayValue: 'name',
      columns: [
        { name: 'name', title: __('NAME'), column: 'name', format: CAST.String, type: 'String' },
        { name: 'value', title: __('VALUE'), column: 'value', format: CAST.String, type: 'String' }
      ]
    }
  }

  @Cached({ isPromise: true })
  async getDefaults () : Promise<UserPreferences[]> {
    const strategy : iUserPreferencesDefaultsStrategy = await this.defaultsStrategy
    const data = await strategy.execute()
    return data
  }

  async get () : Promise<UserPreferences[]> {
    // TODO: Implement waterline filtration
    const store = await this.store
    return store.getAll()
  }

  async getOne () : Promise<?UserPreferences> {
    const [record] = await this.get()
    return record
  }

  async read (name: ID) : Promise<UserPreferences> {
    name = CAST.String(name)
    const emptyResponse = { name, value: undefined }
    const store = await this.store
    const raw = store.getItem(CAST.String(name))
    try {
      if (raw) {
        const value : UserPreferences | null = raw ? JSON.parse(raw) : null
        const userPref : UserPreferences = { name: CAST.String(name), value }
        return userPref
      } else {
        const defaults = await this.getDefaults()
        const defaultValue = defaults.find((item) => item.name === name)
        if (!defaultValue) return emptyResponse

        defaultValue.value = JSON.parse(defaultValue.value)
        return defaultValue
      }
    } catch (e) {
      this.logger.error(e)
    }

    return emptyResponse
  }

  async create (record: UserPreferences) : Promise<UserPreferences> {
    const store = await this.store
    store.setItem(record.name, JSON.stringify(record.value))
    return record
  }

  async update (record: UserPreferences) : Promise<UserPreferences> {
    const store = await this.store
    store.setItem(record.name, JSON.stringify(record.value))
    return record
  }

  async delete (name: ID) {
    const store = await this.store
    store.removeItem(CAST.String(name))
  }

  async reset () : Promise<void> {
    const store = await this.store
    store.clear()
  }
}

export default Preferences
