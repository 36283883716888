// QC: HOLD
/**
 * @file ModalList.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 5th July 2019 5:32:27 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 */

import * as React from 'react'
import Indicator from './Indicator'
import isEqual from 'lodash/isEqual'

class ModalList extends React.Component {
  constructor () {
    super()
    this.handleClick = this.handleClick.bind(this)
  }

  shouldComponentUpdate (props, state) {
    if (this.props.active !== props.active) return true
    if (!isEqual(this.props.modals, props.modals)) return true
    return false
  }

  handleClick ({ id, isCollapsed }) {
    this.props.onIndicatorClick({ id, isCollapsed })
  }

  render () : React.Node {
    const { active, modals } = this.props
    return (
      <div className='modal-list'>{
        modals.filter((modal) => !modal.conceal).map(({ id, label, title, icon, isCollapsed, isFlashed, testName }, idx) => {
          return (
            <Indicator
              key={idx}
              id={id}
              label={label}
              title={title}
              icon={icon}
              testName={testName}
              isFlashed={isFlashed}
              isCollapsed={isCollapsed}
              onClick={this.handleClick}
              isActive={id === active}
            />
          )
        })
      }
      </div>
    )
  }
}

export default ModalList
