import { Mixin } from 'mixwith'

const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export const getUpdates = (changes, collection, collectionName) => {
  if (!changes) return
  if (!Array.isArray(changes)) changes = [changes]

  collection = collection.map((item) => {
    const updates = changes.find((c) => c.id === item.id)
    if (updates) {
      return { ...item, ...updates }
    } else return item
  })

  const updates = { [collectionName]: collection }
  return updates
}

const mList = Mixin((superclass) => class extends superclass {
  defineList (collectionName) {
    this[`add${capitalizeFirstLetter(collectionName)}`] = (items) => this._add(items, collectionName)
    this[`remove${capitalizeFirstLetter(collectionName)}`] = (items) => this._remove(items, collectionName)
    this[`update${capitalizeFirstLetter(collectionName)}`] = (changes) => this._update(changes, collectionName)
    this[`updateAll${capitalizeFirstLetter(collectionName)}`] = (changes) => this._updateAll(changes, collectionName)
    return []
  }

  setStateAsync = (state: Object) : Promise<void> => {
    if (this.updateStateImmediate) {
      this.setState(state)
      return Promise.resolve()
    }
    return new Promise((resolve) => this.setState(state, resolve))
  }

  async _add (items, collectionName) {
    if (!items) return
    if (!Array.isArray(items)) items = [items]
    const preHook = this[`_preAdd${capitalizeFirstLetter(collectionName)}`]
    const postHook = this[`_postAdd${capitalizeFirstLetter(collectionName)}`]

    if (preHook) items = preHook.apply(this, [items])
    let collection = this.state[collectionName]
    const marked = items.map((item) => {
      item.id = item.id || Symbol('id')
      return item
    })
    const deduped = marked.filter((item) => collection.findIndex((existingItem) => existingItem.id === item.id) < 0)

    collection = [...collection, ...deduped]

    await this.setStateAsync({
      [collectionName]: collection
    })

    const idLIst = items.map((i) => i.id)
    if (postHook) return postHook.apply(this, [collection.filter((i) => idLIst.includes(i.id))])
  }

  async _remove (items, collectionName) {
    if (!items) return
    if (!Array.isArray(items)) items = [items]
    items = items.map((item) => {
      if (typeof item !== 'object') item = { id: item }
      return item
    })

    const preHook = this[`_preRemove${capitalizeFirstLetter(collectionName)}`]
    const postHook = this[`_postRemove${capitalizeFirstLetter(collectionName)}`]

    if (preHook) items = preHook.apply(this, [items])
    let collection = this.state[collectionName]
    const idList = items.map((i) => i.id)
    collection = collection.filter((item) => !idList.includes(item.id))
    await this.setStateAsync({
      [collectionName]: collection
    })
    if (postHook) return postHook.apply(this, [collection])
  }

  async _update (changes, collectionName, noCommit = false) {
    if (!changes) return
    if (!Array.isArray(changes)) changes = [changes]

    const preHook = this[`_preUpdate${capitalizeFirstLetter(collectionName)}`]
    const postHook = this[`_postUpdate${capitalizeFirstLetter(collectionName)}`]

    if (preHook) changes = preHook.apply(this, changes)
    const collection = this.state[collectionName]

    const updates = getUpdates(changes, collection, collectionName)

    if (!noCommit) await this.setStateAsync(updates)
    if (postHook) return postHook.apply(this, [collection])
    return updates
  }

  async _updateAll (changes, collectionName) {
    let collection = this.state[collectionName]
    collection = collection.map((item) => {
      item = { ...item, ...changes }
      return item
    })
    await this.setStateAsync({
      [collectionName]: collection
    })
  }
})

export default mList
