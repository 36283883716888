/**
 * @file FileStoreManager.js
 * @project Web-panel
 * @author Andrey Nikulin (<izhastron@gmail.com>)
 * @copyright 2015 - 2022 SKAT LLC, Delive LLC
 * @flow
 */
import EventEmitter from 'web-panel/utils/EventEmitter'
import { Injectable } from 'web-panel/serviceLocator'
import AWS from 'aws-sdk'

AWS.config.update({
  accessKeyId: '',
  secretAccessKey: '',
  endpoint: '',
  s3ForcePathStyle: true,
  signatureVersion: 'v4'
})

@Injectable('fileStoreManager', true)
class FileStoreManager extends EventEmitter {
   s3: Object
   constructor () {
     super()
     this.s3 = new AWS.S3()
   }

   async download (bucket: string, key: string, mimeType?: string): Promise<void> {
     return new Promise((resolve, reject) => {
       this.s3.getObject({
         Bucket: bucket,
         Key: key
       }, (err, data) => {
         if (err) {
           console.error(err)
           reject(Error('Error download file'))
         } else {
           const blob = new Blob([data.Body], { /* eslint-env browser */
             type: mimeType
           })
           global.saveAs(blob, key)
           resolve()
         }
       })
     })
   }

   async upload (bucket: string, key: string, data: any, mimeType?: string): Promise<void> {
     const blob = new Blob([data], { /* eslint-env browser */
       type: mimeType
     })
     return await this.s3.upload({
       Bucket: bucket,
       Key: key,
       Body: blob
     }).promise()
   }
}

export default FileStoreManager
