/**
 * @file Map.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 17th May 2022 5:04:47 pm
 * @copyright 2015 - 2022 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { Node, ComponentType } from 'react'
import type { MapSpecification, MapProps, SubordinateMapProps } from 'web-panel/components/types'

import React, { PureComponent, Suspense, lazy } from 'react'
import { AutoSizer } from 'react-virtualized'
import { Cached } from 'web-panel-essentials/decorators'

type Props = {|
  specification: () => Promise<MapSpecification>,
  ...MapProps
|}

class Map extends PureComponent<Props> {
  APIKey: ?string

  constructor () {
    super()
    this.APIKey = null
  }

  @Cached({ isReact: true })
  getTargetComponent () : ComponentType<SubordinateMapProps> {
    return lazy(async () => {
      const { type, key } = await this.props.specification()
      this.APIKey = key // Грязно, но работать будет
      const module = import('web-panel/components/Map/' + type + '.js')
      return module
    })
  }

  render () : Node {
    const { zoom, center, onClick, children, panStrategy = ({ zoom, center }) => ({ zoom, center }) } = this.props
    const TargetComponent = this.getTargetComponent()

    return (
      <Suspense fallback='...'>
        <AutoSizer>{({ width, height }) => {
          const pan = panStrategy({ width, height, zoom, center })
          return (
            <TargetComponent
              APIKey={this.APIKey}
              zoom={pan.zoom}
              center={pan.center}
              width={width}
              height={height}
              onClick={onClick}
            >
              {children}
            </TargetComponent>
          )
        }}
        </AutoSizer>
      </Suspense>
    )
  }
}

export default Map
