/**
 * @file Sidebar.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 29th January 2021 6:32:18 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow
 */
import type { ID } from 'web-panel-essentials/types'
import type { Page, iPreferencesManager } from '../../types'

import * as React from 'react'


import autoBind from 'react-autobind'
import { KEY } from 'web-panel-essentials/misc'
import SidebarButton from './SidebarButton'
import cn from 'classnames'
import { isMobileMode } from 'web-panel/globals'
import { Inject } from '../../serviceLocator'

export function pageMap<T> (pages: {[ID]: Page}, iterator: (page: Page) => T) : T[] {
  const remapped = []
  const keys = Reflect.ownKeys(pages)
  for (const id of keys) remapped.push(iterator(pages[id]))
  return remapped
}

type Props = {
  active: ID,
  pages: {[ID]: Page},
  onActivate: (id: ID) => void
}

type State = {
  collapsed: boolean,
  cursor: number,
  pages: Page[]
}

const SIDEBAR_MODE_PREF = 'sidebar-collapsed'

class SideBar extends React.Component<Props, State> {
  @Inject preferencesManager : iPreferencesManager
  constructor () {
    super()
    autoBind(this)
    this.state = {
      collapsed: false,
      cursor: 0,
      pages: []
    }
  }

  async componentDidMount () {
    if (isMobileMode()) return
    const pref = await this.preferencesManager.read(SIDEBAR_MODE_PREF)
    if (pref && pref.value) this.setState({ collapsed: pref.value })
  }

  /**
   * Здесь реализуется переключение фокуса стрелками на клавиатуре
   * и активация сфокусированной кнопки при нажатии на Enter
   * @param {*} event событие
   */
  handleKeyboard (e: any) {
    let { cursor, pages } = this.state

    if (e.keyCode === KEY.DOWN || e.keyCode === KEY.UP) {
      if (e.keyCode === KEY.DOWN) cursor++
      if (e.keyCode === KEY.UP) cursor--

      if (cursor > pages.length - 1) cursor = 0
      if (cursor < 0) cursor = pages.length - 1
      this.setState({ cursor })
    }

    if (e.keyCode === KEY.ENTER) this.handleActivate(pages[cursor].id)
  }

  componentDidUpdate (prevProps: Props) {
    if (this.props.pages !== prevProps.pages) {
      const pages : Page[] = pageMap<Page>(this.props.pages, (page) => page).sort((a, b) => a.priority - b.priority)
      this.setState({ pages })
    }

    if (this.props.active !== prevProps.active) {
      const cursor = this.state.pages.findIndex((page) => page.id === this.props.active)
      this.setState({ cursor })
    }
  }

  handleActivate (id: ID): void {
    this.props.onActivate(id)
    if (isMobileMode()) this.setState({ collapsed: true })
  }

  async handleCollapseToggle (e: any) {
    const value = e.target.checked
    const collapsed = Boolean(value)
    this.setState({ collapsed })
    await this.preferencesManager.update({ name: SIDEBAR_MODE_PREF, value: collapsed })
  }

  render () : React.Node {
    const { active } = this.props
    const { pages, cursor, collapsed } = this.state
    return (
      <>
        <input type='checkbox' id='collapse' checked={collapsed} onChange={this.handleCollapseToggle} />
        <div role='region' className={cn('sidebar-region', { collapsed: pages.length === 0 || collapsed })} id='sidebar-region'>
          <div tabIndex={1} onKeyDown={this.handleKeyboard} className='sidebar-buttons'>{
            pages.map((page, index) => (
              <SidebarButton
                key={page.name}
                active={page.id === active}
                focused={index === cursor}
                onActivate={this.handleActivate}
                page={page}
              />
            ))
          }
          </div>
        </div>
      </>
    )
  }
}

export default SideBar
