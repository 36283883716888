import saveAs from 'file-saver'
import pick from 'lodash/pick'
import values from 'lodash/values'

import { replaceAll } from 'web-panel-essentials/misc'

// TODO: Tests!
function prepareCSVData (columns, filteredRows) {
  const columnNames = columns.filter((col) => col.visible !== false).map(({ name }) => name)
  const rows = filteredRows.map((record) => {
    record = pick(record, columnNames)
    const row = values(record).map((field) => {
      field = (field === 0) ? String(field) : field
      field = (field) ? String(field) : ''
      field = replaceAll(field, '\n', '¬')
      field = replaceAll(field, '\r', '')
      field = replaceAll(field, '\t', '|')
      field = replaceAll(field, '"', '""')
      return '"' + field + '"'
    }).join('\t')
    return row
  })

  const titles = columnNames.map(name => '"' + columns.find(column => column.name === name).title + '"')
  return { titles, rows }
}

function exportToCSV (titles, rows) {
  const blob = new window.Blob([
    titles.join('\t'),
    '\r\n',
    rows.join('\r\n')
  ], {
    type: 'text/plain;charset=utf-8'
  })
  saveAs(blob, 'ExportedData.tsv')
}

export { prepareCSVData, exportToCSV }
