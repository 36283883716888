/**
 * @file headerCell.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 14th April 2022 9:43:07 am
 * @copyright 2015 - 2022 SKAT LLC, Delive LLC
 * @flow strict
 */
/* global SyntheticEvent, HTMLDivElement  */
import type { asyncVoid } from 'web-panel/types'
import type { ColumnDefinition } from 'web-panel-essentials/types'

import * as React from 'react'
import cn from 'classnames'
import Draggable from 'react-draggable'
import { Icon } from 'web-panel/components'
import autoBind from 'react-autobind'

const SORTING_ICONS = {
  ASC: 'sort-amount-up',
  DESC: 'sort-amount-down'
}

type SortingMode = $Keys<typeof SORTING_ICONS>

type HeaderControlProps = {
  title: string,
  sortingMode: SortingMode,
  onClick: () => asyncVoid
}

class HeaderControl extends React.PureComponent<HeaderControlProps> {
  handleClick = () => this.props.onClick()

  render () : React.Node {
    const { sortingMode, title } = this.props
    const icon = SORTING_ICONS[sortingMode]

    return (
      <div className='controls' onClick={this.handleClick}>
        <span className='header-title'>{title}</span>
        {sortingMode ? <Icon className='icon' icon={icon} /> : null}
      </div>
    )
  }
}

type Props<T> = {
  sortingMode: SortingMode,
  style: {},
  column: ColumnDefinition<T>,
  columnIndex: number,
  active: boolean,
  onResize: (name: string, width: number) => asyncVoid,
  onClick: (column: ColumnDefinition<T>) => asyncVoid,
  onAdjustRequest: (columnIndex: number) => asyncVoid
}

class HeaderCell<T: {}> extends React.PureComponent<Props<T>> {
  constructor () {
    super()
    autoBind(this)
  }

  handleResize (_: void, data: {x: number}) : void {
    const { column } = this.props
    this.props.onResize(column.name, data.x)
  }

  handleClick () : void {
    this.props.onClick(this.props.column)
  }

  handleDoubleClick (event: SyntheticEvent<HTMLDivElement>) : boolean {
    const { columnIndex } = this.props
    event.stopPropagation()
    event.preventDefault()
    this.props.onAdjustRequest(columnIndex)
    return false
  }

  render () : React.Node {
    const { column, active, sortingMode, style } = this.props
    // $FlowFixMe[prop-missing]
    const title = String(column.alias || column.title || column.name)
    const className = cn('headerСell', { active })
    return (
      <div data-title={title} className={className} style={style}>
        <HeaderControl title={title} sortingMode={sortingMode} onClick={this.handleClick} />
        <Draggable
          axis='x'
          defaultClassName='drag-handle'
          defaultClassNameDragging='drag-handle-active'
          onStop={this.handleResize}
          position={{ x: 0, y: 0 }}
          zIndex={999}
        >
          <div className='adjust' onDoubleClick={this.handleDoubleClick} />
        </Draggable>
      </div>
    )
  }
}

export default HeaderCell
