// QC: PASSED
/**
 * @file WSTransport.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 26th July 2019 1:13:51 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow
 */

import type { iConnectionTransport, TCPConnectionTarget, iEventEmitter, EmitterEvents } from '../types'

import { Injectable } from '../serviceLocator'
import { ConfigurationError } from 'web-panel-essentials/errors'
import { __ } from 'web-panel/globals'

@Injectable('wsTransport')
class WSTransport implements iConnectionTransport, iEventEmitter {
  socket: any

  static get EVENT () : EmitterEvents {
    return {}
  }

  get EVENT () : EmitterEvents {
    return WSTransport.EVENT
  }

  get connected () : boolean {
    return this.socket && this.socket.readyState === 1 // 1-OPEN
  }

  connect (target?: TCPConnectionTarget) {
    if (!target) throw new ConfigurationError(__('NO_CONNECTION_TARGET'))
    if (this.socket) this.release()
    this.socket = new global.WebSocket(`ws${target.SSL ? 's' : ''}://${target.host}:${target.port}`)
    this.socket.binaryType = 'arraybuffer'
  }

  send (...args: Array<any>) {
    this.socket.send(...args)
  }

  on (...args: Array<any>) : void {
    return this.socket.addEventListener(...args)
  }

  emit (...args: Array<any>) : void {
    return this.socket.emit(...args)
  }

  once (eventName: string, callback: Function) : void {
    return this.socket.addEventListener(eventName, (...args) => {
      callback.apply(this.socket, args)
      this.socket.removeEventListener(eventName, callback)
    })
  }

  off (...args: Array<any>) : void {
    return this.socket.removeEventListener(...args)
  }

  release (reason: ?number) {
    this.socket.close(reason)
  }
}

export default WSTransport
