/**
 * @file ReportManager.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 3rd September 2021 8:57:19 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow
 */
import type { TemplateData, Blob, iReportGenerator, iReportManager } from '../../types'
import type { ID } from 'web-panel-essentials/types'

import { NotImplementedError } from 'web-panel-essentials/errors'
import { Injectable } from '../../serviceLocator'
import { __ } from '../../globals'
import TextReport from './TextReport'
import DocxReport from './DocxReport'
import saveAs from 'file-saver'
import { DocumentIsEmptyError } from '../../errors'

const REPORT_TYPE : { [string]: ID } = {
  TXT: Symbol('report'),
  DOCX: Symbol('report')
}

@Injectable('reportManager', true)
class ReportManager implements iReportManager {
  async generateFromRemoteTemplate (templateURL: string, payload: TemplateData, outputFilename: string) {
    const template : ArrayBuffer = await this.fetchTemplate(templateURL)
    await this.generateFromTemplate(template, payload, outputFilename)
  }

  async generateFromTemplate (template: ArrayBuffer, payload: TemplateData, outputFilename: string) : Promise<void> {
    if (!template.byteLength) throw new DocumentIsEmptyError(__('EMPTY_DOCUMENT_TEMPLATE'))

    const reportType = this.detectDocumentType(outputFilename)
    const generator : iReportGenerator = this.getGenerator(reportType)
    const data : Blob = await generator.generate(template, payload)
    this.saveAs(data, outputFilename)
  }

  getGenerator (type: $Values<typeof REPORT_TYPE>) : iReportGenerator {
    if (type === REPORT_TYPE.TXT) return new TextReport()
    if (type === REPORT_TYPE.DOCX) return new DocxReport()
    throw new NotImplementedError(__('UNABLE_TO_PROVIDE_REPORT_GENERATOR_FOR_CPECIFIED_TYPE'))
  }

  detectDocumentType (templateURL: string) : $Values<typeof REPORT_TYPE> {
    if (templateURL.endsWith('.txt')) return REPORT_TYPE.TXT
    if (templateURL.endsWith('.docx')) return REPORT_TYPE.DOCX
    throw new NotImplementedError(__('UNABLE_TO_DETECT_FILETYPE'))
  }

  async fetchTemplate (templateURL: string) : Promise<ArrayBuffer> {
    const request = new global.Request(templateURL)
    const response = await fetch(request, { responseType: 'arraybuffer' })
    const buffer = await response.arrayBuffer()
    return buffer
  }

  saveAs (data: Blob, fileName: string) : void {
    saveAs(data, fileName)
  }
}
export default ReportManager
