/**
 * @file Msg.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 15th April 2021 8:18:42 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow
 */
import type { iExposedView } from 'web-panel/types'

import marked from 'marked'
import * as React from 'react'
import { CAST } from 'web-panel-essentials/misc'
import { genTestName } from 'web-panel/globals'

class Msg extends React.Component<{text: string, testName?: string}> implements iExposedView<void> {
  async data () {}
  async command () {}

  render () : React.Node {
    return (
      <div
        className='full-padding pre-line'
        {...genTestName('body-window', this.props.testName)}
        dangerouslySetInnerHTML={{ __html: marked(CAST.String(this.props.text)) }}
      />
    )
  }
}

export default Msg
