/**
 * @file PageManager.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 17th November 2020 9:14:27 am
 * @copyright 2015 - 2020 SKAT LLC, Delive LLC
 * @flow
 */

import type { ID } from 'web-panel-essentials/types'
import type { iPageManager, iLayout, Page, iToolbarButton, ButtonChanges } from '../../types'
import * as React from 'react'

import { Inject, Injectable } from '../../serviceLocator'
import Layout from './Layout'

/**
 * Proxy binding around PagerView
 * Responsible for the connection between
 * tabs, sidebar and toolbar.
 */
@Injectable('pageManager', true)
class PageManager implements iPageManager {
  @Inject layout : iLayout
  pagesLayout: Promise<Layout>
  get ready () : Promise<void> {
    return Promise.resolve()
  }

  constructor () {
    this.pagesLayout = this.layout.renderView(<Layout />, 'layout-region')
  }

  /**
   * Add a new page. Toolbar and sidebar will be injected automatically
   * @method addPage
   * @param {Object} page - page object
   * @param {String} page.name - page name
   * @param {String} page.label - label for sidebar button
   * @param {String} page.title - title for sidebar button, appears on mouse hover
   * @param {String} page.icon - icon name from fontAwesome catalogue, e.g. 'cirle', 'bug'...
   * @param {Object[]} page.actions - list of toolbar actions for current page
   * @param {String[]} page.actions[].name - action name
   * @param {String[]} page.actions[].action - action callback
   * @example
   * this.addPage({
    *   name: 'myPage',
    *   label: __('MY_PAGE'),
    *   title: __('USE_MY_PAGE'),
    *   icon: 'square',
    *   view: (<div>BELLLA</div>)
    *   //TODO: Add callbacks
    * })
    */
  async add (page : Page) : Promise<void> {
    const layout = await this.pagesLayout
    await layout.addPage(page)
  }

  async addToolbarButtons (buttons: iToolbarButton[], pageId?: ID) {
    const layout = await this.pagesLayout

    if (pageId) await layout.addButtonsToPage(buttons, pageId)
    else await layout.addGlobalToolbarButtons(buttons)
  }

  async removeToolbarButtons (ids: ID[], pageId?: ID) {
    const layout = await this.pagesLayout

    if (pageId) await layout.removeButtonsFromPage(ids, pageId)
    else await layout.removeGlobalToolbarButtons(ids)
  }

  async updateButtons (buttons: ButtonChanges[], pageId: ID) {
    const layout = await this.pagesLayout
    if (pageId) await layout.updatePageButtons(buttons, pageId)
  }

  async highlight (id: ID) : Promise<void> {
    const layout = await this.pagesLayout
    await layout.highlightPage(id)
  }

  async activate (id: ID) : Promise<void> {
    const layout = await this.pagesLayout
    await layout.activatePage(id)
  }

  async findById (id: ID) : Promise<?Page> {
    const layout = await this.pagesLayout
    return layout.getPageById(id)
  }

  async getActive () : Promise<Page> {
    const layout = await this.pagesLayout
    return layout.getActivePage()
  }
}

export default PageManager
