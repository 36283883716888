/**
 * @file manageKeyboard.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 30th July 2019 12:39:11 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow
 */
import type { iToolbarButton, iStockExtension, iWindowManager, iPageManager, iShortcutsManager, iExposedView } from '../types'
import type { Shortcut } from '../views/forms/ShortcutEditor'
import * as React from 'react'

import { __, KEYBINDING_SCOPE, isMobileMode } from '../globals'
import ShortcutsListView from '../views/ShortcutsListView'
import ShortcutEditor from '../views/forms/ShortcutEditor'
import { Inject } from '../serviceLocator'

class ShortcutsExtension implements iStockExtension {
  @Inject windowManager : iWindowManager
  @Inject pageManager : iPageManager
  @Inject shortcutsManager : iShortcutsManager

  disableNativeHelpShortcut () : void {
    document.addEventListener('keydown', (e: any) => {
      if (e.code === 'F1') e.preventDefault()
    }, false)
  }

  async activate () : Promise<void> {
    this.disableNativeHelpShortcut()
    const invokeEditor = async ({ record, parent }) => {
      const id = Symbol('editor')
      const testName = 'shortcuts-editor-window'
      await this.windowManager.show({
        id,
        parent,
        testName,
        name: 'setHotkey',
        label: __('SET_HOTKEY'),
        title: __('SET_HOTKEY'),
        icon: 'edit',
        width: 38,
        widthUnit: 'rem',
        expandable: true,
        view: <ShortcutEditor testName={testName} title={record.title} name={record.name} />,
        buttons: [{
          name: 'cancel',
          label: __('CANCEL'),
          testName: 'shortcuts-editor-window-cancel-button',
          title: __('CANCEL_TOOLTIP'),
          action: async () => this.windowManager.close(id)
        }, {
          name: 'save',
          testName: 'shortcuts-editor-window-save-button',
          label: __('SAVE'),
          title: __('SAVE_TOOLTIP'),
          icon: 'save',
          isPrimary: true,
          action: async (editor: iExposedView<Shortcut>) => {
            const { name, value } = await editor.data()
            this.shortcutsManager.update({
              name,
              customShortcut: value
            })
            this.windowManager.close(id)
          }
        }]
      })
      this.windowManager.activate(id)
    }

    const invokeShortcutsWindow = async (button: iToolbarButton) => {
      const id = Symbol.for('shortcuts')
      const handleEdit = ({ record }) => invokeEditor({ record, parent: id })
      await this.windowManager.show({
        id,
        name: 'shortcuts',
        label: __('SHORTCUTS'),
        title: __('SHORTCUTS'),
        icon: 'keyboard',
        testName: 'shortcuts-window',
        width: 45,
        widthUnit: 'rem',
        extraView: <span className='full-left-margin muted small'>{__('SHORTCUTS_ARE_DYNAMIC')}</span>,
        view: <ShortcutsListView onEdit={handleEdit} />,
        expandable: true,
        buttons: [{
          name: 'close',
          testName: 'shortcuts-window-close-button',
          label: __('CLOSE'),
          title: __('CLOSE'),
          isPrimary: true,
          action: async () => this.windowManager.close(id)
        }]
      })

      this.windowManager.activate(id)
    }

    this.shortcutsManager.register({
      name: 'Shortcuts',
      title: __('SHOW_SHORTCUTS_WINDOW'),
      scope: [KEYBINDING_SCOPE.GLOBAL, null],
      defaultShortcut: 'ctrl+alt+s',
      action: invokeShortcutsWindow
    })

    if (!isMobileMode()) {
      await this.pageManager.ready
      this.pageManager.addToolbarButtons([{
        id: Symbol('shortcuts'),
        name: 'shortcuts',
        label: __('SHORTCUTS'),
        title: __('SHORTCUTS'),
        icon: 'keyboard',
        testName: 'show-shortcuts-form-button',
        action: invokeShortcutsWindow
      }])
    }
  }
}

export default ShortcutsExtension
