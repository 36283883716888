/**
 * @file Button.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 29th January 2021 6:32:18 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow
 */
import type { ID } from 'web-panel-essentials/types'
import type { Page } from '../../types'

import * as React from 'react'
import cn from 'classnames'
import autoBind from 'react-autobind'
import { genTestName } from 'web-panel/globals'
import { Icon } from 'web-panel/components'

type Props = {
  page: Page,
  active: boolean,
  focused: boolean,
  onActivate: (id: ID) => void
}

class SidebarButton extends React.PureComponent<Props> {
  constructor () {
    super()
    autoBind(this)
  }

  handleActivate () : void {
    this.props.onActivate(this.props.page.id)
  }

  render () : React.Node {
    const { page, active, focused } = this.props
    const { label, title, icon, testName, highlighted, disabled } = page

    const className = cn('sidebar-button', {
      active,
      focused,
      highlighted,
      disabled
      // spinner: isPending,
    })

    return (
      <div {...genTestName(testName)} data-title={title} className={className} onClick={this.handleActivate}>
        <div className='inner'>
          <Icon className='icon' icon={icon} />
          <span className='sidebar-label'>{label}</span>
        </div>
      </div>
    )
  }
}

export default SidebarButton
