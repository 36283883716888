/**
 * @file ColumnDefinitionProvider.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 2nd July 2020 11:06:10 am
 * @copyright 2015 - 2020 SKAT LLC, Delive LLC
 */
import type { iDataProvider, ExtendedColumnDefinition, ProviderMeta, WaterlineQuery } from 'web-panel/types'

import { __ } from 'web-panel/globals'
import { FORMAT } from 'web-panel-essentials/misc'
import { DataProvider } from 'web-panel/connections/DataProvider'
import iconFlagCell from 'web-panel/views/grid/iconFlagCell'

const humanReadableBoolean = (v) => v ? __('YES') : __('NO')

class ColumnDefinitionProvider extends DataProvider implements iDataProvider<ExtendedColumnDefinition> {
  payload: Object

  constructor (columns: Object, initialFilter?: WaterlineQuery) {
    super()
    const select : string[] = initialFilter ? initialFilter.select : []

    const isVisible = (column: ExtendedColumnDefinition) : boolean => {
      if (column.visibilityOverwrited) return column.visible !== false
      if (select?.length) return select.includes(column.name) && column.visible !== false
      return column.visible !== false
    }

    this._payload = columns
      .map((column, index) => ({
        ...column,
        position: index,
        id: column.name,
        title: column.title || column.name,
        visible: isVisible(column),
        visibilityOverwrited: column.visibilityOverwrited,
        alias: column.alias
      }))
  }

  set (payload) {
    this._payload = payload
  }

  async meta () : Promise<ProviderMeta> {
    return {
      value: 'id',
      displayValue: 'key',
      columns: [
        { name: 'title', type: 'String', format: FORMAT.String, column: '', title: __('COLUMN_TITLE'), visible: true, width: 150 },
        { name: 'position', type: 'String', format: FORMAT.String, column: '', title: 'position', visible: false },
        { name: 'id', type: 'String', format: FORMAT.String, column: '', title: 'id', visible: false },
        { name: 'name', type: 'String', format: FORMAT.String, column: '', title: 'name', visible: false },
        {
          name: 'visible',
          type: 'Boolean',
          format: humanReadableBoolean,
          column: '',
          title: __('COLUMN_VISIBLE'),
          visible: true,
          width: 60,
          cellType: iconFlagCell.bind({
            icon: 'check-square',
            reverseIcon: 'minus',
            referenceField: 'visible'
          })
        },
        { name: 'width', type: 'Number', format: FORMAT.Number, column: '', title: __('COLUMN_WIDTH'), visible: true, width: 75 },
        { name: 'alias', type: 'String', format: FORMAT.String, column: '', title: __('COLUMN_ALTER_NAME'), visible: true, width: 150 }
      ]
    }
  }
}

export default ColumnDefinitionProvider
