/**
 * @file TooltipManager.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 1st February 2022 12:49:48 pm
 * @copyright 2015 - 2022 SKAT LLC, Delive LLC
 * @flow strict
 */
/* global TimeoutID, MouseEvent, HTMLElement */
import type { asyncVoid, iLogger, CancellablePromiseWrapper, iTooltipManager } from '../../types'

import * as React from 'react'
import { Injectable, Inject } from '../../serviceLocator'
import Delegate from '../../utils/Delegate'
import { Icon } from '../../components'
import { __, makeCancelable, isMobileMode } from '../../globals'
import cn from 'classnames'
import throttle from 'lodash/throttle'

type Handler = () => asyncVoid

function Spinner () : React.Node {
  return (
    <>
      <Icon className='tip-icon' icon='info-circle' />
      <b className='resolving'>{__('LOADING')}</b>
    </>
  )
}

const DATA_KEY = {
  TIP_ID: 'tooltipId',
  TIP_SIMPLE: 'title',
  TIP_PAYLOAD: 'tooltipPayload'
}

const LOOKUP_LEVEL = 5
const TRESHOLD = 10

class Tooltips extends React.PureComponent<{}, {content: ?React.Node}> implements iTooltipManager {
  @Inject logger : iLogger

  timer: TimeoutID
  spinnerTimer: TimeoutID
  position: [number, number]
  wincenter: [number, number]
  handlers: {[string]: Handler}
  pendingTask: CancellablePromiseWrapper<React.Node>
  ready: Promise<void>

  constructor () {
    super()
    this.position = [0, 0]
    this.wincenter = [0, 0]
    this.handlers = {}
    this.state = {
      content: null
    }
  }

  register (id: string, handler: Handler) {
    this.handlers[id] = handler
  }

  componentDidMount () {
    if (isMobileMode()) return

    window.onkeydown = throttle((event) => {
      this.reset()
    }, 500)

    window.onmousedown = throttle((event) => {
      this.position = [event.clientX, event.clientY]
      this.reset()
    }, 50)

    window.onmousemove = throttle((event) => {
      this.wincenter = [window.innerWidth / 2, window.innerHeight / 2]
      if ((Math.abs(this.position[0] - event.clientX) > TRESHOLD) || (Math.abs(this.position[1] - event.clientY) > TRESHOLD)) {
        this.reset()
        this.timer = setTimeout(() => this.lookup(event), 500)
        this.position = [event.clientX, event.clientY]
      }
    }, 50)
  }

  reset () {
    clearTimeout(this.timer)
    if (this.state.content) this.setState({ content: null })
    if (this.pendingTask) this.pendingTask.cancel()
  }

  async lookup (event: MouseEvent) {
    let element = event.target
    let deep = 0

    while (deep < LOOKUP_LEVEL) {
      if (element instanceof HTMLElement || element instanceof global.SVGElement) {
        const dataset = element.dataset
        if (dataset && dataset[DATA_KEY.TIP_SIMPLE]) {
          this.setState({ content: dataset[DATA_KEY.TIP_SIMPLE] })
          break
        }

        if (dataset && dataset[DATA_KEY.TIP_ID]) {
          const [id, payload] = [dataset[DATA_KEY.TIP_ID], dataset[DATA_KEY.TIP_PAYLOAD]]
          if (id && payload) {
            if (!this.handlers[id]) break

            this.spinnerTimer = setTimeout(() => this.setState({ content: Spinner() }), 350)
            try {
              // $FlowFixMe[incompatible-call]
              // $FlowFixMe[extra-arg]
              this.pendingTask = makeCancelable<React.Node>(this.handlers[id](payload))
              const content = await this.pendingTask.promise
              clearTimeout(this.spinnerTimer)
              this.setState({ content })
            } catch (error) {
              this.logger.error(error)
            } finally {
              clearTimeout(this.spinnerTimer)
            }
            break
          }
        }
        element = element.parentNode
      }

      deep += 1
    }
  }

  render () : React.Node {
    const [x, y] = this.position
    const [cx, cy] = this.wincenter

    let className = 'managed-tooltip'
    if (y > cy && x > cx) className = cn(className, 'in-right-bottom')
    else if (x > cx) className = cn(className, 'in-right')
    else if (y > cy) className = cn(className, 'in-bottom')

    if (!this.state.content) return null
    return (
      <div className={className} style={{ left: x, top: y }}>
        {this.state.content}
      </div>
    )
  }
}

/**
 * Coo
 * @alias injectable:notificationManager
 * @class NotificationManager
 * @implements iNotificationManager
 */
@Injectable('tooltipManager', true)
class TooltipManager extends Delegate {
  @Inject layout: *
  ready: Promise<void>

  constructor () {
    super()
    this.ready = new Promise(async (resolve) => { //eslint-disable-line
      const view = await this.layout.renderView(<Tooltips />, 'tooltips-region')
      this.becomeProxyOf(view)
      resolve()
    })
  }
}

export default TooltipManager
