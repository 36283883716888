/**
 * @fileOverview hookResolver.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Monday, 8th July 2019 11:01:40 am
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow
 */

import type { iWebPanelHook, HookDependency, iLogger, ProvidableFeatures } from '../types'

import { Inject, Injectable } from '../serviceLocator'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import { __, __mf } from '../globals'
import { ConfigurationError } from 'web-panel-essentials/errors'
import { UnresolvedDependencyError } from '../errors'

type HooksAndFeatures = {
  enabledHooks: Array<string>,
  defaultFeatures: {[string]: Promise<any>}
}
type constructCallback = (hooks: iWebPanelHook[], features: ProvidableFeatures) => void

/**
 * @class HookResolver
 * @ignore
 */
@Injectable('hookResolver', true)
class HookResolver {
  @Inject logger : iLogger

  constructor () {
    this.logger.namespace = 'hook-resolver'
  }

  importHook (hookName: string) : any {
    this.logger.info('Importing hook module', hookName)
    return import(`../../../api/hooks/${hookName}/assets/js/index`)
  }

  async resolve ({ enabledHooks, defaultFeatures }: HooksAndFeatures, onConstruct: constructCallback) : Promise<void> {
    if (!enabledHooks.length) throw new ConfigurationError(__('AT_LEAST_ONE_HOOK_MUST_BE_ENABLED_FOR_PRESET'))

    const instances = []
    const libraries = []
    let features = defaultFeatures

    for (const hookName of enabledHooks) {
      let name = hookName

      if (hookName.includes(':')) {
        const [prefix, rawName] = hookName.split(':')
        name = rawName.trim()
        if (prefix === 'lib') libraries.push(name)
      }

      const hookModule = await this.importHook(name)

      const WebPanelHook = hookModule.default
      const hook : iWebPanelHook = new WebPanelHook()

      if (hook.meta.NAME !== name) throw new ConfigurationError([__('MODULE_NAME_MISMATCH'), hookName, hook.meta.NAME].join(' '))
      features = { ...features, ...hook.meta.PROVIDES }
      instances.push(hook)
    }

    onConstruct(instances, features)

    const splitter = '\n\t├─ '
    this.logger.info(`Enabled hooks:${splitter}${instances.map((i) => i.meta.NAME).join(splitter)}`)
    this.logger.info(`Registered features:${splitter}${Object.keys(features).join(splitter)}`)

    await Promise.all(instances.map(async (hook) => {
      const dependencies = hook.meta.DEPENDS || []
      const resolvedDependencies = {}

      dependencies.forEach((dependency: HookDependency) => {
        if (!features[dependency.name] && (dependency.required === true || isNil(dependency.required))) {
          const errorMessage = __mf('MISSING_DEPENDENCY', {
            hookName: hook.meta.NAME,
            dependencyName: dependency.name
          })
          this.logger.error(errorMessage)
          throw new UnresolvedDependencyError(errorMessage)
        }
        resolvedDependencies[dependency.name] = features[dependency.name]
      })

      const name = hook.meta.NAME
      if (isFunction(hook.activate) && isFunction(hook.init)) {
        this.logger.info('Initializing hook', name, resolvedDependencies)
        await hook.init(resolvedDependencies)

        if (!libraries.includes(name)) {
          this.logger.info('Activating hook', name, resolvedDependencies)
          await hook.activate(resolvedDependencies)
          this.logger.info('Hook', name, 'activated')
        } else this.logger.info('Hook', name, 'running in "library" mode')

      } else this.logger.warn('Hook', name, 'has no "activate" or "initialize" function. Will be ignored')
      return null
    })
    )
  }
}

export default HookResolver
