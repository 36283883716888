/**
 * @file Autocomplete.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 11th July 2019 1:46:18 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow strict
 */
/* global SyntheticKeyboardEvent, SyntheticEvent, HTMLInputElement, HTMLElement */

import type { CombinedValue, Entity, ReactRef, CancellablePromiseWrapper } from '../types'
import type { AutocompleteProps, _fieldNaming } from './types'
import type { WaterlineQuery } from 'web-panel-essentials/types'

import * as React from 'react'
import autoBind from 'react-autobind'
import debounce from 'lodash/debounce'

import { CAST } from 'web-panel-essentials/misc'

import Field from './Field'
import DropdownMenu from './DropdownMenu'
import injectDataProvider from './HOCs/injectDataProvider'
import { getCurrentLocale, makeCancelable } from '../globals'
import { OperationCancelled } from 'web-panel-essentials/errors'
import isNil from 'lodash/isNil'
import classnames from 'classnames'

type Props<T> = {|
  ...AutocompleteProps<T>,
  ..._fieldNaming<T>
|}

type State<T> = {|
  items: T[],
  focusedItemIndex: number
|}

class AutocompleteField<T:Entity> extends React.PureComponent<Props<T>, State<T>> {
  lastTerm: string
  field: ReactRef<typeof Field>
  dropDown: ReactRef<typeof DropdownMenu>
  activeResolver: ?CancellablePromiseWrapper<T[]>

  constructor (props: Props<T>) {
    super(props)
    autoBind(this)
    this.field = React.createRef()
    this.dropDown = React.createRef()

    this.state = {
      items: [],
      focusedItemIndex: 0
    }
  }

  focus () {
    this.field.current?.focus()
  }

  handleFieldChange ({ displayValue }: CombinedValue) : void {
    this.props.onChange({ value: undefined, displayValue })
    if (displayValue !== this.lastTerm) {
      this.lastTerm = displayValue
      this.enqueueResolvingTask(this.lastTerm)
    }
  }

  enqueueResolvingTask : (term: string) => Promise<void> = debounce((term: string) => {
    if (this.activeResolver) this.activeResolver.cancel()
    const promise = this.resolveItems(term)
    this.activeResolver = makeCancelable(promise)
    this.activeResolver.promise
      .then((items) => {
        if (this.field.current?.state.focused) this.setState({ items }) // Maybe better to use local focus state?
      })
      .catch(error => {
        if (!(error instanceof OperationCancelled)) console.error(error)
      })
  }, 300)

  async resolveItems (term: string) : Promise<T[]> {
    const { displayValueKey, valueKey, filter = {}, dataProvider } = this.props
    if (!term) return []

    const query : WaterlineQuery = {
      where: filter.where
        ? {
            and: [
              // $FlowFixMe
              { [displayValueKey]: { ilike: term } },
              filter.where
            ]
          }
          // $FlowFixMe
        : { [displayValueKey]: { ilike: term } },
      select: [valueKey, displayValueKey],
      limit: 20
    }

    const items = await dataProvider.get(query)
    return items
  }

  handleBlur (event: SyntheticEvent<HTMLElement>) {
    this.resetState()
    if (this.props.onBlur) this.props.onBlur(event)
  }

  handleOutClick (event: SyntheticEvent<HTMLElement>) {
    this.handleBlur(event)
  }

  componentDidUpdate () : void {
    const suggestion = this.getActiveSuggestion()
    if (suggestion && suggestion.displayValue.length) {
      this.field.current?.setSelectionRange(CAST.String(this.props.displayValue).length, suggestion.displayValue.length)
    }
  }

  getActiveSuggestion () : CombinedValue | null {
    const { valueKey, displayValueKey } = this.props
    const { items, focusedItemIndex } = this.state

    const focusedItem = items[focusedItemIndex]
    if (focusedItem) {
      const term = CAST.String(this.props.displayValue).toLocaleLowerCase(getCurrentLocale())
      const displayValue = CAST.String(focusedItem[displayValueKey])
      const lowered = displayValue.toLocaleLowerCase(getCurrentLocale())
      if (lowered && lowered.startsWith(term)) { // && lowered !== term
        return {
          displayValue,
          value: focusedItem[valueKey]
        }
      }
    }
    return null
  }

  handleTabKey (event: SyntheticKeyboardEvent<HTMLInputElement>) : void {
    if (this.state.items.length) {
      const suggestion = this.getActiveSuggestion()
      if (suggestion) this.props.onChange(suggestion)
      this.resetState()
    }
    if (this.props.onTabKey) this.props.onTabKey(event)
  }

  handleDownKey (event: SyntheticKeyboardEvent<HTMLInputElement>) : void {
    let { items, focusedItemIndex } = this.state
    focusedItemIndex++
    focusedItemIndex = Math.min(focusedItemIndex, items.length - 1)
    this.setState({ focusedItemIndex })
    event.preventDefault()
  }

  handleUpKey (event: SyntheticKeyboardEvent<HTMLInputElement>) : void {
    let { focusedItemIndex } = this.state
    focusedItemIndex--
    focusedItemIndex = Math.max(focusedItemIndex, 0)
    this.setState({ focusedItemIndex })
    event.preventDefault()
  }

  handleEraceKey (event: SyntheticKeyboardEvent<HTMLInputElement>): void {
    this.resetState()
  }

  handleEscapeKey (event: SyntheticKeyboardEvent<HTMLInputElement>) : void {
    if (this.state.items.length) {
      // Preventing modal window unexpected close with one shot
      event.stopPropagation()
    }

    this.resetState()
    event.preventDefault()
  }

  resetState () : void {
    if (this.activeResolver) this.activeResolver.cancel()
    if (this.state.items.length) {
      this.setState({ items: [], focusedItemIndex: 0 })
    }
  }

  handleEnterKey (event: SyntheticKeyboardEvent<HTMLInputElement>) : void {
    const { items, focusedItemIndex } = this.state
    const { displayValueKey, valueKey } = this.props
    const item = items[focusedItemIndex]
    if (item) {
      this.handleActivateMenuItem({ value: item[valueKey], displayValue: CAST.String(item[displayValueKey]) })
      event.preventDefault()
      event.stopPropagation()
    }
  }

  handleActivateMenuItem ({ value, displayValue }: CombinedValue) : void {
    this.props.onChange({ value, displayValue })
    this.resetState()
    const cursor = displayValue.length

    this.field.current?.preserveCursor(0, cursor)
    this.field.current?.setSelectionRange(0, cursor)
  }

  getPositionTuneClasses () : ?string {
    const container = this.field.current?.container.current
    if (container) {
      const [cx, cy] = [window.innerWidth / 2, window.innerHeight / 2]
      const { top, left } = container.getBoundingClientRect()

      if (top > cy && left > cx) return 'in-right-bottom'
      else if (left > cx) return 'in-right'
      else if (top > cy) return 'in-bottom'
    }
    return null
  }

  render () : React.Node {
    const { items, focusedItemIndex } = this.state
    let {
      value, displayValue, valueKey, displayValueKey, onChange, onClick,
      readOnly, containerClassName, noResetControl, catchFocusOnMount, disabled,
      children = null, onFocus, placeholder, invalid, inputClassName, tabIndex, testName,
      onKeyDown, onKeyPress, title, valuesBasedBlur
    } = this.props

    inputClassName = classnames(inputClassName, { resolved: !isNil(value) })

    const fieldProps = {
      value,
      displayValue,
      catchFocusOnMount,
      valuesBasedBlur,
      onChange,
      onClick,
      disabled,
      readOnly,
      containerClassName,
      noResetControl,
      onFocus,
      placeholder,
      invalid,
      inputClassName,
      tabIndex,
      testName,
      onKeyDown,
      onKeyPress,
      title
    }

    const dropDownClass = this.getPositionTuneClasses()
    const dropDownProps = { items, focusedItemIndex, valueKey, displayValueKey, disabled, onChange, className: dropDownClass }
    const suggestion = this.getActiveSuggestion()
    displayValue = suggestion ? suggestion.displayValue : displayValue

    return (
      <>

        <Field
          {...fieldProps}
          ref={this.field}
          onBlur={this.handleBlur}
          displayValue={displayValue}
          onChange={this.handleFieldChange}
          onDownKey={this.handleDownKey}
          onUpKey={this.handleUpKey}
          onEnterKey={this.handleEnterKey}
          onEraceKey={this.handleEraceKey}
          onEscapeKey={this.handleEscapeKey}
          onTabKey={this.handleTabKey}
        >
          <DropdownMenu
            {...dropDownProps}
            ref={this.dropDown}
            onOutClick={this.handleOutClick}
            onChange={this.handleActivateMenuItem}
          />
          {children}
        </Field>
      </>
    )
  }
}

const withDataProviderInjected: React.AbstractComponent<AutocompleteProps<Entity>> = injectDataProvider(AutocompleteField)

export { AutocompleteField }
export default withDataProviderInjected
