/**
 * @fileOverview About.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Monday, 8th July 2019 5:27:56 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow
 */
import type { iStockExtension, iWindowManager, iPageManager, iShortcutsManager, iPreferencesManager, iApplication } from '../types'

import { __, KEYBINDING_SCOPE } from '../globals'
import About from '../views/About'
import Hooks from '../views/Hooks'

import * as React from 'react'
import { Inject } from '../serviceLocator'

class AboutExtension implements iStockExtension {
  @Inject windowManager : iWindowManager
  @Inject pageManager : iPageManager
  @Inject shortcutsManager : iShortcutsManager
  @Inject preferencesManager : iPreferencesManager
  @Inject remoteDataCache : { clear: () => Promise<void> }

  application: iApplication
  aboutWindowId: symbol

  async activate (application: iApplication) : Promise<void> {
    this.application = application
    this.aboutWindowId = Symbol('id')

    this.shortcutsManager.register({
      name: 'About',
      title: __('SHOW_ABOUT_WINDOW'),
      scope: [KEYBINDING_SCOPE.GLOBAL, null],
      defaultShortcut: 'ctrl+f1',
      action: this.invokeAboutWindow
    })

    await this.pageManager.addToolbarButtons([{
      id: Symbol('about'),
      name: 'about',
      testName: 'show-about-form-button',
      label: __('ABOUT'),
      title: __('ABOUT'),
      icon: 'info-circle',
      action: this.invokeAboutWindow
    }])
  }

  invokeAboutWindow : Function = async () => {
    await this.windowManager.show({
      id: this.aboutWindowId,
      name: 'about',
      label: __('ABOUT'),
      title: __('ABOUT'),
      icon: 'info-circle',
      testName: 'about-window',
      className: 'about-window',
      width: 38,
      widthUnit: 'rem',
      view: <About />,
      buttons: [{
        name: 'configuration',
        label: __('RESET_USER_PREFS'),
        title: __('RESET_USER_PREFS'),
        action: async (view) => {
          this.preferencesManager.reset()
          this.remoteDataCache.clear()
          this.windowManager.close(this.aboutWindowId)
        }
      }, {
        name: 'modules',
        label: __('ACTIVE_MODULE_LIST'),
        title: __('ACTIVE_MODULE_LIST'),
        action: async () => {
          const id = Symbol('id')
          await this.windowManager.show({
            id,
            expandable: true,
            label: __('ACTIVE_MODULE_LIST'),
            title: __('ACTIVE_MODULE_LIST'),
            icon: 'info-circle',
            testName: 'hooks-window',
            className: 'hooks-window',
            width: 38,
            widthUnit: 'rem',
            view: <Hooks hooks={this.application.hooks} />,
            buttons: [{
              name: 'close',
              label: __('CLOSE'),
              title: __('CLOSE_TOOLTIP'),
              isPrimary: true,
              action: async () => this.windowManager.close(id)
            }]
          })
          await this.windowManager.activate(id)
        }
      }, {
        testName: 'about-window-close-button',
        name: 'close',
        label: __('CLOSE'),
        title: __('CLOSE_TOOLTIP'),
        isPrimary: true,
        action: async () => this.windowManager.close(this.aboutWindowId)
      }]
    })
    this.windowManager.activate(this.aboutWindowId)
  }
}

export default AboutExtension
