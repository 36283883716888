/**
 * @file GlobalEventBus.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 30th July 2019 12:47:24 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow
 */
import type { iEventEmitter, iGlobalEventBus, EmitterEvents } from 'web-panel/types'

import { Injectable } from '../serviceLocator'
import EventBus from './EventBus'
import isString from 'lodash/isString'
import isObject from 'lodash/isObject'
import { DuplicateKeyError } from '../errors'
import { NotFoundError, TypeError } from 'web-panel-essentials/errors'

import { __ } from '../globals'

const EVENTS_PROPERTY = Symbol('events')
const store = new Set()
@Injectable('globalEventBus', true)
class GlobalEventBus extends EventBus implements iEventEmitter, iGlobalEventBus {
  // $FlowFixMe[signature-verification-failure]
  // $FlowFixMe[unsupported-syntax]
  static get [EVENTS_PROPERTY] () {
    return store
  }

  static get EVENT () : EmitterEvents {
    // $FlowFixMe[invalid-computed-prop]
    return [...GlobalEventBus[EVENTS_PROPERTY]]
  }

  get EVENT () : EmitterEvents {
    return GlobalEventBus.EVENT
  }

  set EVENT (value: any) : void {}

  /**
   * События должны быть уникальными, чтобы не было
   * ненужных пересечений. Поэтому шина требует
   * предварительной регистрации события, и имеет право
   * отшить дубликат
   */
  registerEvent (event: string) : void {
    if (!isString(event)) throw new TypeError(__('EVENT_NAME_MUST_BE_A_STRING'))
    if (!(GlobalEventBus:any)[EVENTS_PROPERTY].has(event)) (GlobalEventBus:any)[EVENTS_PROPERTY].add(event)
    else throw new DuplicateKeyError(__('EVENT_HAS_BEEN_ALREADY_REGISTERED'))
  }

  unregisterEvent (event: string) : void {
    (GlobalEventBus:any)[EVENTS_PROPERTY].delete(event)
  }

  eventRegistered (event: string) : boolean {
    return Boolean((GlobalEventBus:any)[EVENTS_PROPERTY].has(event))
  }

  emit (event: string, payload: {[string]: any}) : boolean {
    if (!isObject(payload)) throw new TypeError(__('EVENT_PAYLOAD_OBJECT_MISSING'))
    if ((GlobalEventBus:any)[EVENTS_PROPERTY].has(event)) return super.emit(event, payload)
    else throw new NotFoundError(__('EVENT_IS_NOT_REGISTERED'))
  }
}

export default GlobalEventBus
