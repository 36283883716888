/**
 * @file Prompt.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 28th January 2020 12:35:43 pm
 * @copyright 2015 - 2020 SKAT LLC, Delive LLC
 * @flow
 */
import type { iExposedView } from 'web-panel/types'

import * as React from 'react'
import { CAST } from 'web-panel-essentials/misc'
import autoBind from 'react-autobind'
import { genTestName } from 'web-panel/globals'
import { RunOnNextTick } from 'web-panel-essentials/decorators'

export type Props = {
  type?: 'text' | 'password',
  message?: string,
  value: string
}

type State = {
  value: string
}

class Prompt extends React.Component<Props, State> implements iExposedView<string> {
  input: React.ElementRef<any>
  constructor (props: Props) {
    super(props)
    autoBind(this)
    this.input = React.createRef()
    this.state = {
      value: props.value
    }
  }

  componentDidMount () {
    this.input.current.focus()
  }

  @RunOnNextTick()
  activate () {
    this.input.current.focus()
  }

  handleValueChange ({ target }: any) : void {
    this.setState({ value: target.value })
  }

  async data () : Promise<string> {
    return CAST.String(this.state.value)
  }

  async command () {

  }

  render () : React.Node {
    return (
      <div className='full-padding'>
        {this.props.message ? (<p {...genTestName('message-prompt')}>{this.props.message}</p>) : null}
        <input
          ref={this.input}
          className='full-width mousetrap'
          type={this.props.type || 'text'}
          value={this.state.value}
          onChange={this.handleValueChange}
          {...genTestName('input-prompt')}
        />
      </div>
    )
  }
}

export default Prompt
