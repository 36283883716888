/**
 * @file Action.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 19th October 2021 8:54:01 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow strict
 */
import * as React from 'react'
import Icon from './Icon'
import classnames from 'classnames'

type Props = {|
  icon: string,
  onClick: () => void | Promise<void>,
  disabled?: boolean,
  className?: string,
  title?: string,
|}

export default function Action ({ icon, onClick, disabled, title, className } : Props) : React.Node {
  const handleClick = () => {
    if (!disabled) onClick()
  }

  const classname = classnames('field-button', { disabled }, className)
  return (
    <div data-title={title} className={classname} onClick={handleClick}>
      <Icon tabIndex={-1} className='icon' icon={icon} />
    </div>
  )
}
