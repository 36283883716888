/**
 * @file Tag.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 17th December 2021 9:18:08 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { TagProps } from './types'
import * as React from 'react'

class Tag extends React.PureComponent<TagProps> {
  render () : React.Node {
    const { fgColor, bgColor, children, title } = this.props
    const style = { color: fgColor, backgroundColor: bgColor }
    return (
      <div data-title={title} className='tag' style={style}>
        {children}
      </div>
    )
  }
}

export default Tag
