/**
 * @file EventEmitter.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 31st May 2022 9:36:19 am
 * @copyright 2015 - 2022 SKAT LLC, Delive LLC
 * @flow
 */
import type { iEventEmitter, EmitterEvents } from 'web-panel/types'
import BaseEmitter from 'events'

class EventEmitter extends BaseEmitter implements iEventEmitter {
  get EVENT () : EmitterEvents {
    return {}
  }

  // This alias was made because of broken polifylls
  off (eventName: string, handler: Function) : this {
    this.removeListener(eventName, handler)
    return this
  }
}

export default EventEmitter
