/**
 * @file Hooks.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Wednesday, 7th April 2021 10:04:37 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow
 */
import type { iWebPanelHook, iExposedView } from '../types'

import * as React from 'react'

export type Props = {
  hooks: iWebPanelHook[]
}

class Hooks extends React.PureComponent<Props> implements iExposedView<void> {
  async data () {
  }

  async command () {
  }

  render () : React.Node {
    const { hooks = [] } = this.props

    return (
      <div className='h400px'>
        <div className='web-hooks'>
          {hooks.map((hook, index) => {
            return (
              <div className='hook' key={index}>
                <span className='name'>{hook.meta.NAME}</span>
                <span className='description'>{hook.meta.DESCRIPTION}</span>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default Hooks
