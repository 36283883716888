/**
 * @file FieldIcon.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 22nd October 2019 4:16:44 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow strict
 */
/* global SyntheticEvent, HTMLElement */
import type { asyncVoid } from 'web-panel/types'

import * as React from 'react'
import cn from 'classnames'
import { Icon } from 'web-panel/components'
export type Props = {
  icon: string,
  className?: string,
  active?: boolean,
  title?: string,
  disabled?: boolean,
  testName?: string,
  onClick?: (event: SyntheticEvent<HTMLElement>) => asyncVoid,
  onDoubleClick?: (event: SyntheticEvent<HTMLElement>) => asyncVoid,
}

const FieldIcon = ({ icon, active, disabled, className, onClick, onDoubleClick, title, testName } : Props) : React.Node => {
  const handleClick = (event: SyntheticEvent<HTMLElement>) => {
    event.stopPropagation()
    if (disabled) return
    if (onClick) onClick(event)
  }

  const handleDoubleClick = (event: SyntheticEvent<HTMLElement>) => {
    event.stopPropagation()
    if (disabled) return
    if (onDoubleClick) onDoubleClick(event)
  }

  const containerClassName = cn('field-button', className, { disabled, active })
  return (
    <Icon
      title={title}
      containerClassName={containerClassName}
      icon={icon}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      testName={testName}
    />
  )
}

export default FieldIcon
