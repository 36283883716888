/**
 * @file Col.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Wednesday, 7th October 2020 8:09:29 am
 * @copyright 2015 - 2020 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { ColProps } from './types'

import * as React from 'react'
import cn from 'classnames'
import { genTestName } from 'web-panel/globals'

const Col = (props: ColProps) : React.Node => {
  let { className, size, noMargin, noPadding, children, style, testName } = props
  className = cn('col', `col-${size}`, className, {
    'no-margin': noMargin,
    'no-padding': noPadding
  })

  return (<div className={className} style={style} {...genTestName(testName)}>{children}</div>)
}

export default Col
