/**
 * @file Delegate.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Monday, 18th February 2019 6:52:43 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow
 */

class Delegate {
  becomeProxyOf (origin: Function) {
    for (const methodName of this._getInstanceMethodNames(origin)) {
      if (!(this:any)[methodName]) (this:any)[methodName] = origin[methodName].bind(origin)
    }
  }

  _getInstanceMethodNames (obj: Function) : Array<Function> {
    const proto = Object.getPrototypeOf(obj)
    const names = Object.getOwnPropertyNames(proto)
    return (names.filter((name: string) => typeof obj[name] === 'function') : Array<Function>)
  }
}

export default Delegate
