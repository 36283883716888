/**
 * @file Route.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Wednesday, 6th July 2022 6:32:40 am
 * @copyright 2015 - 2022 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { Point } from 'web-panel-essentials/types'
import type { RouteViewProps, Bounds } from 'web-panel/components/types'
import type { Node } from 'react'

import React from 'react'
import simplify from 'simplify-js'

type ReducablePoint = {|
  x: number,
  y: number
|}

const ABSOLUTE_TOP_LEFT = { position: 'absolute', top: 0, left: 0 }

function intersects (line1, line2) : boolean {
  let gamma, lambda

  const [[a, b], [c, d]] = line1
  const [[p, q], [r, s]] = line2
  const det = (c - a) * (s - q) - (r - p) * (d - b)
  if (det === 0) return false
  else {
    lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det
    gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det
    return (lambda > 0 && lambda < 1) && (gamma > 0 && gamma < 1)
  }
}

function cropRouteByBounds (points: Point[], bounds: Bounds) : ReducablePoint[] {
  const route = []

  const { n, e, s, w } = bounds
  const xInside = (x) => x > w && x < e
  const yInside = (y) => y > s && y < n

  const lineInsideBounds = ([[y1, x1], [y2, x2]]) => {
    if (xInside(x1) || xInside(x2)) return true
    if (yInside(y1) || yInside(y2)) return true
    return false
  }

  const lineIntersectAnyBound = (line) => {
    return (
      intersects(line, [[w, s], [w, n]]) ||
      intersects(line, [[w, n], [e, n]]) ||
      intersects(line, [[e, n], [e, s]]) ||
      intersects(line, [[e, s], [w, s]])
    )
  }

  // Бежим по отрезкам, и если отрезок входит в область отрисовки или пересекает
  // её границы, то добавляем точки отрезка в маршрут, иначе отбрасываем
  // Попутно избавляемся от точек дубликатов и трансформируем формат данных
  // чтобы избежать дополнительных итерации в simplifyToZoom
  for (let i = 1; i < points.length - 1; i += 1) {
    const line = points.slice(i - 1, i + 1)
    const [start, end] = line
    if (lineInsideBounds(line) || lineIntersectAnyBound(line)) {
      const lastPoint = route[route.length - 1]
      const [y, x] = start
      const [y1, x1] = end

      if (!lastPoint || (lastPoint.x !== x && lastPoint.y !== y)) route.push({ x, y })
      route.push({ x: x1, y: y1 })
    }
  }

  return route
}

function simplifyToZoom (route: ReducablePoint[], zoom: number) : ReducablePoint[] {
  // 1 -> 0.25
  // 18 -> 0.000001
  const tolerance = Math.pow(0.25, zoom * 0.55)
  const reduced = simplify(route, tolerance)
  return reduced
}

function Route ({ width, height, zoom, bounds, latLngToPixel, points = [] }: RouteViewProps) : Node {
  const style = { stroke: '#325f79', strokeWidth: 4 }
  if (points.length < 2) return null

  let route = cropRouteByBounds(points, bounds)
  if (route.length > 100) route = simplifyToZoom(route, zoom)
  if (route.length < 2) return null

  const [{ x, y }] = route
  let pixel = latLngToPixel([y, x])

  const lines = []
  for (let i = 1; i < route.length; i++) {
    const { x, y } = route[i]
    const pixel2 = latLngToPixel([y, x])
    lines.push(
      <line
        markerEnd='url(#head)'
        key={i}
        x1={pixel[0]}
        y1={pixel[1]}
        x2={pixel2[0]}
        y2={pixel2[1]}
        style={style}
      />
    )
    pixel = pixel2
  }

  return (
    <svg width={width} height={height} style={ABSOLUTE_TOP_LEFT}>
      {lines}
    </svg>
  )
}

export default Route
